import React, { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../Utility/firebase";

const UserStatus = ({ userId }) => {
  const [status, setStatus] = useState("offline");

  // console.log(status, "status>>>>>>>>>>>>>>");

  useEffect(() => {
    if (userId) {
      const statusRef = doc(db, "userStatus", userId);
      const unsubscribe = onSnapshot(statusRef, (statusDoc) => {
        if (statusDoc.exists()) {
          setStatus(statusDoc.data()?.status || "offline");
        } else {
          setStatus("offline");
        }
      });

      return () => unsubscribe();
    }
  }, [userId]);

  return (
    <i
      className="fa fa-circle onlineStatus"
      style={{
        color:
          status === "online" ? "green" : status === "away" ? "orange" : "grey",
      }}
      aria-hidden="true"
      title={status.charAt(0).toUpperCase() + status.slice(1)}
    ></i>
  );
};

export default UserStatus;
