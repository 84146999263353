import React from "react";
import Modal from "react-bootstrap/Modal";

export const ImageInfoModal = (props) => {
  const {
    show,
    setShow,
    imageType,
    handleChooseProfile,
    handleChooseGallery,
    handleChooseImages,
    images,
  } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Picture Requirement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {imageType === "gallery" ? (
          <ol>
            <li>Full Length Photo</li>
            <li>Waist Up Photo</li>
            <li>Shoulder Up Photo(Straight On)</li>
            <li>Smiling</li>
          </ol>
        ) : undefined}
        <p>
          Please do not upload selfies/mirror selfies, filtered images, and
          images that do not clearly show your face. Make sure your profile
          image is a close up headshot. Please note that if you do not upload
          professional or professional styled images your application may not be
          considered.
        </p>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <span onClick={() => setShow(false)} class="wt-btn">
          Cancel
        </span>
        <label for={imageType}>
          <span class="wt-btn">
            {imageType === "gallery" ? "Select Files" : "Select File"}
          </span>
          <input
            type="file"
            name="file"
            id={imageType}
            onChange={(e) => {
              if (imageType === "profile") {
                handleChooseProfile(e);
              } else if (imageType === "gallery") {
                handleChooseGallery(e);
              } else {
                handleChooseImages(e, imageType);
              }
            }}
            style={{ display: "none" }}
            multiple={imageType === "gallery" ? true : false}
            disabled={imageType === "gallery" ? images?.length >= 10 : false}
          />
        </label>
      </Modal.Footer>
    </Modal>
  );
};
