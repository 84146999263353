import React, { useEffect, useState } from "react";
import SidebarLayout from "../../../layout/SidebarLayout";
import { DeletePost, Loader, PostCard } from "../../../Components";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePost,
  getUserSocialPosts,
  likeDislike,
} from "../../../Redux/Services/OtherServices";
import {
  getAdminPostsApi,
  hideShowReportedApi,
} from "../../../Redux/Services/AdminServices";
import { SitePagination } from "../../../Components/filtersComponents";

const AdminPosts = () => {
  const dispatch = useDispatch();
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [hideShowModal, setHideShowModal] = useState(false);

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  let totalPages = Math.ceil(totalCount / 20);

  const getPortfolioData = async (pg = 1) => {
    try {
      let body = {
        page_number:  pg || 1,
        per_page: 20,
      };
      setLoading(true);
      let res = await dispatch(getAdminPostsApi(body));
      setLoading(false);
      if (res?.status === 200) {
        setPortfolios(res?.results?.data);
        setTotalCount(res?.results?.pagination?.total_users || 0);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
    getPortfolioData(newPage);
  };

  const handleHideShow = async () => {
    try {
      let body = {
        super_id: loggedInUser?.user_data?.user_id,
        action:
          selectedId?.extra?.hide_profile_status === "show" ? "hide" : "show",
        post_id: selectedId?.post_details?.ID,
        action_to: "social_post",
      };
      setLoading(true);
      let res = await dispatch(hideShowReportedApi(body));
      setLoading(false);
      if (res?.status === 200) {
        setHideShowModal(false);
        getPortfolioData();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeletePost = async () => {};

  const handleLikeUnlike = async (type, id) => {
    try {
      let body = {
        type: type,
        post_id: id,
        user_id:
          partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      };
      setLoading(true);
      let res = await dispatch(likeDislike(body));
      setLoading(false);
      if (res?.status === 200) {
        getPortfolioData();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPortfolioData();
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main className="dashboard_main">
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <div className="post-card-grid">
            <h3 className="postTitl">Social Posts</h3>

            <div  className="row">
            {portfolios?.length ? (
              portfolios?.map((item, index) => (
                  <PostCard
                  key={index}
                    setIsDeleteModal={setIsDeleteModal}
                    type="post"
                    cardData={item}
                    setSelectedId={setSelectedId}
                    handleLikeUnlike={handleLikeUnlike}
                    page="manage"
                    route="admin"
                    setHideShowModal={setHideShowModal}
                    selectedId={selectedId}
                  />
                  ))
                  ) : loading ? undefined : (
                    <span>No Social Post Found</span>
                    )}
                    </div>
          </div>
        </div>
      </main>
      <div className="pg_vew">
        {totalPages > 1 ? (
          <SitePagination
            module={portfolios}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
            setPage={setPage}
          />
        ) : undefined}
      </div>

      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={handleDeletePost}
          text="Are you sure you want to delete this post?"
        />
      ) : undefined}

      {hideShowModal ? (
        <DeletePost
          setShow={setHideShowModal}
          show={hideShowModal}
          title="Alert"
          handleConfirm={() => {
            handleHideShow();
          }}
          text={`Are you sure you want to ${
            selectedId?.extra?.hide_profile_status === "show" ? "hide" : "show"
          } this Social post?`}
        />
      ) : undefined}
    </SidebarLayout>
  );
};

export default AdminPosts;
