export const Images = {
  logo: require("../Assets/Images/PNG/logo.png"),
  hero1: require("../Assets/Images/madelyn.png"),
  hero2: require("../Assets/Images/brook.png"),
  banner: require("../Assets/Images/banner.jpeg"),
  newsletter: require("../Assets/Images/newsletter.png"),
  notFound: require("../Assets/Images/404_page-not-found.webp"),
  inboxImage: require("../Assets/Images/thumbnail/img-19.png"),
  latestProposals: require("../Assets/Images/thumbnail/img-20.png"),
  packageExpiry: require("../Assets/Images/thumbnail/img-21.png"),
  followers: require("../Assets/Images/thumbnail/img-22.png"),
  tiktok: require("../Assets/Images/tiktok.png"),
  // bag: require("../Assets/Images/bag.png"),
  user: require("../Assets/Images/thumbnail/user.png"),
  ongoingjobs: require("../Assets/Images/thumbnail/img-16.png"),
  completedobs: require("../Assets/Images/thumbnail/img-17.png"),
  canceledjobs: require("../Assets/Images/thumbnail/img-15.png"),
  postedjobs: require("../Assets/Images/thumbnail/img-23.png"),
  calendar: require("../Assets/Images/thumbnail/calender.png"),
  money: require("../Assets/Images/thumbnail/money.png"),
  history: require("../Assets/Images/thumbnail/history.png"),
  pending: require("../Assets/Images/thumbnail/pending.png"),
  mailSend: require("../Assets/Images/thumbnail/mail-send.png"),
  viewCamera: require("../Assets/Images/thumbnail/viewCamera.png"),
  actor: require("../Assets/Images/thumbnail/actor.png"),
  client: require("../Assets/Images/thumbnail/client.png"),
  photographer: require("../Assets/Images/thumbnail/photographer.png"),
  model: require("../Assets/Images/thumbnail/model.png"),
  referal: require("../Assets/Images/thumbnail/referal.png"),
  danger: require("../Assets/Images/thumbnail/danger.png"),
  partner: require("../Assets/Images/thumbnail/partner.png"),
  report: require("../Assets/Images/thumbnail/report.png"),
  package: require("../Assets/Images/thumbnail/package.png"),
  verify: require("../Assets/Images/thumbnail/verify.svg"),
};
