import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { InputField } from "../InputField";
import { useState } from "react";
import { showToast } from "../../Utility";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../Redux/Services/AuthServices";

export const ResetPassword = (props) => {
  const { response, setModalType } = props;
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleForgetPassword = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      if (!password) {
        showToast("Please Enter Password", "error");
      } else if (!newPassword) {
        showToast("Please Enter Your New Password", "error");
      } else if(password !== newPassword){
        showToast("Password and Confirm password should match")
      } else {
        var body = {
            user_email: response?.user_email,
            user_id: response?.user_id,
            new_password: password,
            new_re_password: newPassword
        };
        let res = await dispatch(forgetPassword(body));
        if (res?.status == 200) {
          setModalType("login");
        }
      }
    }
  };

  return (
    <>
      <div className="passwordform Loginform">
        <div className="signUp">
          If you have an account? <a>Sign in</a>
        </div>
        <Form noValidate validated={validated} onSubmit={handleForgetPassword}>
          <InputField
            required
            type="password"
            placeholder="New Password"
            value={password}
            onChangeText={(e) => {
              setPassword(e.target.value);
            }}
            page="auth"
            isValid={validated && !password}
          />

          <InputField
            required
            type="password"
            placeholder="Confirm New Password"
            value={newPassword}
            onChangeText={(e) => {
              setNewPassword(e.target.value);
            }}
            page="auth"
            isValid={validated && !newPassword}
          />

          <Button  variant="primary" type="submit">
            Reset Password
          </Button>
        </Form>
      </div>
    </>
  );
};
