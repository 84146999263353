import React from "react";
import { InputField } from "../../Components";
import { Tooltip } from "react-bootstrap";

const ActorAudition = ({
  childrenCount,
  setChildrenCount,
  auditions,
  setAuditions,
  talent,
}) => {
  return (
    <>
      {talent === "Actor" ? (
        <div class="form-section">
          <div
            style={{ justifyContent: "flex-start" }}
            className="form-section-title"
          >
            Reality TV Auditioning
            <Tooltip
              style={{ marginLeft: 15 }}
              arrow
              placement="top-start"
              title="This information is private and will only display to the client"
            >
              <span className="tooltipOverlay">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
              </span>
            </Tooltip>
          </div>
          <div class="form-group form-group-50">
            <InputField
              title="How many children do you have?"
              type="number"
              placeholder="Enter no. of children"
              value={childrenCount}
              onChangeText={(e) => {
                setChildrenCount(e?.target?.value);
              }}
            />

            <InputField
              title="Occupation"
              type="text"
              placeholder="Enter Occupation"
              value={auditions?.occupation}
              onChangeText={(e) => {
                setAuditions({
                  ...auditions,
                  occupation: e?.target?.value,
                });
              }}
            />

            <InputField
              title="Three things about you that no one knows"
              type="textarea"
              placeholder="Enter your answer"
              value={auditions?.threeThings}
              onChangeText={(e) => {
                setAuditions({
                  ...auditions,
                  threeThings: e?.target?.value,
                });
              }}
            />

            <InputField
              title="What reality show can you see yourself on and why?"
              type="textarea"
              placeholder="Enter your answer"
              value={auditions?.showOn}
              onChangeText={(e) => {
                setAuditions({
                  ...auditions,
                  showOn: e?.target?.value,
                });
              }}
            />

            <InputField
              title="Have you ever been on a reality show? If yes, which show and when?"
              type="textarea"
              placeholder="Enter your answer"
              value={auditions?.realityShow}
              onChangeText={(e) => {
                setAuditions({
                  ...auditions,
                  realityShow: e?.target?.value,
                });
              }}
            />
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default ActorAudition;
