import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  askCancelRequestApi,
  cancelClientProposalApi,
  getJobDetails,
  getProposalListing,
} from "../../Redux/Services/OtherServices";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../Constants";
import { routeName, showToast } from "../../Utility";
import FeedbackModal from "../../Components/Popups/Feedback";
import { DeletePost, Loader } from "../../Components";
import { CAncelModal, CancelRequests } from "../../Components/Popups";
import moment from "moment";
import { storageKey, storeData } from "../../Utility/Storage";
import { useHandleMessage } from "../../Utility/helper";

const Proposals = () => {
  const dispatch = useDispatch();
  const [proposalList, setProposalList] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  const queryParams = new URLSearchParams(location?.search);
  const handleMessage = useHandleMessage();

  const project_id = queryParams?.get("id");
  const active_status = queryParams?.get("status");
  const [RatingModal, setRatingModal] = useState(false);
  const [proposalData, setProposalData] = useState({});
  const [jobDetail, setJobDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedbackType, setFeedbackType] = useState(-1);
  const [selectedId, setSelectedId] = useState("");
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);
  const [isDirectConfirm, setIsDirectConfirm] = useState(false);
  const [isAskModel, setIsAskModel] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [rating, setRating] = useState([]);
  const [isDeclineModal, setIsDeclineModal] = useState(false);
  const [requestsCancel, setRequestsCancel] = useState("");
  const [isApproveModal, setIsApproveModal] = useState(false);

  const handleButton = (endDate) => {
    const providedDate = new Date(endDate);
    const today = new Date();
    const differenceInMs = today - providedDate;
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    return differenceInDays <= 10;
  };

  useEffect(() => {
    getJobData();
    getProposalsList();
  }, [loggedInUser]);

  const getProposalsList = async () => {
    if (project_id) {
      let body = {
        user_id:
          partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
        project_id: project_id,
        get_result: "proposal",
      };
      setLoading(true);
      let res = await dispatch(getProposalListing(body));
      setLoading(false);
      if (res?.status == 200) {
        if (res?.results && res?.results?.length != 0) {
          setProposalList(res?.results.reverse());
        } else {
          setProposalList([]);
        }
      }
    }
  };
  const getJobData = async () => {
    if (project_id) {
      let body = {
        project_id: project_id,
        user_id:
          partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      };
      setLoading(true);
      let res = await dispatch(getJobDetails(body));
      setLoading(false);
      if (res?.status == 200) {
        setJobDetail(res?.results?.[0]);
      }
    } else {
    }
  };

  const handleCancelProposal = async (cancelType) => {
    let body = {
      action: "project_cancel_client",
      project_id: project_id,
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      proposal_id: selectedId,
      canceled_type: cancelType,
    };
    if (isAskModel) {
      body.description = description;
    }
    setLoading(true);
    let res = await dispatch(cancelClientProposalApi(body));
    setLoading(false);
    if (res?.status == 200) {
      showToast(res?.message || "", "success");
      setDescription("");
      if (cancelType !== "ask_model") {
        storeData(storageKey.SELECTED_JOB_TYPE, "cancelled");
        const queryParams = new URLSearchParams({
          status: "cancelled",
        });
        navigate(`${routeName.CLIENT_PROJECTS}?${queryParams.toString()}`, {
          replace: true,
        });
      } else {
        getJobData();
        getProposalsList();
      }
      setIsDirectConfirm(false);
      setIsCancelConfirm(false);
      setIsAskModel(false);
    }
  };

  const handleAcceptCancelRequest = async (cancelType, item) => {
    let body = {
      action: "project_request_client",
      project_id: project_id,
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      proposal_id: item ? item?.basic?.ID : selectedId,
      action_type: cancelType,
      request_id: item
        ? item?.ask_cancel_project?.data?.ID
        : requestsCancel?.ask_cancel_project?.data?.ID,
    };

    if (cancelType === "decline") {
      body.reject_reason = selectedReason;
    }

    // console.log(body, "xdlkhdlkgh");
    // return

    setLoading(true);
    let res = await dispatch(askCancelRequestApi(body));
    setLoading(false);
    if (res?.status == 200) {
      if (cancelType === "decline") {
        getJobData();
        getProposalsList();
      } else {
        storeData(storageKey.SELECTED_JOB_TYPE, "cancelled");
        const queryParams = new URLSearchParams({
          status: "cancelled",
        });
        navigate(`${routeName.CLIENT_PROJECTS}?${queryParams.toString()}`, {
          replace: true,
        });
      }
      setIsDeclineModal(false);
      setSelectedReason("");
      setIsApproveModal(false);
      setIsAskModel(false);
    }
  };

  let cancel0 =
    "Job has already started If you cancel this job, the model will receive 100% payment as a compensation.";

  let cancel24 =
    "If you cancel the job 24 hours within the start date, the model will receive 100% payment as a compensation.";

  let cancel48 =
    "If the job is canceled between 48 hours and 24 hours before the start date, there will be a 50% refund and the model will receive 50% as a compensation.";

  let cancelMore48 =
    "If the job is canceled more than 48 hours before the start date, there will be no charges deducted.";
  // console.log("duration.asHours() > 24-----", jobDetail);

  const durationString = jobDetail?.post_meta_details?.differenceInHours
    ? jobDetail?.post_meta_details?.differenceInHours
    : "00:00"; // 62 hours and 49 minutes
  const [hours, minutes] = durationString.split(":").map(Number);
  const duration = moment.duration({ hours, minutes });

  return (
    <>
      <SidebarLayout>
        <Loader loading={loading} />
        <div class="dashboard_main">
          <div className="myJobs">
            <div class="wt-managejobcontent">
              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                  <div class="wt-contenthead">
                    <div class="wt-title">
                      {jobDetail?.post_meta_details?.project_status && (
                        <a
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          <i
                            style={{
                              color:
                                jobDetail?.post_meta_details?.project_status ==
                                "completed"
                                  ? Colors?.green
                                  : jobDetail?.post_meta_details
                                      ?.project_status == "cancelled"
                                  ? Colors?.red
                                  : Colors?.blue,
                            }}
                            class={
                              jobDetail?.post_meta_details?.project_status ==
                              "completed"
                                ? "fa fa-check-circle"
                                : jobDetail?.post_meta_details
                                    ?.project_status == "cancelled"
                                ? "fa fa-times-circle"
                                : "fa fa-check-fa fa-spinner"
                            }
                          ></i>
                          {"  "}
                          {jobDetail?.post_meta_details?.project_status}
                        </a>
                      )}
                      <h2
                        onClick={() => {
                          const queryParams = new URLSearchParams({
                            id: jobDetail?.profile?.ID,
                          });
                          navigate(
                            `${routeName.JOB_DETAILS}?${queryParams.toString()}`
                          );
                        }}
                      >
                        {jobDetail?.profile?.post_title}
                      </h2>
                    </div>
                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                      <li>
                        <span>
                          <i class="far fa-flag"></i> Location :{" "}
                          {jobDetail?.post_meta_details?.country} |{" "}
                          {jobDetail?.post_meta_details?.city}
                        </span>
                      </li>
                      <li>
                        <a class="wt-clicksavefolder">
                          <i class="far fa-folder"></i> Type:{" "}
                          {jobDetail?.post_meta_details?._project_type}
                        </a>
                      </li>
                    </ul>
                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                      <li>
                        <span class="wt-dashboradclock">
                          <i class="far fa-calendar"></i> Start Date :{" "}
                          {jobDetail?.post_meta_details?.starting_date}
                        </span>
                      </li>
                      <li>
                        <span class="wt-dashboradclock">
                          <i class="far fa-calendar"></i> End Date :{" "}
                          {jobDetail?.post_meta_details?.end_date}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="wt-rightarea">
                    <div class="wt-hireduserstatus">
                      <h4>{jobDetail?.post_meta_details?.count_proposal}</h4>
                      <span>Proposal count</span>
                      <h4>{jobDetail?.profile?.post_status || ""}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="listproposal">
          <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
            {proposalList?.map((item, index) => {
              return (
                <>
                  {item?.proposal_details?.pay_request_status?.show === "yes" ||
                  Number(item?.proposal_details?.extra_pay_count) ? (
                    <div class="container">
                      <div class="wt-haslayout page-data re-send-email">
                        <div class="wt-jobalerts">
                          <div class="alert alert-warning alert-dismissible fade show">
                            {Number(item?.proposal_details?.extra_pay_count) >
                            0 ? (
                              <span
                                onClick={() => {
                                  navigate(
                                    `${
                                      routeName.EXTRA_PAY_LIST
                                    }?${new URLSearchParams({
                                      proposalId: item?.basic?.ID,
                                      id: item?.proposal_details?.project_id,
                                      name: item?.proposal_details
                                        ?.display_name,
                                    }).toString()}`
                                  );
                                }}
                              >
                                <em>Extra Payment Request&nbsp;:&nbsp;</em>The
                                hired talent has requested extra payment for
                                this project. Click here to view more details.
                              </span>
                            ) : (
                              <span>
                                <em>Paying Request&nbsp;:&nbsp;</em>Your hired
                                talent has request to complete job now{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : undefined}
                  {item?.ask_cancel_project?.data &&
                  active_status === "ongoing" &&
                  !item?.ask_cancel_project?.data?.status ? (
                    <div class="wt-haslayout page-data re-send-email">
                      <div class="wt-jobalerts">
                        <div class="alert alert-warning cancelReq">
                          <p>
                            <b>Project Cancel Request&nbsp;:&nbsp;</b>
                            {`The telent has requested for the cancellation of this Job`}
                          </p>
                          <p>
                            {item?.ask_cancel_project?.data?.description ? (
                              <p>
                                {" "}
                                <b>Reason: </b>
                                {item?.ask_cancel_project?.data?.description ||
                                  ""}
                              </p>
                            ) : (
                              ""
                            )}
                          </p>

                          <div className="btnsAppDec">
                            <button
                              className="btn"
                              onClick={() => {
                                setSelectedId(item?.basic?.ID);
                                setRequestsCancel(item);
                                setIsApproveModal(true);
                                // handleAcceptCancelRequest("accept", item);
                              }}
                            >
                              Approve
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                setSelectedId(item?.basic?.ID);
                                setRequestsCancel(item);
                                setIsDeclineModal(true);
                                // handleAcceptCancelRequest("decline");
                              }}
                            >
                              Decline
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : undefined}

                  {active_status === "ongoing" &&
                  item?.ask_cancel_project_decline?.data &&
                  item?.ask_cancel_project_decline?.data?.status ? (
                    <div class="wt-haslayout page-data re-send-email">
                      <div class="wt-jobalerts">
                        <div class="alert alert-warning cancelReq">
                          <p>
                            <b>Project Cancel Request Declined :</b>
                            {` Your request for job Cancellation has been rejected by the talent`}
                          </p>
                          <p>
                            {item?.ask_cancel_project_decline?.data
                              ?.reject_reason ? (
                              <p>
                                {" "}
                                <b>Reason : </b>
                                {
                                  item?.ask_cancel_project_decline?.data
                                    ?.reject_reason
                                }
                              </p>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : undefined}

                  <div class="wt-userlistingcontent wt-userlistingcontentvtwo proposalDetails">
                    <div class="wt-contenthead">
                      <figure class="wt-userlistingimg">
                        <img
                          src={item?.proposal_details?.profile_image}
                          alt="image description"
                          class="mCS_img_loaded"
                        />
                      </figure>
                      <div className="tArea">
                        <div class="wt-title">
                          <h2>
                            {item?.proposal_details?.display_name
                              ? item?.proposal_details?.display_name?.split(
                                  " "
                                )?.[0] +
                                " " +
                                item?.proposal_details?.display_name
                                  ?.split(" ")?.[1]
                                  .substring(0, 1)
                              : ""}
                          </h2>
                        </div>

                        <div class="wt-proposalfeedback">
                          <span class="wt-starsvtwo">
                            <i class="fa fa-star fill"></i>
                          </span>
                          <span class="wt-starcontent">
                            {" "}
                            {item?.proposal_details?.user_rating}/<i>5</i>
                          </span>
                        </div>

                        {item?.proposal_details?.status == "complete" &&
                        handleButton(jobDetail?.post_meta_details?.end_date) &&
                        !item?.proposal_details?.feedback_status ? (
                          <div class="wt-btnarea-btn">
                            <span
                              class="wt-bt"
                              onClick={() => {
                                setProposalData(item);
                                setRatingModal(true);
                                setFeedbackType(1);
                                setRating([]);
                              }}
                            >
                              Feedback
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip`}>
                                    This Feedback option is available for 10
                                    days after the end date
                                  </Tooltip>
                                }
                              >
                                <span className="tooltipOverlay">
                                  <i
                                    class="fa fa-info-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </OverlayTrigger>
                            </span>
                          </div>
                        ) : undefined}

                        {active_status === "completed" ? (
                          <div class="wt-btnarea-btn">
                            <span
                              class="wt-bt"
                              onClick={() =>
                                navigate(
                                  `${routeName.CHECKOUT}?${new URLSearchParams({
                                    proposalId: item?.basic?.ID,
                                    projectId:
                                      item?.proposal_details?.project_id,
                                  }).toString()}`
                                )
                              }
                            >
                              View Contract
                            </span>
                            <span
                              onClick={() => {
                                handleMessage(
                                  item?.proposal_details?.proposal_author_email,
                                  item?.proposal_details?.firebase_uid,
                                  item?.proposal_details?.full_name,
                                  setLoading,
                                  item?.proposal_details?.profile_image,
                                  item?.basic?.post_author
                                );
                              }}
                              class="wt-bt"
                            >
                              Message
                            </span>
                          </div>
                        ) : active_status === "ongoing" ? (
                          <div class="wt-btnarea-btn">
                            <span
                              class="wt-bt"
                              onClick={() =>
                                navigate(
                                  `${routeName.CHECKOUT}?${new URLSearchParams({
                                    proposalId: item?.basic?.ID,
                                    projectId:
                                      item?.proposal_details?.project_id,
                                  }).toString()}`
                                )
                              }
                            >
                              View Proposal
                            </span>

                            {/* {Number(item?.proposal_details?.extra_pay_count) >
                              0 && (
                              <span
                                class="wt-bt"
                                onClick={() => {
                                  navigate(
                                    `${
                                      routeName.EXTRA_PAY_LIST
                                    }?${new URLSearchParams({
                                      proposalId: item?.basic?.ID,
                                      id: item?.proposal_details?.project_id,
                                    }).toString()}`
                                  );
                                }}
                              >
                                {`Payment Requests (${Number(
                                  item?.proposal_details?.extra_pay_count
                                )})`}
                              </span>
                            )} */}

                            <span
                              onClick={() => {
                                handleMessage(
                                  item?.proposal_details?.proposal_author_email,
                                  item?.proposal_details?.firebase_uid,
                                  item?.proposal_details?.full_name,
                                  setLoading,
                                  item?.proposal_details?.profile_image
                                );
                              }}
                              class="wt-bt"
                            >
                              Message
                            </span>
                          </div>
                        ) : undefined}

                        {active_status === "ongoing" &&
                        item?.proposal_details?.status === "hired" ? (
                          <div class="wt-btnarea-btn">
                            {item?.proposal_details?.status != "complete" && (
                              <span
                                class="wt-bt"
                                onClick={() => {
                                  setProposalData(item);
                                  setRatingModal(true);
                                  setFeedbackType(2);
                                  setRating([]);
                                }}
                              >
                                Complete & Pay
                              </span>
                            )}{" "}
                          </div>
                        ) : undefined}

                        {active_status === "posted" ? (
                          <div class="wt-btnarea-btn">
                            <span
                              class="wt-bt"
                              onClick={() =>
                                navigate(
                                  `${routeName.CHECKOUT}?${new URLSearchParams({
                                    proposalId: item?.basic?.ID,
                                    projectId:
                                      item?.proposal_details?.project_id,
                                  }).toString()}`
                                )
                              }
                            >
                              View Proposal
                            </span>

                            <span
                              onClick={() => {
                                handleMessage(
                                  item?.proposal_details?.proposal_author_email,
                                  item?.proposal_details?.firebase_uid,
                                  item?.proposal_details?.full_name,
                                  setLoading,
                                  item?.proposal_details?.profile_image
                                );
                              }}
                              class="wt-bt"
                            >
                              Message
                            </span>
                          </div>
                        ) : undefined}
                        {(item?.proposal_details?.proposal_status === "hired" &&
                          item?.proposal_details?.status !== "cancelled") ||
                        item?.proposal_details?.status === "hired" ? (
                          <div class="wt-btnarea-btn">
                            <span
                              class="wt-bt"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedId(item?.basic?.ID);
                                setRequestsCancel(item);
                                setSelectedReason("");
                                setIsCancelConfirm(true);
                              }}
                            >
                              Cancel Contract
                            </span>
                          </div>
                        ) : undefined}
                      </div>
                    </div>
                    <div class="wt-rightarea">
                      <div class="wt-hireduserstatus">
                        <h4>$ {item?.proposal_details?.proposal_amount}</h4>
                        <span> Quoted Price</span>
                        {item?.proposal_details?.status && (
                          <a
                            style={{
                              textTransform: "capitalize",
                              fontWeight: 600,
                            }}
                          >
                            <i
                              style={{
                                color:
                                  item?.proposal_details?.status == "complete"
                                    ? Colors?.green
                                    : item?.proposal_details?.status ==
                                      "cancelled"
                                    ? Colors?.red
                                    : Colors?.blue,
                              }}
                              class={
                                item?.proposal_details?.status == "complete"
                                  ? "fa fa-check-circle"
                                  : item?.proposal_details?.status ==
                                    "cancelled"
                                  ? "fa fa-times-circle"
                                  : "fa fa-check-fa fa-spinner"
                              }
                            ></i>
                            {"  "}
                            {item?.proposal_details?.status}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <FeedbackModal
          setShow={setRatingModal}
          show={RatingModal}
          proposalId={proposalData?.basic?.ID}
          projectId={proposalData?.proposal_details?.project_id}
          type="Rating"
          role="client"
          setLoading={setLoading}
          status={active_status}
          getProposalsList={getProposalsList}
          feedbackType={feedbackType}
          setRating={setRating}
          rating={rating}
        />
        {isDirectConfirm ? (
          <DeletePost
            setShow={setIsDirectConfirm}
            show={isDirectConfirm}
            title="Cancellation"
            handleConfirm={() => {
              handleCancelProposal("direct_cancel");
            }}
            // text={jobDetail?.post_meta_details?.differenceInHours}
            text={`${
              duration.asHours() == 0
                ? cancel0
                : duration.asHours() < 24
                ? cancel24
                : duration.asHours() <= 48
                ? cancel48
                : duration.asHours() > 48
                ? cancelMore48
                : ""
            }
            Are you sure you want to cancel this project?`}
          />
        ) : undefined}

        {isApproveModal ? (
          <DeletePost
            setShow={setIsApproveModal}
            show={isApproveModal}
            title="Approve Cancellation Request"
            handleConfirm={() => {
              handleAcceptCancelRequest("accept");
            }}
            text="Are you sure you want to approve the cancellation job request?"
          />
        ) : undefined}

        {isCancelConfirm ? (
          <CAncelModal
            setVisible={setIsCancelConfirm}
            visible={isCancelConfirm}
            title="Alert"
            handleDirectConfirm={() => {
              setIsCancelConfirm(false);
              setIsDirectConfirm(true);
            }}
            handleAskToClient={() => {
              setIsAskModel(true);
              if (!description && isAskModel) {
                showToast("Please add description");
              } else if (description && isAskModel) {
                handleCancelProposal("ask_model");
              }
            }}
            setDescription={setDescription}
            description={description}
            isAskModel={isAskModel}
            setIsAskModel={setIsAskModel}
            data={requestsCancel}
            selected={jobDetail}
          />
        ) : undefined}

        {isDeclineModal ? (
          <CancelRequests
            visible={isDeclineModal}
            setVisible={setIsDeclineModal}
            setDescription={setSelectedReason}
            description={selectedReason}
            handleAccept={handleAcceptCancelRequest}
          />
        ) : undefined}
      </SidebarLayout>
    </>
  );
};

export default Proposals;
