import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const ViewIdentity = (props) => {
  const { data, show, setShow } = props;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Identity Verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Name</p>
          <span>{`${data?.post_meta_details?.full_name || ""}`}</span>
        </div>
        <div>
          <p>Contact Number</p>
          <span>{data?.post_meta_details?.phone_number || "-"}</span>
        </div>
        <div>
          <p>Verification ID/DOC</p>
          <span>{data?.identity?.document_type || "-"}</span>
        </div>
        <p>Attachment</p>
        <img alt="attach" src={data?.identity?.identity_url?.[0]} />
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
