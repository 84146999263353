// Filename - ContactUs.jsx
import React, { useState } from "react";
import { Loader } from "../../../Components";
import { Images } from "../../../Constants";
import "lightbox.js-react/dist/index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserDetail } from "../../../Redux/Services/AuthServices";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Rating from "@mui/material/Rating";
import { getPartnerListApi } from "../../../Redux/Services/PartnerServices";
import SidebarLayout from "../../../layout/SidebarLayout";
import { routeName } from "../../../Utility";

export const PartnerDetailView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");

  const [talentData, setTalentData] = useState("");
  const [isReadMore, setIsReadMore] = useState(false);
  const [tab, setTab] = useState("talent");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTalentDetail = async () => {
    let body = {
      user_id: id,
    };
    let res = await dispatch(getUserDetail(body));
    if (res?.status == 200) {
      setTalentData({
        ...talentData,
        fwOption: res?.results?.fw_option ? res?.results?.fw_option?.[0] : "",
        userData: res?.results?.user_data || "",
        companyDetails: res?.results?.company_details || "",
        postDetails: res?.results?.post_meta_details || "",
        socialFollowers: res?.results?.social_followers || "",
        measurement: res?.results?.measurment || "",
        imageGallery: res?.results?.images_gallery || "",
        profileImage: res?.results?.profile_image || "",
        projectCount: res?.results?.project_count_details || "",
        companyDetails: res?.results?.company_details || "",
      });
      if (res?.results?.user_data?.user_role === 14) {
        getAllPartnerList(res?.results?.user_data?.profile_id);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getTalentDetail();
    }
  }, [id]);

  const getAllPartnerList = async (profile_id) => {
    setLoading(true);
    let body = {
      user_id: profile_id,
      user_type: tab,
      action: "registered",
      page_number: "1",
      per_page: "50",
    };
    let res = await dispatch(getPartnerListApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
    } else {
      setList([]);
    }
  };

  useEffect(() => {
    if (talentData && talentData?.userData?.user_role === 14) {
      getAllPartnerList(talentData?.userData?.profile_id);
    }
  }, [talentData]);

  const adminDashboard = [
    {
      key: 1,
      title: "MODELS LIST",
      onPress: () => {
        const queryParams = new URLSearchParams({
          type: "talent",
          tab: 1,
          partnerId: talentData?.userData?.profile_id,
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`);
      },
      icon: Images.model,
      text: "Click To View",
    },
    {
      key: 2,
      title: "CLIENTS LIST",
      onPress: () => {
        const queryParams = new URLSearchParams({
          type: "client",
          tab: 1,
          partnerId: talentData?.userData?.profile_id,
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`);
      },
      icon: Images.client,
      text: "Click To View",
    },
    {
      key: 3,
      title: "PHOTOGRAPHERS LIST",
      onPress: () => {
        const queryParams = new URLSearchParams({
          type: "photographer",
          tab: 1,
          partnerId: talentData?.userData?.profile_id,
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`);
      },
      icon: Images.photographer,
      text: "Click To View",
    },
    {
      key: 4,
      title: "ACTORS LIST",
      onPress: () => {
        const queryParams = new URLSearchParams({
          type: "actor",
          tab: 1,
          partnerId: talentData?.userData?.profile_id,
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`);
      },
      icon: Images.actor,
      text: "Click To View",
    },
  ];

  return (
    <SidebarLayout>
      <Loader loading={loading} />

      <div className="talentInfo">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="wt-userprofileholder">
                <div className="row">
                  {talentData?.postDetails?.subscription_pro_id != 107 ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id={`tooltip-${talentData?.postDetails?.subscription_pro_id}`}
                        >
                          {talentData?.postDetails?.subscription_pro_id ==
                            106 ||
                          talentData?.postDetails?.subscription_pro_id == 104
                            ? "Professional member"
                            : talentData?.postDetails?.subscription_pro_id ==
                                103 ||
                              talentData?.postDetails?.subscription_pro_id ==
                                105
                            ? "Premium member"
                            : ""}
                        </Tooltip>
                      }
                    >
                      {talentData?.postDetails?.subscription_pro_id ? (
                        <div
                          className={
                            talentData?.postDetails?.subscription_pro_id ==
                              106 ||
                            talentData?.postDetails?.subscription_pro_id == 104
                              ? "paidUsers pro"
                              : talentData?.postDetails?.subscription_pro_id ==
                                  103 ||
                                talentData?.postDetails?.subscription_pro_id ==
                                  105
                              ? "paidUsers"
                              : ""
                          }
                        >
                          <i class="fas fa-crown"></i>
                        </div>
                      ) : (
                        <></>
                      )}
                    </OverlayTrigger>
                  ) : null}
                  <div class="col-3">
                    <div class="row">
                      <div class="wt-userprofile">
                        {talentData?.profileImage?.length ? (
                          <figure>
                            <img
                              src={
                                talentData?.profileImage?.[0]?.guid ||
                                Images?.hero1
                              }
                              alt="img description"
                            />
                          </figure>
                        ) : undefined}
                        <div class="wt-title">
                          <h3>
                            <i class="fa fa-check-circle"></i>{" "}
                            {`${talentData?.userData?.first_name || ""} ${
                              talentData?.userData?.last_name?.slice(0, 1) || ""
                            }`}
                          </h3>

                          <div className="ratingProfile">
                            <Rating
                              name="half-rating-read"
                              precision={0.5}
                              value={
                                talentData?.postDetails?.user_rating
                                  ? Number(talentData?.postDetails?.user_rating)
                                  : 0
                              }
                              readOnly
                            />
                          </div>

                          <span>
                            {`${
                              talentData?.postDetails?.user_rating
                                ? Number(talentData?.postDetails?.user_rating)
                                : 0
                            }/5`}
                            {/* <a class="javascript:void(0);">(860 Feedback)</a> */}
                            <p>{`Member since ${moment(
                              talentData?.userData?.post_date
                            ).format("MMM DD,YYYY")}`}</p>
                          </span>
                        </div>
                      </div>
                      <div className="rightSideBar">
                {talentData?.socialFollowers?.facebook_profile_link ||
                talentData?.socialFollowers?.youtube_profile_link ||
                talentData?.socialFollowers?.instagram_profile_link ? (
                  <h4>Social Media Handles</h4>
                ) : undefined}
                <ul class="socialIcon">
                  {talentData?.socialFollowers?.facebook_profile_link ? (
                    <li>
                      <a
                        href={
                          talentData?.socialFollowers?.facebook_profile_link
                        }
                        target="_blank"
                      >
                        <i class="fa fa-facebook"></i>{" "}
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.instagram_profile_link ? (
                    <li>
                      <a
                        href={
                          talentData?.socialFollowers?.instagram_profile_link
                        }
                        target="_blank"
                      >
                        <i class="fa fa-instagram"></i>{" "}
                        {/* <span>{`${talentData?.socialFollowers?.instagram_follower} Followers`}</span> */}
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.youtube_profile_link ? (
                    <li>
                      <a
                        href={talentData?.socialFollowers?.youtube_profile_link}
                        arget="_blank"
                        title="0-9k Follower"
                      >
                        <i class="fa fa-youtube"></i>{" "}
                        {/* <span>{`${talentData?.socialFollowers?.youtube_follower} Followers`}</span> */}
                      </a>
                    </li>
                  ) : undefined}
                </ul>
              </div>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="wt-proposalhead wt-userdetails">
                      <h2>{talentData?.fwOption?.tag_line || ""}</h2>
                      <ul class="wt-userlisting-breadcrumb wt-userlisting-breadcrumbvtwo">
                        <li>
                          <span>{talentData?.postDetails?.state || ""}</span>
                        </li>
                        <li>{talentData?.postDetails?.city || ""}</li>
                      </ul>
                      <div
                        class="wt-description"
                        data-readmore=""
                        aria-expanded="false"
                        id="rmjs-1"
                      >
                        {talentData?.companyDetails?.description ? (
                          <>
                            <span>About:</span>
                            {talentData?.companyDetails?.description &&
                            talentData?.companyDetails?.description?.length >
                              400 ? (
                              <>
                                <p>
                                  {isReadMore
                                    ? talentData?.companyDetails?.description
                                    : talentData?.companyDetails?.description?.slice(
                                        0,
                                        400
                                      ) || ""}
                                  {!isReadMore &&
                                  talentData?.companyDetails?.description
                                    ?.length > 400 ? (
                                    <span
                                      className="read_btn"
                                      onClick={() => setIsReadMore(!isReadMore)}
                                    >
                                      ...Read More
                                    </span>
                                  ) : talentData?.companyDetails?.description
                                      ?.length > 400 ? (
                                    <span
                                      className="read_btn"
                                      onClick={() => setIsReadMore(!isReadMore)}
                                    >
                                      Read Less
                                    </span>
                                  ) : undefined}
                                </p>
                              </>
                            ) : talentData?.companyDetails?.description &&
                              talentData?.companyDetails?.description?.length <
                                400 ? (
                              <>
                                <p>
                                  {talentData?.companyDetails?.description ||
                                    ""}
                                </p>
                              </>
                            ) : undefined}
                          </>
                        ) : undefined}
                      </div>
                    </div>
                    <div class="talentDescription partInfo">
                <p>
                  <span> Company Name :</span>
                  <span>{talentData?.companyDetails?.organization || ""}</span>
                </p>
                <p>
                  <span> Business Website :</span>
                  <span>
                    {talentData?.companyDetails?.bussiness_website || ""}
                  </span>
                </p>
                <p>
                  <span> Title :</span>
                  <span>{talentData?.companyDetails?.title || ""}</span>
                </p>
                <p>
                  <span> We have been in bussiness since :</span>
                  <span>
                    {talentData?.companyDetails?.bussiness_since || ""}
                  </span>
                </p>
              </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row">
            {adminDashboard?.map((item) => (
              <div
                key={item?.key}
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
              >
                <div className="wt-insightsitem wt-dashboardbox">
                  <figure className="wt-userlistingimg">
                    <img
                      src={item?.icon}
                      alt="image description"
                      className="mCS_img_loaded"
                    />
                  </figure>
                  <div className="wt-insightdetails">
                    <div className="wt-title">
                      <h3>{item?.title}</h3>
                      <a onClick={item?.onPress}>{item?.text}</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};
