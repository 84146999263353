import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { DeletePost, Loader, PostCard } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  deletePost,
  getSavedPost,
  likeDislike,
  unsavePostApi,
} from "../../Redux/Services/OtherServices";
import { convertUTCToLocalTime, routeName, showToast, timeSince } from "../../Utility";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const SavedCollection = () => {
  const dispatch = useDispatch();
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedTab, setSelectedTab] = useState("portfolio");
  const [jobData, setjobData] = useState([]);

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const handleSelect = (key) => {
    setSelectedTab(key);
  };


  const handleDeletePost = async () => {
    try {
      let body = {
        user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
        action: "delete",
        post_id: selectedId,
      };
      setLoading(true);
      let res = await dispatch(deletePost(body));
      setLoading(false);
      if (res?.status === 200) {
        getSavedItems();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getSavedItems = async () => {
    try {
      let body = {
        post_type:
          selectedTab === "portfolio"
            ? "212"
            : selectedTab === "jobs"
            ? "213"
            : "211",
        user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      };
      setLoading(true);
      let res = await dispatch(getSavedPost(body));
      setLoading(false);
      if (res?.status === 200) {
        // console.log("resssssssss", res);
        if (selectedTab === "jobs") {
          setPortfolios([]);
          setjobData(res?.results || []);
        } else {
          setPortfolios(res?.results);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleLikeUnlike = async (type, id) => {
    try {
      let body = {
        type: type,
        post_id: id,
        user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      };
      setLoading(true);
      let res = await dispatch(likeDislike(body));
      setLoading(false);
      if (res?.status === 200) {
        getSavedItems();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSaveUnsave = async (id) => {
    try {
      let body = {
        post_id: id,
        user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
        type: "unsaved",
        post_type: selectedTab === "portfolio" ? "212" : "211",
      };
      setLoading(true);
      let res = await dispatch(unsavePostApi(body));
      setLoading(false);
      if (res?.status === 200) {
        getSavedItems();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSavedItems();
  }, [selectedTab]);

  return (
    <>
      <SidebarLayout>
        <Loader loading={loading} />
        <div class="searchtalentList dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="portfolio"
              activeKey={selectedTab}
              onSelect={handleSelect}
              className="mb-3"
            >
              <Tab eventKey="portfolio" title="Portfolio">
                <div className="post-card-grid">
                  <div className="row">
                    {portfolios?.length ? (
                      portfolios?.map((item, index) => (
                        <PostCard
                          key={index}
                          type="portfolio"
                          setIsDeleteModal={setIsDeleteModal}
                          cardData={item}
                          setSelectedId={setSelectedId}
                          handleLikeUnlike={handleLikeUnlike}
                          page="save"
                          handleSaveUnsave={handleSaveUnsave}
                        />
                      ))
                    ) : loading ? undefined : (
                      <span>No Portfolio found</span>
                    )}
                  </div>
                </div>
                {isDeleteModal ? (
                  <DeletePost
                    setShow={setIsDeleteModal}
                    show={isDeleteModal}
                    title="Alert"
                    handleConfirm={handleDeletePost}
                    text="Are you sure you want to delete this portfolio?"
                  />
                ) : undefined}
              </Tab>
              <Tab eventKey="social-post" title="Social Post">
                <div className="post-card-grid">
                  <div className="row">
                    {portfolios?.length ? (
                      portfolios?.map((item, index) => (
                        <PostCard
                          key={index}
                          type="post"
                          setIsDeleteModal={setIsDeleteModal}
                          cardData={item}
                          setSelectedId={setSelectedId}
                          handleLikeUnlike={handleLikeUnlike}
                          page="save"
                          handleSaveUnsave={handleSaveUnsave}
                        />
                      ))
                    ) : loading ? undefined : (
                      <span>No Social Posts found</span>
                    )}
                  </div>
                </div>
                {isDeleteModal ? (
                  <DeletePost
                    setShow={setIsDeleteModal}
                    show={isDeleteModal}
                    title="Alert"
                    handleConfirm={handleDeletePost}
                    text="Are you sure you want to delete this post?"
                  />
                ) : undefined}
              </Tab>
              <Tab eventKey="jobs" title="Jobs">
                <section className="joblisting jobsaved">
                  {jobData?.length
                    ? jobData?.map((item, index) => {
                        return (
                          <>
                            <JobCard
                              cardData={item}
                              handleApplyFilter={getSavedItems}
                            />
                          </>
                        );
                      })
                    : undefined}
                </section>
                {!loading && !jobData?.length ? (
                  <section className="no_data">
                    <div>No Saved jobs Found</div>
                  </section>
                ) : (
                  <></>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </SidebarLayout>
    </>
  );
};

export default SavedCollection;

const JobCard = (props) => {
  const { cardData, handleApplyFilter } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const handleSaveJob = async (postId, status) => {

    let body = {
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      post_id: postId,
      type: "unsaved",
      post_type: "213",
    };
    let res = await dispatch(unsavePostApi(body));
    if (res?.status == 200) {
      handleApplyFilter();
      showToast("Job Removed Successfully");
    }
  };

  return (
    <>
      <div class="wt-userlistinghold wt-userlistingholdvtwo">
        <div class="wt-userlistingcontent">
          <div class="wt-contenthead">
            <div class="wt-title">
              <div className="wrTitle">
                <small>
                  {/* {moment?.utc(cardData?.profile?.post_date).fromNow()} */}
                  {timeSince(
                    convertUTCToLocalTime(cardData?.profile?.post_date)
                  )}
                </small>
                <h2>{cardData?.profile?.post_title}</h2>
                {cardData?.post_meta_details?.model_type_req ===
                "casting calls" ? (
                  <small className="castingCalls">Casting calls</small>
                ) : undefined}
              </div>

              <div
                onClick={() =>
                  handleSaveJob(
                    cardData?.profile?.ID,
                    cardData?.post_meta_details?.saved_status
                  )
                }
                class="wt-clicklike"
              >
                <i class={"fa fa-bookmark"}></i>
              </div>
              <div class="wt-description">
                <p>
                  {cardData?.profile?.post_content && (
                    <p>{cardData?.profile?.post_content}</p>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div class="wt-viewjobholder">
            <ul>
              <li>
                <span>
                  <i class="fa fa-flag" aria-hidden="true"></i>{" "}
                  {cardData?.post_meta_details?.country}/{" "}
                  {cardData?.post_meta_details?.city}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-cogs" aria-hidden="true"></i>Expert: All level
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-gavel" aria-hidden="true"></i>Proposal:{" "}
                  {cardData?.post_meta_details?.proposal_count || 0}
                </span>
              </li>
              <li>
                <span>
                  <i class="far fa-folder "></i>Type:{" "}
                  {cardData?.post_meta_details?._project_type}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-money "></i>Budget:{" "}
                  {cardData?.post_meta_details?.project_type?.project_cost
                    ? "$" +
                      cardData?.post_meta_details?.project_type?.project_cost
                    : cardData?.post_meta_details?._hourly_rate
                    ? "$" +
                      cardData?.post_meta_details?._hourly_rate +
                      " (" +
                      "Estimated Hours: " +
                      cardData?.post_meta_details?._estimated_hours +
                      " hours)"
                    : 0}
                </span>
              </li>
              <li class="wt-btnarea">
                <div
                  onClick={() => {
                    const queryParams = new URLSearchParams({
                      id: cardData?.profile?.ID,
                    });
                    navigate(
                      `${routeName.JOB_DETAILS}?${queryParams.toString()}`
                    );
                  }}
                  class="wt-btn"
                >
                  View Job
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
