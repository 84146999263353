// import React, { useState } from 'react';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';

// const CalendarCompo = () => {
//   const [selectedDates, setSelectedDates] = useState([]);

//   const handleDateChange = (date) => {
//     setSelectedDates([...selectedDates, date]);
//   };

//   return (
//     <div>
//       <h2>Selected Dates:</h2>
//       <ul>
//         {selectedDates.map((date, index) => (
//           <li key={index}>{date.toLocaleString()}</li>
//         ))}
//       </ul>
//       <Calendar
//         onChange={handleDateChange}
//         value={selectedDates}
//         selectRange={true}

//       />
//     </div>
//   );
// };

// export default CalendarCompo;

import React, { useEffect, useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

const localizer = momentLocalizer(moment);

const CalendarCompo = ({talentData}) => {
  const [view, setView] = useState(Views.DAY);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if(talentData?.event_details?.length){
      const eventsArr = talentData?.event_details?.map(event => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        allDay: event.allDay
      }));
      setEvents(eventsArr)
    }
  }, [talentData]);


  // console.log(events);
  

  const eventsArr = [
    {
      "id": "239",
      "title": "Booked",
      "url": "",
      "status": "hours",
      "start": new Date("2024-04-28T07:54"),
      "end": new Date("2024-04-28T10:55"),
      "allday": false
    },
    // Add more events here
  ];

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const dayPropGetter = date => {
    const eventForDate = eventsArr.find(event => moment(event.start).isSame(date, 'day'));

    if (eventForDate) {
      return {
        className: 'event-date',
        title: eventForDate.title
      };
    }

    return {};
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        // events={eventsArr}
        views={['day', 'week', 'month']}
        view={view}
        onView={(view) => handleViewChange(view)}
        // onSelectSlot={(slotInfo) => console.log('Selected slot: ', slotInfo)}
        style={{ height: 500 }}
        // dayPropGetter={dayPropGetter}
        // eventPropGetter={(event) => ({
        //   className: 'custom-event'
        // })}
      />
    </div>
  );
};

export default CalendarCompo;



