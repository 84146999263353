export const Colors = {
  themeColor: "#ef4d74",
  lightThemeColor: "rgb(249 25 66 / 9%)",
  lightGreen: "#b7e5d7",
  green: "#4BBE9D",
  backgroundColor: "#656565",
  inputBorder: "#999",
  black: "#000",
  white: "#fff",
  green: "#4BBE9D",
  grey: "#D3D3D3",
  darkgrey: "#7A869A",
  red: "#E84242",
  yellow: "#FFCE71",
  lightYellow: "#f0e19d",
  gray: "#676767",
  lightGray: "#f7f7f7",
  blue: "#1d90b4",
  lightBlue: "#addcea",
  pink: "#ef4d74",
  // lightPink: "#FFEBF0",
  lightPink: "rgb(249 25 66 / 9%)",
  gredient: "#dadde1",
  offWhite: "#f2f2f2",
  orange: "#ff6600",
};
