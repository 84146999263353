import React from "react";
import { Images } from "../../Constants";
import { Header, Footer, Loader } from "../../Components";

export const NotFound = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Header />
      <img src={Images?.notFound} className="notFound"/>
      <Footer />
    </div>
  );
};
