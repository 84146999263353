import React from "react";
import { Header, Footer, Loader } from "../../Components";
import Accordion from "react-bootstrap/Accordion";
import { getFaqTypeList } from "../../Redux/Services/OtherServices";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

export const Faq = () => {
  const dispatch = useDispatch();
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("all");

  const allOptionData = useSelector((state) => state.authReducer.allOptionData);

  const getFaq = async (key) => {
    let body = {
      get_type: key || selectedTab,
    };
    setLoading(true);
    let res = await dispatch(getFaqTypeList(body));
    setLoading(false);
    if (res?.status == 200) {
      setFaqList(res?.results);
    } else {
      setFaqList([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    getFaq("all");
  }, []);

  const options = [
    {
      name: "All",
      type: "all",
    },
    {
      name: "Model",
      type: "model",
    },
    {
      name: "Actor",
      type: "actor",
    },
    {
      name: "Photographer",
      type: "photographer",
    },
    {
      name: "Client",
      type: "client",
    },
    {
      name: "Partner",
      type: "partner",
    },
  ];

  return (
    <div>
      <Header />
      <Loader loading={loading} />
      <div className="pageTitle">
        <div className="wt-title">
          <h1>Frequently Asked Question</h1>
        </div>
      </div>
      <div className="container">
        <div class="wt-userprofileholder contactForm">
          <div className="row">
            <div className="dashboard_main">
              <div className="myJobs">
                <Tabs
                  defaultActiveKey="security-settings"
                  activeKey={selectedTab}
                  onSelect={(key) => {
                    setSelectedTab(key);
                    getFaq(key);
                    setFaqList([]);
                  }}
                  className="mb-3"
                >
                  {options?.map((item, index) => (
                    <Tab key={index} eventKey={item.type} title={item.name}>
                      <div className="col-md-12">
                        <Accordion defaultActiveKey="0">
                          {faqList?.map((item, index) => {
                            return (
                              <>
                                <Accordion.Item eventKey={index}>
                                  <Accordion.Header>
                                    {item?.faq_question}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {item?.faq_answer}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            );
                          })}
                        </Accordion>
                      </div>
                      {!loading && !faqList?.length ? (
                        <div>
                          <p>No Faq's Found</p>
                        </div>
                      ) : undefined}
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
