export const ADD_PARTNERS_LIST_LOADING = "ADD_PARTNERS_LIST_LOADING";
export const ADD_PARTNERS_LIST_SUCCESS = "ADD_PARTNERS_LIST_SUCCESS";
export const ADD_PARTNERS_LIST_ERROR = "ADD_PARTNERS_LIST_ERROR";

export const GET_PARTNERS_LIST_LOADING = "GET_PARTNERS_LIST_LOADING";
export const GET_PARTNERS_LIST_SUCCESS = "GET_PARTNERS_LIST_SUCCESS";
export const GET_PARTNERS_LIST_ERROR = "GET_PARTNERS_LIST_ERROR";

export const DELETE_PARTNER_LOADING = "DELETE_PARTNER_LOADING";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const DELETE_PARTNER_ERROR = "DELETE_PARTNER_ERROR";

export const VIEW_PROJECT_PARTNER_LOADING = "VIEW_PROJECT_PARTNER_LOADING";
export const VIEW_PROJECT_PARTNER_SUCCESS = "VIEW_PROJECT_PARTNER_SUCCESS";
export const VIEW_PROJECT_PARTNER_ERROR = "VIEW_PROJECT_PARTNER_ERROR";

export const MAIL_SINGLE_PARTNER_LOADING = "MAIL_SINGLE_PARTNER_LOADING";
export const MAIL_SINGLE_PARTNER_SUCCESS = "MAIL_SINGLE_PARTNER_SUCCESS";
export const MAIL_SINGLE_PARTNER_ERROR = "MAIL_SINGLE_PARTNER_ERROR";

export const CHECK_EMAIL_STATUS_LOADING = "CHECK_EMAIL_STATUS_LOADING";
export const CHECK_EMAIL_STATUS_SUCCESS = "CHECK_EMAIL_STATUS_SUCCESS";
export const CHECK_EMAIL_STATUS_ERROR = "CHECK_EMAIL_STATUS_ERROR";

export const PARTNER_ACCESS_PERMISSIONS = "PARTNER_ACCESS_PERMISSIONS";