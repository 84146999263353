export const ADD_EDIT_FAQ_LOADING = "ADD_EDIT_FAQ_LOADING";
export const ADD_EDIT_FAQ_SUCCESS = "ADD_EDIT_FAQ_SUCCESS";
export const ADD_EDIT_FAQ_ERROR = "ADD_EDIT_FAQ_ERROR";

export const UPLOAD_MEDIA_LOADING = "UPLOAD_MEDIA_LOADING";
export const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
export const UPLOAD_MEDIA_ERROR = "UPLOAD_MEDIA_ERROR";

export const ADD_EDIT_BLOG_LOADING = "ADD_EDIT_BLOG_LOADING";
export const ADD_EDIT_BLOG_SUCCESS = "ADD_EDIT_BLOG_SUCCESS";
export const ADD_EDIT_BLOG_ERROR = "ADD_EDIT_BLOG_ERROR";

export const ADD_EDIT_PACKAGE_LOADING = "ADD_EDIT_PACKAGE_LOADING";
export const ADD_EDIT_PACKAGE_SUCCESS = "ADD_EDIT_PACKAGE_SUCCESS";
export const ADD_EDIT_PACKAGE_ERROR = "ADD_EDIT_PACKAGE_ERROR";

export const ALL_MODELS_LIST_LOADING = "ALL_MODELS_LIST_LOADING";
export const ALL_MODELS_LIST_SUCCESS = "ALL_MODELS_LIST_SUCCESS";
export const ALL_MODELS_LIST_ERROR = "ALL_MODELS_LIST_ERROR";

export const ALL_VERIFIED_MODELS_LOADING = "ALL_VERIFIED_MODELS_LOADING";
export const ALL_VERIFIED_MODELS_SUCCESS = "ALL_VERIFIED_MODELS_SUCCESS";
export const ALL_VERIFIED_MODELS_ERROR = "ALL_VERIFIED_MODELS_ERROR";

export const NEW_APPLICATIONS_MODELS_LOADING = "NEW_APPLICATIONS_MODELS_LOADING";
export const NEW_APPLICATIONS_MODELS_SUCCESS = "NEW_APPLICATIONS_MODELS_SUCCESS";
export const NEW_APPLICATIONS_MODELS_ERROR = "NEW_APPLICATIONS_MODELS_ERROR";

export const INCOMPLETE_MODELS_LOADING = "INCOMPLETE_MODELS_LOADING";
export const INCOMPLETE_MODELS_SUCCESS = "INCOMPLETE_MODELS_SUCCESS";
export const INCOMPLETE_MODELS_ERROR = "INCOMPLETE_MODELS_ERROR";

export const ID_VERIFICATION_MODELS_LOADING = "ID_VERIFICATION_MODELS_LOADING";
export const ID_VERIFICATION_MODELS_SUCCESS = "ID_VERIFICATION_MODELS_SUCCESS";
export const ID_VERIFICATION_MODELS_ERROR = "ID_VERIFICATION_MODELS_ERROR";

export const REJECTED_MODELS_LOADING = "REJECTED_MODELS_LOADING";
export const REJECTED_MODELS_SUCCESS = "REJECTED_MODELS_SUCCESS";
export const REJECTED_MODELS_ERROR = "REJECTED_MODELS_ERROR";

export const ACCEPT_ID_USER_LOADING = "ACCEPT_ID_USER_LOADING";
export const ACCEPT_ID_USER_SUCCESS = "ACCEPT_ID_USER_SUCCESS";
export const ACCEPT_ID_USER_ERROR = "ACCEPT_ID_USER_ERROR";

export const REJECT_ID_USER_LOADING = "REJECT_ID_USER_LOADING";
export const REJECT_ID_USER_SUCCESS = "REJECT_ID_USER_SUCCESS";
export const REJECT_ID_USER_ERROR = "REJECT_ID_USER_ERROR";

export const PROFILE_APPROVE_DECLINE_LOADING = "PROFILE_APPROVE_DECLINE_LOADING";
export const PROFILE_APPROVE_DECLINE_SUCCESS = "PROFILE_APPROVE_DECLINE_SUCCESS";
export const PROFILE_APPROVE_DECLINE_ERROR = "PROFILE_APPROVE_DECLINE_ERROR";

export const ALL_ACTOR_LIST_LOADING = "ALL_ACTOR_LIST_LOADING";
export const ALL_ACTOR_LIST_SUCCESS = "ALL_ACTOR_LIST_SUCCESS";
export const ALL_ACTOR_LIST_ERROR = "ALL_ACTOR_LIST_ERROR";

export const ALL_VERIFIED_ACTOR_LOADING = "ALL_VERIFIED_ACTOR_LOADING";
export const ALL_VERIFIED_ACTOR_SUCCESS = "ALL_VERIFIED_ACTOR_SUCCESS";
export const ALL_VERIFIED_ACTOR_ERROR = "ALL_VERIFIED_ACTOR_ERROR";

export const NEW_APPLICATIONS_ACTOR_LOADING = "NEW_APPLICATIONS_ACTOR_LOADING";
export const NEW_APPLICATIONS_ACTOR_SUCCESS = "NEW_APPLICATIONS_ACTOR_SUCCESS";
export const NEW_APPLICATIONS_ACTOR_ERROR = "NEW_APPLICATIONS_ACTOR_ERROR";

export const INCOMPLETE_ACTOR_LOADING = "INCOMPLETE_ACTOR_LOADING";
export const INCOMPLETE_ACTOR_SUCCESS = "INCOMPLETE_ACTOR_SUCCESS";
export const INCOMPLETE_ACTOR_ERROR = "INCOMPLETE_ACTOR_ERROR";

export const ID_VERIFICATION_ACTOR_LOADING = "ID_VERIFICATION_ACTOR_LOADING";
export const ID_VERIFICATION_ACTOR_SUCCESS = "ID_VERIFICATION_ACTOR_SUCCESS";
export const ID_VERIFICATION_ACTOR_ERROR = "ID_VERIFICATION_ACTOR_ERROR";

export const REJECTED_ACTOR_LOADING = "REJECTED_ACTOR_LOADING";
export const REJECTED_ACTOR_SUCCESS = "REJECTED_ACTOR_SUCCESS";
export const REJECTED_ACTOR_ERROR = "REJECTED_ACTOR_ERROR";

export const ADMIN_RESET_PASSWORD_LOADING = "ADMIN_RESET_PASSWORD_LOADING";
export const ADMIN_RESET_PASSWORD_SUCCESS = "ADMIN_RESET_PASSWORD_SUCCESS";
export const ADMIN_RESET_PASSWORD_ERROR = "ADMIN_RESET_PASSWORD_ERROR";

export const ALL_CLIENT_LIST_LOADING = "ALL_CLIENT_LIST_LOADING";
export const ALL_CLIENT_LIST_SUCCESS = "ALL_CLIENT_LIST_SUCCESS";
export const ALL_CLIENT_LIST_ERROR = "ALL_CLIENT_LIST_ERROR";

export const ALL_VERIFIED_CLIENT_LOADING = "ALL_VERIFIED_CLIENT_LOADING";
export const ALL_VERIFIED_CLIENT_SUCCESS = "ALL_VERIFIED_CLIENT_SUCCESS";
export const ALL_VERIFIED_CLIENT_ERROR = "ALL_VERIFIED_CLIENT_ERROR";

export const NEW_APPLICATIONS_CLIENT_LOADING = "NEW_APPLICATIONS_CLIENT_LOADING";
export const NEW_APPLICATIONS_CLIENT_SUCCESS = "NEW_APPLICATIONS_CLIENT_SUCCESS";
export const NEW_APPLICATIONS_CLIENT_ERROR = "NEW_APPLICATIONS_CLIENT_ERROR";

export const INCOMPLETE_CLIENT_LOADING = "INCOMPLETE_CLIENT_LOADING";
export const INCOMPLETE_CLIENT_SUCCESS = "INCOMPLETE_CLIENT_SUCCESS";
export const INCOMPLETE_CLIENT_ERROR = "INCOMPLETE_CLIENT_ERROR";

export const ID_VERIFICATION_CLIENT_LOADING = "ID_VERIFICATION_CLIENT_LOADING";
export const ID_VERIFICATION_CLIENT_SUCCESS = "ID_VERIFICATION_CLIENT_SUCCESS";
export const ID_VERIFICATION_CLIENT_ERROR = "ID_VERIFICATION_CLIENT_ERROR";

export const REJECTED_CLIENT_LOADING = "REJECTED_CLIENT_LOADING";
export const REJECTED_CLIENT_SUCCESS = "REJECTED_CLIENT_SUCCESS";
export const REJECTED_CLIENT_ERROR = "REJECTED_CLIENT_ERROR";

export const ALL_PARTNER_LIST_LOADING = "ALL_PARTNER_LIST_LOADING";
export const ALL_PARTNER_LIST_SUCCESS = "ALL_PARTNER_LIST_SUCCESS";
export const ALL_PARTNER_LIST_ERROR = "ALL_PARTNER_LIST_ERROR";

export const ALL_VERIFIED_PARTNER_LOADING = "ALL_VERIFIED_PARTNER_LOADING";
export const ALL_VERIFIED_PARTNER_SUCCESS = "ALL_VERIFIED_PARTNER_SUCCESS";
export const ALL_VERIFIED_PARTNER_ERROR = "ALL_VERIFIED_PARTNER_ERROR";

export const NEW_APPLICATIONS_PARTNER_LOADING = "NEW_APPLICATIONS_PARTNER_LOADING";
export const NEW_APPLICATIONS_PARTNER_SUCCESS = "NEW_APPLICATIONS_PARTNER_SUCCESS";
export const NEW_APPLICATIONS_PARTNER_ERROR = "NEW_APPLICATIONS_PARTNER_ERROR";

export const INCOMPLETE_PARTNER_LOADING = "INCOMPLETE_PARTNER_LOADING";
export const INCOMPLETE_PARTNER_SUCCESS = "INCOMPLETE_PARTNER_SUCCESS";
export const INCOMPLETE_PARTNER_ERROR = "INCOMPLETE_PARTNER_ERROR";

export const ID_VERIFICATION_PARTNER_LOADING = "ID_VERIFICATION_PARTNER_LOADING";
export const ID_VERIFICATION_PARTNER_SUCCESS = "ID_VERIFICATION_PARTNER_SUCCESS";
export const ID_VERIFICATION_PARTNER_ERROR = "ID_VERIFICATION_PARTNER_ERROR";

export const REJECTED_PARTNER_LOADING = "REJECTED_PARTNER_LOADING";
export const REJECTED_PARTNER_SUCCESS = "REJECTED_PARTNER_SUCCESS";
export const REJECTED_PARTNER_ERROR = "REJECTED_PARTNER_ERROR";

export const ALL_PHOTOGRAPHER_LIST_LOADING = "ALL_PHOTOGRAPHER_LIST_LOADING";
export const ALL_PHOTOGRAPHER_LIST_SUCCESS = "ALL_PHOTOGRAPHER_LIST_SUCCESS";
export const ALL_PHOTOGRAPHER_LIST_ERROR = "ALL_PHOTOGRAPHER_LIST_ERROR";

export const ALL_VERIFIED_PHOTOGRAPHER_LOADING = "ALL_VERIFIED_PHOTOGRAPHER_LOADING";
export const ALL_VERIFIED_PHOTOGRAPHER_SUCCESS = "ALL_VERIFIED_PHOTOGRAPHER_SUCCESS";
export const ALL_VERIFIED_PHOTOGRAPHER_ERROR = "ALL_VERIFIED_PHOTOGRAPHER_ERROR";

export const NEW_APPLICATIONS_PHOTOGRAPHER_LOADING = "NEW_APPLICATIONS_PHOTOGRAPHER_LOADING";
export const NEW_APPLICATIONS_PHOTOGRAPHER_SUCCESS = "NEW_APPLICATIONS_PHOTOGRAPHER_SUCCESS";
export const NEW_APPLICATIONS_PHOTOGRAPHER_ERROR = "NEW_APPLICATIONS_PHOTOGRAPHER_ERROR";

export const INCOMPLETE_PHOTOGRAPHER_LOADING = "INCOMPLETE_PHOTOGRAPHER_LOADING";
export const INCOMPLETE_PHOTOGRAPHER_SUCCESS = "INCOMPLETE_PHOTOGRAPHER_SUCCESS";
export const INCOMPLETE_PHOTOGRAPHER_ERROR = "INCOMPLETE_PHOTOGRAPHER_ERROR";

export const ID_VERIFICATION_PHOTOGRAPHER_LOADING = "ID_VERIFICATION_PHOTOGRAPHER_LOADING";
export const ID_VERIFICATION_PHOTOGRAPHER_SUCCESS = "ID_VERIFICATION_PHOTOGRAPHER_SUCCESS";
export const ID_VERIFICATION_PHOTOGRAPHER_ERROR = "ID_VERIFICATION_PHOTOGRAPHER_ERROR";

export const REJECTED_PHOTOGRAPHER_LOADING = "REJECTED_PHOTOGRAPHER_LOADING";
export const REJECTED_PHOTOGRAPHER_SUCCESS = "REJECTED_PHOTOGRAPHER_SUCCESS";
export const REJECTED_PHOTOGRAPHER_ERROR = "REJECTED_PHOTOGRAPHER_ERROR";

export const ADD_MODEL_BY_ADMIN_LOADING = "ADD_MODEL_BY_ADMIN_LOADING";
export const ADD_MODEL_BY_ADMIN_SUCCESS = "ADD_MODEL_BY_ADMIN_SUCCESS";
export const ADD_MODEL_BY_ADMIN_ERROR = "ADD_MODEL_BY_ADMIN_ERROR";

export const SELECTED_TAB_USERS = "SELECTED_TAB_USERS";