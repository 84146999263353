import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routeName, showToast } from "../../Utility";
import { useDispatch, useSelector } from "react-redux";
import SidebarLayout from "../../layout/SidebarLayout";
import { Button, Card, Container } from "react-bootstrap";
import { DeletePost, Loader } from "../../Components";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SitePagination } from "../../Components/filtersComponents";
import { SendMailModal, ViewProjects } from "../../Components/Popups";
import SearchBar from "../../Components/SearchBar";
import { isValidInput } from "../../Utility/validations";
import { getBlogReviewsList } from "../../Redux/Services/OtherServices";
import { addEditBlogApi } from "../../Redux/Services/AdminServices";

const ManageBlogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const type = queryParams?.get("type");
  const tabs = queryParams?.get("tab");

  const [tab, setTab] = useState(tabs || 1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [isProjectModal, setIsProjectModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [blogList, setBlogList] = useState([]);

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  let totalPages = Math.ceil(totalCount / 10);

  // console.log(selectedRows);

  const onPageChange = (newPage) => {
    setPage(newPage);
    // handleGetFunctions(tab, newPage);
  };

  const handleBlogList = async () => {
    setLoading(true);
    var body = {
      post_type: "blogs",
      page_number: page,
      per_page: "10",
      search: debouncedSearchTerm,
    };
    let res = await dispatch(getBlogReviewsList(body));
    setLoading(false);
    if (res?.status == 200) {
      setBlogList(res?.results?.post);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setBlogList([]);
    }
  };

  const handleDeletePartnerData = async () => {
    let body = {
      action:'delete',
      blog_id: selectedId?.post_id,
      thumbnail_id: selectedId?.thumbnail?.thumb_id,
    };
    setLoading(true);

    let res = await dispatch(addEditBlogApi(body));
    setLoading(false);
    if (res?.status == 200) {
      showToast('Blog deleted successfully', "success")
      handleBlogList();
    } else {
    }
  };

 

  useEffect(() => {
    if (loggedInUser) {
      handleBlogList();
    }
  }, [tab, debouncedSearchTerm, page, loggedInUser]);


  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <div>
        <Container>
          <div className="list_cnnt">
            <h3>{`MANAGE BLOGS`}</h3>

            <a
              class="wt-btn"
              onClick={() => {
                navigate(routeName.ADD_BLOGS);
              }}
            >
              Add Blog
            </a>
          </div>
        </Container>
        <div className="dashboard_main">
          <div className="myJobs">
            <div class="wt-managejobcontent">
              <div className="cards">
                {/* <div className="cards_header">
                  <div />
                  <SearchBar
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onCross={() => setSearchTerm("")}
                    onChange={(val) => {
                      if (isValidInput(val.target.value)) {
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </div> */}
                <TableContainer className="table_container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No.</TableCell>
                        <TableCell>Thumbnail</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    {blogList?.length ? (
                      blogList?.map((item, i) => {
                        return (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                                <p>{(page - 1) * 10 + (i + 1)}</p>
                              </TableCell>
                              <TableCell>
                                <img
                                  style={{
                                    height: 50,
                                    width: 50,
                                    borderRadius: 25,
                                  }}
                                  src={item?.thumbnail?.url || ""}
                                  alt="post_url"
                                />
                              </TableCell>
                              <TableCell>{item?.post_title || "-"}</TableCell>

                              <TableCell align="center">
                                <div className="table_actions">
                                  <IconButton
                                    onClick={() => {
                                      const queryParams = new URLSearchParams({
                                        id: item?.post_id,
                                      });
                                      navigate(
                                        `${
                                          routeName?.INSIGHT_DETAIL
                                        }?${queryParams.toString()}`
                                      );
                                    }}
                                  >
                                    <div>
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                      <p>View</p>
                                    </div>
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      const queryParams = new URLSearchParams({
                                        id: item?.post_id,
                                      });
                                      navigate(
                                        `${
                                          routeName?.ADD_BLOGS
                                        }?${queryParams.toString()}`
                                      );
                                    }}
                                  >
                                    <div>
                                      <i
                                        class="fa fa-pencil"
                                        aria-hidden="true"
                                        color="#00000"
                                      ></i>
                                      <p>Edit</p>
                                    </div>
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      setSelectedId(item);
                                      setIsDeleteModal(true);
                                    }}
                                  >
                                    <div>
                                      <i
                                        class="fa fa-trash-o"
                                        aria-hidden="true"
                                        color="#00000"
                                      ></i>
                                      <p>Delete</p>
                                    </div>
                                  </IconButton>
                                  {/* {Number(tab) === 2 ? undefined : (
                                          <IconButton
                                            onClick={() => {
                                              setIsProjectModal(true);
                                            }}
                                          >
                                            <div>
                                               <i
                                              class="fas fa-business-time"
                                              aria-hidden="true"
                                              color="#00000"
                                            ></i>
                                              <p>Projects</p>
                                            </div>
                                          </IconButton>
                                        )} */}
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })
                    ) : (
                      <TableBody>
                        <TableCell
                          align="center"
                          colSpan={10}
                          sx={{ color: "black" }}
                        >
                          {`No Blogs Found`}
                        </TableCell>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <div className="pg_vew">
                  {totalPages > 1 ? (
                    <SitePagination
                      module={blogList}
                      page={page}
                      onPageChange={onPageChange}
                      totalPages={totalPages}
                      setPage={setPage}
                    />
                  ) : undefined}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={handleDeletePartnerData}
          text={`Are you sure you want to delete this blog?`}
        />
      ) : undefined}

      <SendMailModal
        setVisible={setSendMailModal}
        visible={sendMailModal}
        selectedItem={selectedId}
        // getAllJobsList={get}
        setTab={setTab}
        setLoading={setLoading}
        tab={tab}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
      <ViewProjects show={isProjectModal} setShow={setIsProjectModal} />
    </SidebarLayout>
  );
};

export default ManageBlogs;
