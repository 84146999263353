import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { InputField } from "../InputField";

export const CAncelModal = (props) => {
  const {
    setVisible,
    visible,
    handleDirectConfirm,
    isAskModel,
    description,
    setDescription,
    handleAskToClient,
    setIsAskModel,
    data,
    selected,
  } = props;

  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0 });

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  function convertToDaysAndMinutes() {
    const [remainingHours, minutes] = selected?.post_meta_details
      ?.differenceInHours
      ? selected?.post_meta_details?.differenceInHours?.split(":").map(Number)
      : "00:00";
    const days = Math.floor(remainingHours / 24);
    const hours = remainingHours % 24;
    const result = `${days} days ${hours} hours ${minutes} minutes`;
    setTimeLeft({ days, hours, minutes });
  }

  useEffect(() => {
    convertToDaysAndMinutes();
  }, [selected]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
        setIsAskModel(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cancellation Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="jobCancel">
        <div className="timerCard">
          <div className="jobStart">
            {`${
              !selected?.post_meta_details?.differenceInHours
                ? "This job has started on"
                : ""
            } ${selected?.post_meta_details?.starting_date} at ${
              loggedInUser?.user_data?.user_role === 12 ||
              loggedInUser?.user_data?.user_role === 14
                ? selected?.post_meta_details?.format_start_time
                : selected?.post_meta_details?.starting_time
            } ${
              selected?.post_meta_details?.time_zone
                ? "(" + selected?.post_meta_details?.time_zone + ")"
                : ""
            }`}
          </div>
          <h6>
            {selected?.post_meta_details?.differenceInHours
              ? ` The job will start in  ${
                  timeLeft?.days
                    ? `${timeLeft?.days} ${
                        timeLeft?.days == 1 ? "day" : "days"
                      }`
                    : ""
                } ${timeLeft?.hours ? `${timeLeft?.hours} hours` : ""}  ${
                  timeLeft?.minutes ? `${timeLeft?.minutes} minutes` : ""
                }`
              : ""}
          </h6>
        </div>

        {loggedInUser?.user_data?.user_role === 12 ||
        loggedInUser?.user_data?.user_role === 14 ? (
          <div>
            <div className="form-group">
              <p className="rejReason">
                {data?.ask_cancel_project_decline?.data?.status ? (
                  <>
                    <span>
                      Your request for job cancellation has reject by talent.
                    </span>
                    <span>
                      <b>Reason : </b>{" "}
                      {data?.ask_cancel_project_decline?.data?.reject_reason}
                    </span>
                  </>
                ) : data?.ask_cancel_project_pending?.data?.status ===
                  "pending" ? (
                  <span>
                    Your job cancellation request is pending. Please wait until
                    talent is taking action into it.
                  </span>
                ) : (
                  <span>
                    *You may cancel the job by selecting, "Cancellation Request"
                    with the talent. If they agree to the cancellation, there
                    will be no penalties besides transaction fees.
                  </span>
                )}
              </p>
              {!isAskModel ? (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      setIsAskModel(true);
                    }}
                    className={
                      data?.ask_cancel_project_decline?.data?.status &&
                      data?.ask_cancel_project_pending?.data?.status ===
                        "pending"
                        ? "btn_disable"
                        : ""
                    }
                    disabled={
                      data?.ask_cancel_project_decline?.data?.status ||
                      data?.ask_cancel_project_pending?.data?.status ===
                        "pending"
                    }
                  >
                    {data?.ask_cancel_project_decline?.data?.status
                      ? "Request Declined"
                      : data?.ask_cancel_project_pending?.data?.status ===
                        "pending"
                      ? "Request Submitted"
                      : "Request to cancel"}
                  </Button>
                </div>
              ) : undefined}

              {isAskModel ? (
                <InputField
                  placeholder="Write your reason message to cancel this project"
                  type="textarea"
                  value={description}
                  onChangeText={(e) => {
                    setDescription(e?.target?.value);
                  }}
                />
              ) : undefined}

              {isAskModel ? (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      handleAskToClient();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              ) : undefined}

              {isAskModel ? undefined : <div className="sepratorDiv">Or</div>}

              {isAskModel ? undefined : (
                <div>
                  {selected?.post_meta_details?.differenceInHours ? (
                    <>
                      <p>
                        <span>
                          * If you cancel the job 24 hours within the start
                          date, the model will receive 100% payment as a
                          compensation.
                        </span>
                      </p>
                      <p>
                        <span>
                          * If the job is canceled between 48 hours and 24 hours
                          before the start date, there will be a 50% refund and
                          the model will receive 50% as a compensation.
                        </span>
                      </p>
                      <p>
                        <span>
                          If the job is canceled more than 48 hours before the
                          start date, there will be no charges deducted.
                        </span>
                      </p>
                    </>
                  ) : (
                    <p>
                      Job has already started If you cancel this job, the model
                      will receive 100% payment as a compensation.
                    </p>
                  )}
                </div>
              )}

              {isAskModel ? undefined : (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      handleDirectConfirm();
                    }}
                  >
                    Cancel Now
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="form-group">
            <p className="rejReason">
              {data?.ask_cancel_project_decline?.data?.status ? (
                <>
                  {" "}
                  <span>
                    {`Your request for job cancellation has reject by client.`}
                  </span>
                  <span>
                    <b>Reason : </b>{" "}
                    {data?.ask_cancel_project_decline?.data?.reject_reason}
                  </span>
                </>
              ) : data?.ask_cancel_project_pending?.data?.status ===
                "pending" ? (
                <span>
                  Your job cancellation request is pending. Please wait until
                  client is taking action into it.
                </span>
              ) : (
                <span>
                  *You may cancel the job by selecting, "Cancellation Request"
                  with the client. If they agree to the cancellation, there will
                  be no penalties besides transaction fees.
                </span>
              )}
            </p>

            {isAskModel ? undefined : (
              <div className="footer-center">
                <Button
                  onClick={() => {
                    setIsAskModel(true);
                  }}
                  className={
                    data?.ask_cancel_project_decline?.data?.status &&
                    data?.ask_cancel_project_pending?.data?.status === "pending"
                      ? "btn_disable"
                      : ""
                  }
                  disabled={
                    data?.ask_cancel_project_decline?.data?.status ||
                    data?.ask_cancel_project_pending?.data?.status === "pending"
                  }
                >
                  {data?.ask_cancel_project_decline?.data?.status
                    ? "Request Declined"
                    : data?.ask_cancel_project_pending?.data?.status ===
                      "pending"
                    ? "Request Submitted"
                    : "Request to cancel"}
                </Button>
              </div>
            )}

            {isAskModel ? (
              <InputField
                placeholder="Write your reason message to cancel this project"
                type="textarea"
                value={description}
                onChangeText={(e) => {
                  setDescription(e?.target?.value);
                }}
              />
            ) : undefined}

            {isAskModel ? (
              <div className="footer-center">
                <Button
                  onClick={() => {
                    handleAskToClient();
                  }}
                >
                  Submit
                </Button>
              </div>
            ) : undefined}
            {isAskModel ? undefined : <div className="sepratorDiv">Or</div>}

            {isAskModel ? undefined : (
              <div>
                {selected?.post_meta_details?.differenceInHours ? (
                  <>
                    <p>
                      <span>
                        * If you cancel the job within 24 hours of the start
                        date, you will get 1 star rating for this job and 50% of
                        the amount of your next job on this platform will be
                        deducted as compensation or penalty.
                      </span>
                    </p>
                    <p>
                      <span>
                        * If you cancel the job in between 24 hours to 48 hours
                        of the start date, you will get 3 star rating for this
                        job and 25% of the amount of your next job on this
                        platform will be deducted as compensation or penalty.
                      </span>
                    </p>
                  </>
                ) : (
                  <p>
                    Job has already started If you cancel this job, the model
                    will receive 100% payment as a compensation.
                  </p>
                )}
              </div>
            )}

            {isAskModel ? undefined : (
              <div className="footer-center">
                <Button
                  onClick={() => {
                    handleDirectConfirm();
                  }}
                >
                  Cancel Now
                </Button>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
