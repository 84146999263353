import React, { useContext, useEffect, useState } from "react";
import {
  arrayUnion,
  deleteField,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Utility/firebase";
import { v4 as uuid } from "uuid";
import { AuthContext } from "../../Context/AuthContext";
import { ChatContext } from "../../Context/ChatContext";

const ChatInput = ({ setLoading }) => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const updateTypingStatus = async (chatId, isTyping) => {
    try {
      const otherUserId = data?.user?.uid;
      const typingStatusRef = doc(
        db,
        "typingStatus",
        `${otherUserId}_${currentUser?.uid}`
      );

      await setDoc(typingStatusRef, {
        typing: isTyping,
      });
    } catch (error) {
      console.error("Error updating typing status:", error);
    }
  };

  // const handleSend = async () => {
  //   setLoading(true);
  //   const chatId = data?.chatId;
  //   const recipientId = data?.user?.uid;
  //   const chatDocRef = doc(db, "chats", chatId);
  //   const chatDoc = await getDoc(chatDocRef);
  //   console.log(chatDoc.exists(),"klhgjkljj");

  //   // if (!chatDoc.exists()) {
  //   //   // If the chat document does not exist, create a new one
  //   //   await setDoc(chatDocRef, {
  //   //     messages: [], // You can initialize with an empty messages array
  //   //     createdAt: serverTimestamp(),
  //   //   });
  //   //   console.log("New chat document created");
  //   // }

  //   const userChatRef = doc(db, "userChats", recipientId);
  //   await updateDoc(userChatRef, {
  //     [`${chatId}.deletedAt`]: deleteField(),
  //   });

  //   await updateDoc(doc(db, "chats", data?.chatId), {
  //     messages: arrayUnion({
  //       id: uuid(),
  //       text,
  //       senderId: currentUser?.uid,
  //       date: Timestamp.now(),
  //       receiverId: data?.user?.uid,
  //       status: "sent",
  //     }),
  //   });

  //   await updateDoc(doc(db, "userChats", currentUser?.uid), {
  //     [data?.chatId + ".lastMessage"]: {
  //       text,
  //     },
  //     [data?.chatId + ".date"]: serverTimestamp(),
  //   });

  //   await updateDoc(doc(db, "userChats", data?.user?.uid), {
  //     [data?.chatId + ".lastMessage"]: {
  //       text,
  //     },
  //     [data?.chatId + ".date"]: serverTimestamp(),
  //   });
  //   // [data?.chatId + ".userInfo"]: {
  //   //   uid: currentUser?.uid,
  //   //   displayName: currentUser?.displayName,
  //   //   photoURL: currentUser?.photoURL,
  //   // },
  //   setLoading(false);
  //   setText("");
  //   updateTypingStatus(data?.chatId, false);
  // };


  const handleSend = async () => {
    setLoading(true);
    const chatId = data?.chatId;
    const recipientId = data?.user?.uid;
    const recipientRef = doc(db, "users", recipientId);
    const chatDocRef = doc(db, "chats", chatId);
    const chatDoc = await getDoc(chatDocRef);
  
    // Check if the recipient exists in the 'users' collection
    const recipientDoc = await getDoc(recipientRef);
    console.log(recipientDoc.exists(),"recipientDoc.exists()recipientDoc.exists()")
  
    if (!recipientDoc.exists()) {
      // If recipient doesn't exist, create a new user document
      await setDoc(recipientRef, {
        uid: recipientId,
        displayName: data?.user?.displayName || "Unknown User", // Add other fields as needed
        photoURL: data?.user?.photoURL || "", // Set default if necessary
        createdAt: serverTimestamp(),
      });
      console.log("New recipient registered");
    }
  
    // Check if the chat document exists
    if (!chatDoc.exists()) {
      // If the chat document does not exist, create a new one
      await setDoc(chatDocRef, {
        messages: [], // You can initialize with an empty messages array
        createdAt: serverTimestamp(),
      });
      console.log("New chat document created");
    }
  
    const userChatRef = doc(db, "userChats", recipientId);
    await updateDoc(userChatRef, {
      [`${chatId}.deletedAt`]: deleteField(),
    });
  
    // Add new message to the chat
    await updateDoc(doc(db, "chats", chatId), {
      messages: arrayUnion({
        id: uuid(),
        text,
        senderId: currentUser?.uid,
        date: Timestamp.now(),
        receiverId: recipientId,
        status: "sent",
      }),
    });
  
    // Update the 'lastMessage' and 'date' fields for both users
    await updateDoc(doc(db, "userChats", currentUser?.uid), {
      [chatId + ".lastMessage"]: {
        text,
      },
      [chatId + ".date"]: serverTimestamp(),
    });
  
    await updateDoc(doc(db, "userChats", recipientId), {
      [chatId + ".lastMessage"]: {
        text,
      },
      [chatId + ".date"]: serverTimestamp(),
    });
  
    setLoading(false);
    setText("");
    updateTypingStatus(chatId, false);
  };
  

  useEffect(() => {
    let typingTimeout;
    if (isTyping) {
      updateTypingStatus(data?.chatId, true);
      typingTimeout = setTimeout(() => {
        setIsTyping(false);
        updateTypingStatus(data?.chatId, false);
      }, 6000);
    }

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [isTyping, data?.chatId]);

  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => {
          setText(e.target.value);
          setIsTyping(true);
        }}
        onKeyDown={(e) => e.code === "Enter" && handleSend()}
        value={text}
      />
      <div className="send">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={(e) => setImg(e.target.files[0])}
        />
        <label htmlFor="file"></label>
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default ChatInput;
