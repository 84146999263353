import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import { Loader, DeletePost } from "../../Components";
import SidebarLayout from "../../layout/SidebarLayout";
import {
  cancelClientProposalApi,
  deleteJob,
  getPostedListing,
  repostJob,
} from "../../Redux/Services/OtherServices";
import { useDispatch, useSelector } from "react-redux";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import { routeName, showToast } from "../../Utility";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Constants";
import { CAncelModal } from "../../Components/Popups";

const ClientProjects = () => {
  let queryType = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [jobsList, setJobsList] = useState([]);
  const [tab, setTab] = useState(queryType || "posted");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");
  const [isRepostModal, setIsRepostModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);
  const [isDirectConfirm, setIsDirectConfirm] = useState(false);
  const [isAskModel, setIsAskModel] = useState(false);
  const [description, setDescription] = useState("");

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  useEffect(() => {
    getQueryData();
  }, []);

  const getQueryData = async () => {
    queryType = await getData(storageKey.SELECTED_JOB_TYPE);
    getAllJobsList(queryType || "posted");
    setTab(queryType || "posted");
  };

  const getAllJobsList = async (type) => {
    setLoading(true);
    let userID = await getData(storageKey?.USER_ID);
    let body = {
      client_id: partnerPermissions?.user_id || userID,
      job_status: type,
    };
    let res = await dispatch(getPostedListing(body));
    setLoading(false);
    if (res?.status == 200) {
      storeData(storageKey.SELECTED_JOB_TYPE, type);
      setTab(type);
      setJobsList(res?.results);
    } else {
      setJobsList([]);
    }
  };

  const handleRepostJob = async () => {
    let userID = await getData(storageKey?.USER_ID);
    let body = {
      user_id: partnerPermissions?.user_id || userID,
      project_id: selectedJob,
      action: "open",
    };
    setLoading(true);
    let res = await dispatch(repostJob(body));
    setLoading(false);
    if (res?.status == 200) {
      setTab("posted");
      setIsRepostModal(false);
      showToast("Job Reposted Successfully", "success");
      getAllJobsList("posted");
    } else {
    }
  };

  const handleCancelProposal = async (cancelType) => {
    let body = {
      action: "project_cancel_client",
      project_id: selectedJob,
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      canceled_type: cancelType,
      // proposal_id: selectedJob,
    };
    if (isAskModel) {
      body.description = description;
    }
    setLoading(true);
    let res = await dispatch(cancelClientProposalApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setIsDirectConfirm(false);
      getAllJobsList("posted");
      setIsCancelConfirm(false);
      setIsAskModel(false);
    }
  };

  const handleDeleteJob = async () => {
    let userID = await getData(storageKey?.USER_ID);
    let body = {
      user_id: partnerPermissions?.user_id || userID,
      post_id: selectedJob,
      action: "delete",
    };
    setLoading(true);
    let res = await dispatch(deleteJob(body));
    setLoading(false);
    if (res?.status == 200) {
      setTab("posted");
      setIsDeleteModal(false);
      getAllJobsList("posted");
    } else {
    }
  };

  const options = [
    {
      name: "Posted Projects",
      type: "posted",
    },
    {
      name: "Ongoing Projects",
      type: "ongoing",
    },
    {
      name: "Completed Projects",
      type: "completed",
    },
    {
      name: "Cancelled Projects",
      type: "cancelled",
    },
  ];

  return (
    <>
      <SidebarLayout>
        <Loader loading={loading} />
        <div class="dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="posted"
              id="jobs"
              activeKey={tab}
              onSelect={getAllJobsList}
            >
              {options?.map((item, index) => (
                <Tab key={index} eventKey={item.type} title={item.name}>
                  <div class="wt-managejobcontent">
                    {jobsList?.length
                      ? jobsList?.map((ele, ind) => {
                          return (
                            <>
                              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                                  <div class="wt-contenthead">
                                    <div class="wt-title">
                                      {ele?.post_meta_details
                                        ?.project_status && (
                                        <a
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <i
                                            style={{
                                              color:
                                                ele?.post_meta_details
                                                  ?.project_status ==
                                                "completed"
                                                  ? Colors?.green
                                                  : ele?.post_meta_details
                                                      ?.project_status ==
                                                    "cancelled"
                                                  ? Colors?.red
                                                  : Colors?.blue,
                                            }}
                                            class={
                                              ele?.post_meta_details
                                                ?.project_status == "completed"
                                                ? "fa fa-check-circle"
                                                : ele?.post_meta_details
                                                    ?.project_status ==
                                                  "cancelled"
                                                ? "fa fa-times-circle"
                                                : "fa fa-check-fa fa-spinner"
                                            }
                                          ></i>
                                          {"  "}
                                          {
                                            ele?.post_meta_details
                                              ?.project_status
                                          }
                                        </a>
                                      )}
                                      <h2
                                        onClick={() => {
                                          storeData(
                                            storageKey.SELECTED_JOB_TYPE,
                                            tab
                                          );
                                          const queryParams =
                                            new URLSearchParams({
                                              id: ele?.profile?.ID,
                                            });
                                          navigate(
                                            `${
                                              routeName.JOB_DETAILS
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                      >
                                        {ele?.profile?.post_title}
                                      </h2>
                                    </div>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span>
                                          <i class="far fa-flag"></i> Location :{" "}
                                          {ele?.post_meta_details?.country} |{" "}
                                          {ele?.post_meta_details?.city}
                                        </span>
                                      </li>
                                      <li>
                                        <a class="wt-clicksavefolder">
                                          <i class="far fa-folder"></i> Type:{" "}
                                          {ele?.post_meta_details?.project_type}
                                        </a>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="fa fa-usd"></i> Hourly Rate
                                          :{" "}
                                          {ele?.post_meta_details?.hourly_rate}
                                        </span>
                                      </li>
                                    </ul>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> Start
                                          Date :{" "}
                                          {
                                            ele?.post_meta_details
                                              ?.starting_date
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> End
                                          Date :{" "}
                                          {ele?.post_meta_details?.end_date}
                                        </span>
                                      </li>
                                    </ul>
                                    <div class="wt-btnarea-btn">
                                      {item?.type == "cancelled" ? (
                                        <span
                                          onClick={() => {
                                            setSelectedJob(ele?.profile?.ID);
                                            setIsRepostModal(true);
                                          }}
                                          class="wt-bt"
                                        >
                                          Repost
                                        </span>
                                      ) : null}

                                      {item?.type == "ongoing" ||
                                      item?.type === "completed" ? (
                                        <span
                                          onClick={() => {
                                            storeData(
                                              storageKey.SELECTED_JOB_TYPE,
                                              tab
                                            );
                                            const queryParams =
                                              new URLSearchParams({
                                                id: ele?.profile?.ID,
                                                status: item?.type,
                                              });
                                            navigate(
                                              `${
                                                routeName.PROPOSALS
                                              }?${queryParams.toString()}`
                                            );
                                          }}
                                          class="wt-bt"
                                        >
                                          Contract Details
                                        </span>
                                      ) : null}

                                      {item?.type == "posted" &&
                                      (loggedInUser?.user_data?.user_role ===
                                        12 ||
                                        loggedInUser?.user_data?.user_role ===
                                          14) ? (
                                        <>
                                          <span
                                            onClick={() => {
                                              storeData(
                                                storageKey.SELECTED_JOB_TYPE,
                                                tab
                                              );
                                              const queryParams =
                                                new URLSearchParams({
                                                  id: ele?.profile?.ID,
                                                });
                                              navigate(
                                                `${
                                                  ele?.post_meta_details
                                                    ?.model_type_req ===
                                                  "casting calls"
                                                    ? routeName.CASTING_JOB
                                                    : routeName.JOB_POST
                                                }?${queryParams.toString()}`
                                              );
                                            }}
                                            class="wt-bt"
                                          >
                                            Edit
                                          </span>
                                          <span
                                            onClick={() => {
                                              setSelectedJob(ele?.profile?.ID);
                                              setIsDeleteModal(true);
                                            }}
                                            class="wt-bt"
                                          >
                                            Delete
                                          </span>
                                        </>
                                      ) : null}

                                      {/* {ele?.profile?.post_status === "hired" &&
                                      new Date(
                                        ele?.post_meta_details?.starting_date +
                                          " " +
                                          ele?.post_meta_details?.starting_time
                                      ) >= new Date() &&
                                      (loggedInUser?.user_data?.user_role ===
                                        12 ||
                                        loggedInUser?.user_data?.user_role ===
                                          14) ? (
                                        <span
                                          onClick={() => {
                                            setSelectedJob(ele?.profile?.ID);
                                            setIsCancelConfirm(true);
                                          }}
                                          class="wt-bt"
                                        >
                                          Cancel Project
                                        </span>
                                      ) : undefined} */}
                                    </div>
                                  </div>
                                  <div class="wt-rightarea">
                                    {item?.type == "posted" ? (
                                      <div class="wt-hireduserstatus">
                                        <h4>
                                          {
                                            ele?.post_meta_details
                                              ?.proposal_count
                                          }
                                        </h4>
                                        <span>Proposal count</span>

                                        <h6
                                          class="wt-dashboradclock"
                                          style={{ color: Colors?.blue }}
                                          onClick={() => {
                                            storeData(
                                              storageKey.SELECTED_JOB_TYPE,
                                              tab
                                            );
                                            const queryParams =
                                              new URLSearchParams({
                                                id: ele?.profile?.ID,
                                                status: "posted",
                                              });
                                            navigate(
                                              `${
                                                routeName.PROPOSALS
                                              }?${queryParams.toString()}`
                                            );
                                          }}
                                        >
                                          View Proposals
                                        </h6>
                                      </div>
                                    ) : (
                                      <div class="wt-hireduserstatus">
                                        {item?.type !== "cancelled" ? (
                                          <h4>Hired</h4>
                                        ) : null}

                                        {item?.type === "cancelled" &&
                                        ele?.post_meta_details?.hide_status !==
                                          "hide" ? (
                                          <h4>Canceled</h4>
                                        ) : null}

                                        {item?.type === "cancelled" &&
                                        ele?.post_meta_details?.hide_status ===
                                          "hide" ? (
                                          <h4>Hide By Admin</h4>
                                        ) : null}

                                        <ul class="wt-hireduserimgs">
                                          {ele?.project_hired_users?.map(
                                            (user, i) => {
                                              return (
                                                <>
                                                  <li>
                                                    <figure>
                                                      {user?.proposal_details
                                                        ?.profile_image ? (
                                                        <img
                                                          src={
                                                            user
                                                              ?.proposal_details
                                                              ?.profile_image
                                                          }
                                                          title={
                                                            user
                                                              ?.proposal_details
                                                              ?.display_name
                                                              ? user?.proposal_details?.display_name?.split(
                                                                  " "
                                                                )?.[0] +
                                                                " " +
                                                                user?.proposal_details?.display_name
                                                                  ?.split(
                                                                    " "
                                                                  )?.[1]
                                                                  .substring(
                                                                    0,
                                                                    1
                                                                  )
                                                              : ""
                                                          }
                                                          alt="img description"
                                                          class="mCS_img_loaded"
                                                        />
                                                      ) : (
                                                        <i class="fas fa-user-circle"></i>
                                                      )}
                                                    </figure>
                                                  </li>
                                                </>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : undefined}
                    {!jobsList?.length ? (
                      <div className="wt-contenthead-center">
                        <p>No Jobs Found</p>
                      </div>
                    ) : undefined}
                  </div>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </SidebarLayout>
      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={handleDeleteJob}
          text="Are you sure you want to delete this Job?"
        />
      ) : undefined}

      {isRepostModal ? (
        <DeletePost
          setShow={setIsRepostModal}
          show={isRepostModal}
          title="Alert"
          handleConfirm={handleRepostJob}
          text="Are you sure you want to repost this Job?"
        />
      ) : undefined}

      {isDirectConfirm ? (
        <DeletePost
          setShow={setIsDirectConfirm}
          show={isDirectConfirm}
          title="Alert"
          handleConfirm={() => {
            handleCancelProposal("direct_cancel");
          }}
          text="Are you sure you want to cancel this project?"
        />
      ) : undefined}
      {isCancelConfirm ? (
        <CAncelModal
          setVisible={setIsCancelConfirm}
          visible={isCancelConfirm}
          title="Alert"
          handleDirectConfirm={() => {
            setIsCancelConfirm(false);
            setIsDirectConfirm(true);
          }}
          handleAskToClient={() => {
            setIsAskModel(true);
            if (!description && isAskModel) {
              showToast("Please add description");
            } else if(!description) {
              handleCancelProposal("ask_model");
            }
          }}
          setDescription={setDescription}
          description={description}
          isAskModel={isAskModel}
          setIsAskModel={setIsAskModel}
        />
      ) : undefined}
    </>
  );
};

export default ClientProjects;
