import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OTPInput from "otp-input-react";

export const VerifyOtp = (props) => {
  const { setVisible, visible, otp, setOtp, countDown, setCountDown, handleVerify } = props;

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Phone Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please enter the verification code that we have send on your registered phone number</p>
        <div className="optInput">
          <OTPInput
            value={otp}
            onChange={setOtp}
            autoFocus={false}
            OTPLength={6}
            otpType="number"
            disabled={false}

            // secure
          />
        </div>

        {countDown === 0 ? (
            <div className="rsnd_btn">
              <button onClick={() => handleVerify('resend')} className="btn_rd">
                Resend OTP
              </button>
            </div>
          ) : (
            <h5>
              The verify code will expire in
              <span className="count"> 00 : {countDown}</span>
            </h5>
          )}


      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            setVisible(false);
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            // setVisible(false);
            handleVerify("verify")
          }}
        >
          Verify
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
