import React from "react";
import { DropdownList, InputField } from "../../Components";
import { followers } from "../../Global";

const SocialMediaHandle = ({
  talent,
  socialLinks,
  setSocialLinks,
  videoLink,
  setVideoLink,
  reelLink,
  setReelLink,
  facebookFollowers,
  setFacebookFollowers,
  instagramFollowers,
  setInstagramFollowers,
  tiktokFollowers,
  setTiktokFollowers,
  twitterFollowers,
  setTwitterFollowers,
  vimeoFollowers,
  setVimeoFollowers,
  youtubeFollowers,
  setYoutubeFollowers,
}) => {
  return (
    <>
      <div class="form-section">
        <div className="form-section-title">Social Media Handles</div>

        <div class="form-group form-group-50">
          <InputField
            title="Facebook Link"
            type="text"
            value={socialLinks.facebook}
            placeholder="https://www.facebook.com/facebook-profile-link"
            onChangeText={(e) => {
              setSocialLinks({
                ...socialLinks,
                facebook: e?.target?.value,
              });
            }}
          />
          {talent != "Client" && talent != "Partners" ? (
            <DropdownList
              title="Facebook followers"
              placeholder="Select"
              options={followers}
              value={facebookFollowers}
              setValue={setFacebookFollowers}
              required={false}
            />
          ) : null}
          <InputField
            title="Instagram Link"
            type="text"
            value={socialLinks.instagram}
            placeholder="https://www.instagram.com/instagram-profile-link"
            onChangeText={(e) => {
              setSocialLinks({
                ...socialLinks,
                instagram: e?.target?.value,
              });
            }}
          />
          {talent != "Client" && talent != "Partners" ? (
            <DropdownList
              title="Instargam followers"
              placeholder="Select"
              options={followers}
              value={instagramFollowers}
              setValue={setInstagramFollowers}
            />
          ) : null}
          <InputField
            title="Twitter Link"
            type="text"
            value={socialLinks.twitter}
            placeholder="https://www.twitter.com/twitter-profile-link"
            onChangeText={(e) => {
              setSocialLinks({
                ...socialLinks,
                twitter: e?.target?.value,
              });
            }}
          />
          {talent != "Client" && talent != "Partners" ? (
            <DropdownList
              title="Twitter followers"
              placeholder="Select"
              options={followers}
              value={twitterFollowers}
              setValue={setTwitterFollowers}
            />
          ) : null}
          <InputField
            title="Youtube Link"
            type="text"
            value={socialLinks.youtube}
            placeholder="https://www.youtube.com/youtube-profile-link"
            onChangeText={(e) => {
              setSocialLinks({
                ...socialLinks,
                youtube: e?.target?.value,
              });
            }}
          />
          {talent != "Client" && talent != "Partners" ? (
            <DropdownList
              title="Youtube followers"
              placeholder="Select"
              options={followers}
              value={youtubeFollowers}
              setValue={setYoutubeFollowers}
            />
          ) : null}
          <InputField
            title="TikTok Link"
            type="text"
            value={socialLinks.tikTok}
            placeholder="https://www.tiktok.com/tiktok-profile-link"
            onChangeText={(e) => {
              setSocialLinks({
                ...socialLinks,
                tikTok: e?.target?.value,
              });
            }}
          />
          {talent != "Client" && talent != "Partners" ? (
            <DropdownList
              title="TikTok followers"
              placeholder="Select"
              options={followers}
              value={tiktokFollowers}
              setValue={setTiktokFollowers}
            />
          ) : null}
          <InputField
            title="Vimeo Link"
            type="text"
            value={socialLinks.vimeo}
            placeholder="https://www.vimeo.com/vimeo-profile-link"
            onChangeText={(e) => {
              setSocialLinks({
                ...socialLinks,
                vimeo: e?.target?.value,
              });
            }}
          />
          {talent != "Client" && talent != "Partners" ? (
            <DropdownList
              title="Vimeo followers"
              placeholder="Select"
              options={followers}
              value={vimeoFollowers}
              setValue={setVimeoFollowers}
            />
          ) : null}
          {talent != "Client" && talent != "Partners" ? (
            <InputField
              title="Your website"
              type="text"
              value={socialLinks.website}
              placeholder="https://your-website-link.com/"
              onChangeText={(e) => {
                setSocialLinks({
                  ...socialLinks,
                  website: e?.target?.value,
                });
              }}
            />
          ) : null}
          {talent == "Actor" || talent === "Actor Kid" ? (
            <>
              <InputField
                title="Video Link"
                type="text"
                value={videoLink}
                placeholder="https://your-video-link.mp4"
                onChangeText={(e) => {
                  setVideoLink(e.target.value);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default SocialMediaHandle;
