import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const ViewProjects = (props) => {
  const { show, setShow } = props;

  const data = [];

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Projects List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableContainer className="table_container">
          <div className="heading"></div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Project Budget</TableCell>
                <TableCell>Project Share</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            {data?.length ? (
              data?.map((item, i) => {
                return (
                  <TableBody>
                    <TableRow></TableRow>
                  </TableBody>
                );
              })
            ) : (
              <TableBody>
                <TableCell align="center" colSpan={10} sx={{ color: "black" }}>
                  No Projects Found
                </TableCell>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Modal.Body>
    </Modal>
  );
};
