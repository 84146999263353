import React, { useEffect, useRef, useState } from "react";
import { Routing } from "./Routing";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/css/normalize.css";
import "./Assets/css/scrollbar.css";
import "./Assets/css/fontawesome/fontawesome-all.css";
import "./Assets/css/font-awesome.min.css";
import "./Assets/css/owl.carousel.min.css";
import "./Assets/css/linearicons.css";
import "./Assets/css/jquery-ui.css";
import "./Assets/css/tipso.css";
import "./Assets/css/chosen.css";
import "./Assets/css/main.css";
import "./Assets/css/dashboard.css";
import "./Assets/css/color.css";
import "./Assets/css/transitions.css";
import "./Assets/css/style.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Assets/css/responsive.css";
import "./Message.scss";
import { useDispatch } from "react-redux";
import { getOptionsData } from "./Redux/Services/AuthServices";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "./Utility/firebase";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptionsData());
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    let userId;
    let statusInterval;

    const updateUserStatus = async (status) => {
      if (userId) {
        try {
          await setDoc(
            doc(db, 'userStatus', userId),
            { status },
            { merge: true }
          );
        } catch (error) {
          console.error('Error updating status:', error.message);
        }
      }
    };

    // Handle user logout or page unload
    const handleBeforeUnload = () => {
      updateUserStatus('offline');
    };

    // Handle tab visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateUserStatus('online');
        clearInterval(statusInterval);
        statusInterval = setInterval(() => {
          updateUserStatus('online');
        }, 10000); // Every 10 seconds
      } else {
        updateUserStatus('away');
        clearInterval(statusInterval);
      }
    };

    // Listen for authentication state changes
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true); // Track logged-in status
        userId = user.uid;
        updateUserStatus('online');

        // Listen for tab visibility changes
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Handle page/tab unload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Periodically confirm user is online while the tab is active
        statusInterval = setInterval(() => {
          updateUserStatus('online');
        }, 10000);
      } else {
        setIsLoggedIn(false); // Not logged in
        updateUserStatus('offline');
        userId = null;
      }
    });

    // Clean up event listeners when the component unmounts
    return () => {
      unsubscribeAuth();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      clearInterval(statusInterval);
      updateUserStatus('offline'); // Make sure status is updated to offline on cleanup
    };
  }, []);

  // const handleLogout = async () => {
  //   try {
  //     // Set user offline before logging out
  //     const user = auth.currentUser;
  //     if (user) {
  //       await setDoc(doc(db, 'userStatus', user.uid), { status: 'offline' }, { merge: true });
  //     }
  //     // Proceed with logout
  //     await signOut(auth);
  //   } catch (error) {
  //     console.error('Error signing out:', error.message);
  //   }
  // };

  //  console.log = () => {}
  
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
