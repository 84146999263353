import React, { useEffect, useState } from "react";
import SidebarLayout from "../../../layout/SidebarLayout";
import { DeletePost, Loader, PostCard } from "../../../Components";
import { useDispatch, useSelector } from "react-redux";
import { likeDislike } from "../../../Redux/Services/OtherServices";
import {
  getAdminPortfolioApi,
  hideShowReportedApi,
} from "../../../Redux/Services/AdminServices";
import { SitePagination } from "../../../Components/filtersComponents";

const AdminPortfolio = () => {
  const dispatch = useDispatch();
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [hideShowModal, setHideShowModal] = useState(false);

  const resultsPerPage = 21;
  let totalPages = Math.ceil(totalCount / resultsPerPage);

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const getPortfolioData = async (pg) => {
    try {
      let body = {
        page_number: 3,
        per_page: 21,
      };
      setLoading(true);
      let res = await dispatch(getAdminPortfolioApi(body));
      setLoading(false);
      if (res?.status === 200) {
        setPortfolios(res?.results?.data);
        setTotalCount(res?.results?.pagination?.total_users);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeletePost = async () => {};

  const handleLikeUnlike = async (type, id) => {
    try {
      let body = {
        type: type,
        post_id: id,
        user_id:
          partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      };
      setLoading(true);
      let res = await dispatch(likeDislike(body));
      setLoading(false);
      if (res?.status === 200) {
        getPortfolioData(page);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPortfolioData(1);
  }, []);

  const onPageChange = (newPage) => {
    setPage(newPage);
    getPortfolioData(newPage);
  };

  const handleHideShow = async () => {
    try {
      let body = {
        super_id: loggedInUser?.user_data?.user_id,
        action:
          selectedId?.extra?.hide_profile_status !== "show" ? "hide" : "show",
        post_id: selectedId?.post_details?.ID,
        action_to: "portfolio",
      };
      setLoading(true);
      let res = await dispatch(hideShowReportedApi(body));
      setLoading(false);
      if (res?.status === 200) {
        setHideShowModal(false);
        getPortfolioData();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <div className="post-card-grid">
            <h3 className="postTitl">Portfolios</h3>
            <div className="row">
              {portfolios?.length ? (
                portfolios?.map((item, index) => (
                  <PostCard
                    key={index}
                    type="portfolio"
                    setIsDeleteModal={setIsDeleteModal}
                    cardData={item}
                    setSelectedId={setSelectedId}
                    handleLikeUnlike={handleLikeUnlike}
                    page="manage"
                    route="admin"
                    setHideShowModal={setHideShowModal}
                    selectedId={selectedId}
                  />
                ))
              ) : loading ? undefined : (
                <span>No Portfolio found</span>
              )}
            </div>
          </div>
          <SitePagination
            module={portfolios}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
            setPage={setPage}
          />
        </div>
        {isDeleteModal ? (
          <DeletePost
            setShow={setIsDeleteModal}
            show={isDeleteModal}
            title="Alert"
            handleConfirm={handleDeletePost}
            text="Are you sure you want to delete this portfolio?"
          />
        ) : undefined}

        {hideShowModal ? (
          <DeletePost
            setShow={setHideShowModal}
            show={hideShowModal}
            title="Alert"
            handleConfirm={() => {
              handleHideShow();
            }}
            text={`Are you sure you want to ${
              selectedId?.extra?.hide_profile_status === "show"
                ? "hide"
                : "show"
            } this Portfolio?`}
          />
        ) : undefined}
      </main>
    </SidebarLayout>
  );
};

export default AdminPortfolio;
