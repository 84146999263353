import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export const ReportedUsersModal = (props) => {
  const { show, setShow, reportedData } = props;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          List of Reports
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableContainer className="table_container">
          <div className="heading"></div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Profile</TableCell>
                <TableCell align="center">Reason</TableCell>
                <TableCell align="center">Comment</TableCell>
              </TableRow>
            </TableHead>
            {reportedData?.length ? (
              reportedData?.map((item, i) => {
                return (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        <div className="userImgName">
                          <img
                            src={item?.reported_user_data?.attachment?.url}
                            alt="profile"
                          />
                          {
                            item?.reported_user_data?.display_name || ""
                          }
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {item?.report_details?.post_title || ""}
                      </TableCell>
                      <TableCell align="center">
                        {item?.report_details?.post_content || ""}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })
            ) : (
              <TableBody>
                <TableCell align="center" colSpan={10} sx={{ color: "black" }}>
                  No Reported Users Found
                </TableCell>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Modal.Body>
    </Modal>
  );
};
