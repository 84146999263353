import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const IdentityRequest = (props) => {
  const { text, show, setShow, title,  onAccept, onReject, btn1Text, btn2Text } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer className="footer-center">
      
        <Button
          onClick={() => {
            if (onAccept) {
              onAccept()
            }
            setShow(false);
          }}
        >
          {btn1Text || 'Accept'}
        </Button>
        <Button
          onClick={() => {
            if (onReject) {
              onReject()
            }
            setShow(false);
          }}
        >
          {btn2Text || 'Reject'}
        </Button>
        <Button
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
