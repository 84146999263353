import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routeName, showToast } from "../../../Utility";
import { useDispatch, useSelector } from "react-redux";
import SidebarLayout from "../../../layout/SidebarLayout";
import { AdminTable, DeletePost, Loader } from "../../../Components";
import {
  AdminResendPassword,
  DeleteReason,
  IdentityRequest,
  RejectionReason,
  SendMailModal,
  ViewIdentity,
  ViewProjects,
} from "../../../Components/Popups";
import {
  ActorIdRequestApi,
  acceptUserIdApi,
  adminResetPasswordApi,
  allActorListApi,
  incompleteActorListAPi,
  newActorsListAPi,
  profileApproveDeclineApi,
  rejectUserIdApi,
  rejectedActorApi,
  verifiedActorApi,
} from "../../../Redux/Services/AdminServices";
import {
  getAccountSettingDetails,
} from "../../../Redux/Services/OtherServices";
import { getData, storageKey } from "../../../Utility/Storage";
import { selectedTabAction } from "../../../Redux/Actions/AdminActions";

const ActorList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const tabs = queryParams?.get("tab");

  const [tab, setTab] = useState(tabs || 1);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [isProjectModal, setIsProjectModal] = useState(false);
  const [isIdentityModal, setIsIdentityModal] = useState(false);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [isResendModal, setIsResendModal] = useState(false);
  const [isIdentityViewModal, setIsIdentityViewModal] = useState(false);
  const [password, setPassword] = useState("");
  const [isRejectionProfile, setIsRejectionProfile] = useState(false);
  const [isRejectionIdentity, setIsRejectionIdentity] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [isDeleteReason, setIsDeleteReason] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const tabSelected = useSelector((state) => state.authReducer.tabSelected);

  let totalPages = Math.ceil(totalCount / 20);

  const onPageChange = (newPage) => {
    setPage(newPage);
    handleGetFunctions(tab, newPage);
  };

  const options = [
    {
      name: "All",
      type: 1,
    },
    {
      name: "Verified Users",
      type: 2,
    },
    {
      name: "New Applications",
      type: 3,
    },
    {
      name: "Incomplete Applications",
      type: 4,
    },
    {
      name: "ID Verification Request",
      type: 5,
    },
    {
      name: "Rejected Accounts",
      type: 6,
    },
  ];

  const getAllModelsList = async (pages) => {
    setLoading(true);
    let body = {
      search: debouncedSearchTerm,
      page_number: pages,
      per_page: "20",
    };
    let res = await dispatch(allActorListApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setList([]);
    }
  };

  const getVerifiedModels = async (pages) => {
    setLoading(true);
    let body = {
      search: debouncedSearchTerm,
      page_number: pages,
      per_page: "20",
    };
    let res = await dispatch(verifiedActorApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setList([]);
    }
  };

  const getNewApplicationsList = async (pages) => {
    setLoading(true);
    let body = {
      search: debouncedSearchTerm,
      page_number: pages,
      per_page: "20",
    };
    // console.log(body, "dsfsdfds");
    let res = await dispatch(newActorsListAPi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setList([]);
    }
  };

  const getIncompleteUsers = async (pages) => {
    setLoading(true);
    let body = {
      search: debouncedSearchTerm,
      page_number: pages,
      per_page: "20",
    };
    let res = await dispatch(incompleteActorListAPi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setList([]);
    }
  };

  const getVerifyRequestUsers = async (pages) => {
    setLoading(true);
    let body = {
      search: debouncedSearchTerm,
      page_number: pages,
      per_page: "20",
    };
    let res = await dispatch(ActorIdRequestApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setList([]);
    }
  };

  const getRejectedUsers = async (pages) => {
    setLoading(true);
    let body = {
      search: debouncedSearchTerm,
      page_number: pages,
      per_page: "20",
    };
    let res = await dispatch(rejectedActorApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setList([]);
    }
  };

  const handleGetFunctions = (type, pages) => {
    if (Number(type) === 1) {
      getAllModelsList(pages);
    } else if (Number(type) === 2) {
      getVerifiedModels(pages);
    } else if (Number(type) === 3) {
      getNewApplicationsList(pages);
    } else if (Number(type) === 4) {
      getIncompleteUsers(pages);
    } else if (Number(type) === 5) {
      getVerifyRequestUsers(pages);
    } else if (Number(type) === 6) {
      getRejectedUsers(pages);
    }
  };

  const handleDeleteModel = async () => {
    let body = {
      user_id:
        tab === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      profile_id: selectedId?.profile?.ID,
      reason: selectedReason,
      description: rejectionReason,
      action: "delete",
    };
    console.log(body, selectedId, "dfdfd");
    setLoading(true);

    let res = await dispatch(getAccountSettingDetails(body));
    setLoading(false);
    if (res?.status == 200) {
      setIsDeleteModal(false);
      setIsDeleteReason(false);
      setRejectionReason("");
      setSelectedReason("");
      handleGetFunctions(tab, page);
    } else {
    }
  };

  const handleGeneratePassowrd = () => {
    const specialSymbols = "!@#$%()";
    const capitalLetters = "ABCDEFGHJKPQRSTUXYZ";
    const smallLetters = "abcdefghjkopqrstuxyz";
    const numbers = "23456789";

    let password2 = "";

    password2 += specialSymbols.charAt(
      Math.floor(Math.random() * specialSymbols.length)
    );
    password2 += capitalLetters.charAt(
      Math.floor(Math.random() * capitalLetters.length)
    );
    password2 += smallLetters.charAt(
      Math.floor(Math.random() * smallLetters.length)
    );
    password2 += numbers.charAt(Math.floor(Math.random() * numbers.length));

    const allCharacters =
      specialSymbols + capitalLetters + smallLetters + numbers;
    for (let i = 0; i < 4; i++) {
      password2 += allCharacters.charAt(
        Math.floor(Math.random() * allCharacters.length)
      );
    }

    password2 = password2
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
    console.log(password2);
    setPassword(password2);
    // return password;
  };

  const handleResendPassword = async () => {
    if (!password) {
      showToast("Please generate password first", "error");
      return;
    }
    setLoading(true);
    let body = {
      user_id:
        tab === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      password,
    };
    let res = await dispatch(adminResetPasswordApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setIsResendModal(false);
    } else {
    }
  };

  const handleAcceptVerification = async () => {
    let body = {
      user_id:
        tab === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      identity_id: selectedId?.identity?.attached_id?.[0] || "",
    };

    setLoading(true);

    let res = await dispatch(acceptUserIdApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setIsIdentityModal(false);
      handleGetFunctions(tab, page);
    } else {
    }
  };

  const handleRejectVerification = async () => {
    let body = {
      user_id:
        tab === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      identity_id: selectedId?.identity?.attached_id?.[0] || "",
      // action: "reupload",
      reason: rejectionReason,
    };
    setLoading(true);

    let res = await dispatch(rejectUserIdApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setRejectionReason("");
      setIsRejectionIdentity(false);
      setIsIdentityModal(false);
      // setTab(6)
      // getRejectedUsers(1);
      handleGetFunctions(tab, page);
    } else {
    }
  };

  const handleAcceptRejectProfile = async (type) => {
    const userId = await getData(storageKey.USER_ID);
    console.log(userId);
    let body = {
      user_id:
        tab === 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      super_id: userId,
      action: type,
    };
    if (type === "decline") {
      body.reason = rejectionReason;
    }
    setLoading(true);

    let res = await dispatch(profileApproveDeclineApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setRejectionReason("");
      setIsRejectionProfile(false);
      setIsIdentityModal(false);
      handleGetFunctions(tab, page);
    } else {
    }
  };

  useEffect(() => {
    if (tabSelected) {
      setTab(tabSelected);
      handleGetFunctions(tabSelected, "1");
    } else if (loggedInUser) {
      handleGetFunctions(tab, "1");
    }
  }, [debouncedSearchTerm, loggedInUser]);

  return (
    <SidebarLayout>
      <Loader loading={loading} />

      <AdminTable
        options={options}
        role="admin"
        list={list}
        setList={setList}
        setTab={setTab}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        setDebouncedSearchTerm={setDebouncedSearchTerm}
        noDataFound={"No Actor Found"}
        totalPages={totalPages}
        page={page}
        handleGetFunctions={handleGetFunctions}
        onPageChange={onPageChange}
        setPage={setPage}
        onBtnPress={() => {
          dispatch(selectedTabAction(tab));
          const queryParams = new URLSearchParams({
            type: "actor",
          });
          navigate(`${routeName.ADD_USER}?${queryParams.toString()}`);
        }}
        tab={tab}
        Heading="Manage Actors"
        btnText="Add New Users"
        setSelectedId={setSelectedId}
        setIsDeleteModal={setIsDeleteModal}
        setIsIdentityModal={setIsIdentityModal}
        setIsResendModal={setIsResendModal}
        setIsIdentityViewModal={setIsIdentityViewModal}
        setIsProfileModal={setIsProfileModal}
        onEditClick={(id) => {
          dispatch(selectedTabAction(tab));
          const queryParams = new URLSearchParams({
            type: "actor",
            id,
          });
          navigate(`${routeName.ADD_USER}?${queryParams.toString()}`);
        }}
        part="email"
        setSendMailModal={setSendMailModal}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        totalCount={totalCount}
        isAllSelected={isAllSelected}
        setIsAllSelected={setIsAllSelected}
      />

      <SendMailModal
        setVisible={setSendMailModal}
        visible={sendMailModal}
        selectedItem={selectedId}
        setTab={setTab}
        setLoading={setLoading}
        tab={tab}
        type="custom"
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        isAllSelected={isAllSelected}
        setIsAllSelected={setIsAllSelected}
        totalCount={totalCount}
        from="actor"
        profile_type={
          Number(tab) === 1
            ? "all_actor"
            : Number(tab) === 2
            ? "verified_actor"
            : Number(tab) === 3
            ? "new_actor"
            : Number(tab) === 4
            ? "incomplete_actor"
            : Number(tab) === 5
            ? "verification_actor"
            : "rejected_actor"
        }
      />

      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={() => {
            setIsDeleteModal(false);
            setIsDeleteReason(true);
          }}
          text={`Are you sure you want to delete this actor?`}
        />
      ) : undefined}

      {isDeleteReason ? (
        <DeleteReason
          setVisible={setIsDeleteReason}
          visible={isDeleteReason}
          title="Deleted reason"
          handleSubmit={handleDeleteModel}
          text={`Please add reason why you want to delete user profile ?`}
          reason={selectedReason}
          setReason={setSelectedReason}
          value={rejectionReason}
          setValue={setRejectionReason}
        />
      ) : undefined}

      {isIdentityViewModal ? (
        <ViewIdentity
          setShow={setIsIdentityViewModal}
          show={isIdentityViewModal}
          data={selectedId}
        />
      ) : undefined}

      {isResendModal ? (
        <AdminResendPassword
          setVisible={setIsResendModal}
          visible={isResendModal}
          handleClick={handleResendPassword}
          handleGeneratePassowrd={handleGeneratePassowrd}
          password={password}
          setPassword={setPassword}
        />
      ) : undefined}

      {isIdentityModal ? (
        <IdentityRequest
          setShow={setIsIdentityModal}
          show={isIdentityModal}
          title="Identity Verification"
          onAccept={handleAcceptVerification}
          onReject={() => {
            setIsIdentityModal(false);
            setIsRejectionIdentity(true);
          }}
          text={`Are you sure, you want to verify identity of this user?`}
        />
      ) : undefined}

      {isRejectionIdentity ? (
        <RejectionReason
          handleSubmit={handleRejectVerification}
          setVisible={setIsRejectionIdentity}
          visible={isRejectionIdentity}
          value={rejectionReason}
          setValue={setRejectionReason}
          title="Rejection reason"
          text="Please add reason why you want to reject identity application of this user ?"
        />
      ) : undefined}

      {isProfileModal ? (
        <IdentityRequest
          setShow={setIsProfileModal}
          show={isProfileModal}
          btn1Text="Accept"
          btn2Text="Reject"
          title="Account Verification"
          onAccept={() => handleAcceptRejectProfile("approved")}
          onReject={() => {
            setIsProfileModal(false);
            setIsRejectionProfile(true);
          }}
          text={`Are you sure, you want to verify Account of this user?`}
        />
      ) : undefined}

      {isRejectionProfile ? (
        <RejectionReason
          handleSubmit={() => handleAcceptRejectProfile("decline")}
          setVisible={setIsRejectionProfile}
          visible={isRejectionProfile}
          value={rejectionReason}
          setValue={setRejectionReason}
          title="Rejection reason"
          text="Please add reason why you want to reject user application ?"
        />
      ) : undefined}

      <ViewProjects show={isProjectModal} setShow={setIsProjectModal} />
    </SidebarLayout>
  );
};

export default ActorList;
