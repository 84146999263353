import React from "react";
import { Images } from "../Constants/Images";
import { routeName } from "../Utility";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <footer
        id="wt-footer"
        className="wt-footer wt-footerfour wt-haslayout wt-footer-v1"
      >
        <div className="wt-footerholder wt-haslayout wt-sectionspace wt-widgets-active">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-lg-6">
                <div className="wt-footerlogohold">
                  <strong className="wt-logo">
                    <a
                      onClick={() => navigate(routeName?.HOME)}
                      href="javascript:void(0);"
                    >
                      <img src={Images?.logo} />
                    </a>
                  </strong>
                  <div className="wt-description">
                    <p>
                      The Book Sculp is a modeling and talent agency that
                      combines an artistic eye with working industry knowledge
                      to meet the needs of models, advertising professionals,
                      and photographers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 re">
                <div className="wt-footercol wt-widgetcompany footer-link-1">
                  <div className="wt-fwidgettitle">
                    <h3>Helpful Links</h3>
                  </div>
                  <ul className="wt-fwidgetcontent">
                    <li>
                      <a onClick={() => navigate(routeName?.PRIVACY_POLICY)}>
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a onClick={() => navigate(routeName?.TERMS_CONTIONS)}>
                        Terms of Service
                      </a>
                    </li>
                    <li>
                      <a onClick={() => navigate(routeName?.CONTACT_US)}>
                        Give us your feedback
                      </a>
                    </li>
                    <li>
                      <a onClick={() => navigate(routeName?.FAQ)}>FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 re">
                <div className="wt-footercol wt-widgetcompany footer-link-2">
                  <div className="wt-fwidgettitle">
                    <h3>Contact Us</h3>
                  </div>
                  <ul className="wt-fwidgetcontent">
                    <li>
                      <a target="_blank" href="">
                        Email
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="mailto:info@booksculp.com">
                        info@booksculp.com
                      </a>
                    </li>
                  </ul>
                  <ul className="wt-socialiconssimple wt-socialiconfooter">
                    <li className="wt-facebook">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/booksculp/"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li className="wt-twitter">
                      <a target="_blank" href="https://twitter.com/booksculp">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="wt-instagram">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/booksculp/"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li className="wt-linkedin">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/booksculp-agency"
                      >
                        <i className="fa fa-linkedin-square"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="downloadIcons">
                    <a href="https://apps.apple.com/in/app/book-sculp/id6444509575">
                      <img src="https://booksculp.com/LIVE/uploads/appstore.png" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.sculp_agency">
                      <img src="https://booksculp.com/LIVE/uploads/playstore.webp" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <p className="wt-copyrights">
                {`Book Sculp ${moment().format("YYYY")} © All Rights Reserved.`}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
