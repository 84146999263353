import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { showToast } from "../../Utility";
import { DropdownList } from "../DropdownList";

export const DeleteReason = (props) => {
  const {
    reason,
    setReason,
    setVisible,
    visible,
    title,
    text,
    value,
    setValue,
    handleSubmit,
  } = props;

  const submit = () => {
    if (!reason) {
      showToast("Please select rejection reason", "error");
      return;
    }
    if (!value) {
      showToast("Please add description", "error");
      return;
    }
    handleSubmit();
  };

  const reasons = [
    {
      label: "This is Fake",
      value: "This is Fake",
    },
    {
      label: "Their behaviour is inappropriate or abusive",
      value: "Their behaviour is inappropriate or abusive",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
        <div className="form-group">
          <DropdownList
            required
            title="Select Reason to Leave"
            placeholder="Select Reason to Leave"
            options={reasons}
            value={reason}
            setValue={setReason}
          />
        </div>
        <div className="form-group">
          <textarea
            type="text"
            name="reason"
            id="reason"
            class="form-control"
            placeholder={"Add Description"}
            onChange={(val) => {
              setValue(val.target.value);
            }}
            value={value}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <button
          type="submit"
          className="wt-btn wt-save-identity"
          onClick={submit}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};
