import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const DeletePost = (props) => {
  const { text, show, setShow, title, handleConfirm } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            setShow(false);
          }}
        >
          No
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            setShow(false);
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
