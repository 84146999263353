// import React, { useContext, useEffect, useRef, useState } from "react";
// import { AuthContext } from "../../Context/AuthContext";
// import { ChatContext } from "../../Context/ChatContext";
// import {
//   doc,
//   updateDoc,
//   arrayRemove,
//   getDoc,
//   arrayUnion,
//   onSnapshot,
// } from "firebase/firestore"; // Firestore functions for deleting
// import { db } from "../../Utility/firebase";
// import { DeleteChat } from "../../Components/Popups";

// const Message = ({ message }) => {
//   const { currentUser } = useContext(AuthContext);
//   const { data, dispatch } = useContext(ChatContext);
//   const ref = useRef();
//   const [showDeleteOptions, setShowDeleteOptions] = useState(false);
//   const [isEditing, setIsEditing] = useState(false); // New state for editing
//   const [editedMessage, setEditedMessage] = useState(message?.text || "");

//   console.log(message,"llll>>>>>>>>>>>")
//   // Scroll to the new message when it's rendered
//   useEffect(() => {
//     ref.current?.scrollIntoView({ behavior: "smooth" });
//   }, [message, data, currentUser]);

//   // Convert the message timestamp to a readable time
//   const convertTimestampToTime = (timestamp) => {
//     const date = new Date(
//       timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
//     );
//     return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
//   };

//   // If message.date exists, format the time
//   const messageTime = message?.date ? convertTimestampToTime(message.date) : "";

//   const handleSelect = (u) => {
//     dispatch({ type: "CHANGE_USER", payload: u });
//   };

//   // Delete message for everyone
//   const handleDeleteForEveryone = async () => {
//     try {
//       const chatRef = doc(db, "chats", data.chatId);
//       await updateDoc(chatRef, {
//         messages: arrayRemove(message),
//       });
//     } catch (err) {
//       console.error("Error deleting message for everyone: ", err);
//     }
//   };

//   const handleDeleteForMe = async () => {
//     try {
//       const userRef = doc(db, "users", currentUser.uid);
//       await updateDoc(userRef, {
//         deletedMessages: arrayUnion(message.id),
//       });
//       handleSelect({
//         displayName: data?.user?.displayName,
//         uid: data?.user?.uid,
//         photoURL: data?.user?.photoURL,
//       })
//     } catch (err) {
//       console.error("Error updating deleted messages for user: ", err);
//     }
//   };

//   const handleEditMessage = async () => {
//     try {
//       const chatRef = doc(db, "chats", data?.chatId);
//       const updatedMessages = data?.messages?.map((msg) =>
//         msg?.id === message?.id ? { ...msg, text: editedMessage } : msg
//       );
//       await updateDoc(chatRef, {
//         messages: updatedMessages,
//       });
//       setIsEditing(false); // Close the edit mode after updating
//     } catch (err) {
//       console.error("Error editing message: ", err);
//     }
//   };

//   // Check if the message is deleted for the current user
//   const [userDeletedMessages, setUserDeletedMessages] = useState([]);

//   useEffect(() => {
//     const fetchDeletedMessages = async () => {
//       try {
//         const userRef = doc(db, "users", currentUser.uid);
//         const userDoc = await getDoc(userRef);
//         if (userDoc.exists()) {
//           setUserDeletedMessages(userDoc.data().deletedMessages || []);
//         }
//       } catch (err) {
//         console.error("Error fetching deleted messages: ", err);
//       }
//     };

//     fetchDeletedMessages();
//   }, [currentUser?.uid]);

//   useEffect(() => {
//     // Real-time listener for deleted messages
//     const userRef = doc(db, "users", currentUser?.uid);
//     const unsubscribe = onSnapshot(userRef, (docs) => {
//       if (docs?.exists()) {
//         setUserDeletedMessages(docs.data().deletedMessages || []);
//       }
//     });

//     // Clean up the listener on component unmount
//     return () => unsubscribe();
//   }, [currentUser?.uid]);

//   if (userDeletedMessages?.includes(message?.id)) {
//     return null; // Don't render the message if it has been deleted for me
//   }

//   return (
//     <div
//       ref={ref}
//       className={`message ${message?.senderId === currentUser?.uid && "owner"}`}
//     >
//       <div className="messageInfo">
//         <img
//           src={
//             message.senderId === currentUser.uid
//               ? currentUser.photoURL
//               : data?.user?.photoURL || data?.user?.photoURL
//           }
//           alt=""
//           className="msgByUser"
//         />
//       </div>
//       <div className="messageContent">
//         <p style={{ color: "black" }}>{message?.text}</p>
//         {message?.img && <img src={message?.img} alt="" />}
//         <ul className="signleMsgOpt">
//           {message?.senderId === currentUser?.uid ? (
//             <li>
//               <i
//                 className={`fas fa-check-double ${
//                   message?.status === "read" ? "readMsg" : ""
//                 }`}
//               ></i>
//             </li>
//           ) : undefined}
//           <li>{messageTime}</li>
//           <li>
//             <span
//               onClick={() => setShowDeleteOptions(!showDeleteOptions)}
//               style={{ cursor: "pointer" }}
//             >
//               Delete
//             </span>
//           </li>
//         </ul>
//       </div>
//       {showDeleteOptions ? (
//         <DeleteChat
//           firstTitle={
//             message?.senderId === currentUser?.uid ? "Delete for Everyone" : ""
//           }
//           secondTitle="Delete for me"
//           firstClick={() => {
//             handleDeleteForEveryone();
//             setShowDeleteOptions(false);
//           }}
//           secondClick={() => {
//             handleDeleteForMe();
//             setShowDeleteOptions(false);
//           }}
//           show={showDeleteOptions}
//           setShow={setShowDeleteOptions}
//         />
//       ) : undefined}
//     </div>
//   );
// };

// export default Message;

import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { ChatContext } from "../../Context/ChatContext";
import {
  doc,
  updateDoc,
  arrayRemove,
  getDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore"; // Firestore functions for deleting and updating
import { db } from "../../Utility/firebase";
import { DeleteChat } from "../../Components/Popups";

const Message = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data, dispatch } = useContext(ChatContext);
  const ref = useRef();
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // New state for editing
  const [editedMessage, setEditedMessage] = useState(message?.text || ""); // State for the edited message

  // Scroll to the new message when it's rendered
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message, data, currentUser]);

  // Convert the message timestamp to a readable time
  const convertTimestampToTime = (timestamp) => {
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  // If message.date exists, format the time
  const messageTime = message?.date ? convertTimestampToTime(message.date) : "";

  // Handle message deletion for everyone
  const handleDeleteForEveryone = async () => {
    try {
      const chatRef = doc(db, "chats", data.chatId);
      await updateDoc(chatRef, {
        messages: arrayRemove(message),
      });
    } catch (err) {
      console.error("Error deleting message for everyone: ", err);
    }
  };

  // Handle message deletion for the current user
  const handleDeleteForMe = async () => {
    try {
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        deletedMessages: arrayUnion(message.id),
      });
    } catch (err) {
      console.error("Error updating deleted messages for user: ", err);
    }
  };

  // Update the edited message in Firestore
  const handleEditMessage = async () => {
    try {
      const chatRef = doc(db, "chats", data?.chatId);

      // Fetch the current chat document
      const chatDoc = await getDoc(chatRef);

      if (chatDoc.exists()) {
        const messages = chatDoc.data().messages || [];

        // Update only the specific message
        const updatedMessages = messages.map((msg) =>
          msg.id === message.id ? { ...msg, text: editedMessage } : msg
        );

        // Update Firestore with the new array
        await updateDoc(chatRef, {
          messages: updatedMessages,
        });

        setIsEditing(false); // Close the edit mode after updating
      } else {
        console.error("Chat document does not exist");
      }
    } catch (err) {
      console.error("Error editing message: ", err);
    }
  };

  // Check if the message is deleted for the current user
  const [userDeletedMessages, setUserDeletedMessages] = useState([]);

  useEffect(() => {
    const fetchDeletedMessages = async () => {
      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserDeletedMessages(userDoc.data().deletedMessages || []);
        }
      } catch (err) {
        console.error("Error fetching deleted messages: ", err);
      }
    };

    fetchDeletedMessages();
  }, [currentUser?.uid]);

  useEffect(() => {
    // Real-time listener for deleted messages
    const userRef = doc(db, "users", currentUser?.uid);
    const unsubscribe = onSnapshot(userRef, (docs) => {
      if (docs?.exists()) {
        setUserDeletedMessages(docs.data().deletedMessages || []);
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [currentUser?.uid]);

  if (userDeletedMessages?.includes(message?.id)) {
    return null; // Don't render the message if it has been deleted for me
  }

  return (
    <div
      ref={ref}
      className={`message ${message?.senderId === currentUser?.uid && "owner"}`}
    >
      <div className="messageInfo">
        <img
          src={
            message.senderId === currentUser.uid
              ? currentUser.photoURL
              : data?.user?.photoURL || data?.user?.photoURL
          }
          alt=""
          className="msgByUser"
        />
      </div>
      <div className="messageContent">
        {/* Conditional rendering: show input for editing or the message text */}
        {isEditing ? (
          <>
          <div className="input">
            <input
              type="text"
              value={editedMessage}
              onChange={(e) => setEditedMessage(e.target.value)}
              // onBlur={handleEditMessage} // Save message when input loses focus
              autoFocus
            />
            <i
              onClick={handleEditMessage}
              class="fa fa-paper-plane"
              aria-hidden="true"
            ></i>
          </div>
          </>
        ) : (
          <p style={{ color: "black" }}>{message?.text}</p>
        )}
        {message?.img && <img src={message?.img} alt="" />}
        <ul className="signleMsgOpt">
          <li>{messageTime}</li>
          <li>
            <span
              onClick={() => setShowDeleteOptions(!showDeleteOptions)}
              style={{ cursor: "pointer" }}
            >
              Delete
            </span>
          </li>
          {message?.senderId === currentUser?.uid ? (
            <>
              <li
                onClick={() => setIsEditing(true)} // Open edit mode
                style={{ cursor: "pointer" }}
              >
                Edit
              </li>
              <li>{}
                <i title={message?.status === "read" ? "Seen" : "Delivered"}
                  className={`fas fa-check-double ${
                    message?.status === "read" ? "readMsg" : ""
                  }`}
                ></i>
              </li>
            </>
          ) : null}
        </ul>
      </div>
      {showDeleteOptions ? (
        <DeleteChat
          firstTitle={
            message?.senderId === currentUser?.uid ? "Delete for Everyone" : ""
          }
          secondTitle="Delete for me"
          firstClick={() => {
            handleDeleteForEveryone();
            setShowDeleteOptions(false);
          }}
          secondClick={() => {
            handleDeleteForMe();
            setShowDeleteOptions(false);
          }}
          show={showDeleteOptions}
          setShow={setShowDeleteOptions}
        />
      ) : undefined}
    </div>
  );
};

export default Message;
