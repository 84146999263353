import { useContext, useEffect } from "react";
import {
  getDatabase,
  ref,
  onDisconnect,
  set,
  onValue,
  serverTimestamp,
} from "firebase/database";
import { AuthContext } from "../../Context/AuthContext";

const useUserStatus = () => {
  const database = getDatabase();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser) return;

    const userStatusDatabaseRef = ref(database, `/status/${currentUser.uid}`);

    const isOfflineForDatabase = {
      state: "offline",
      last_changed: serverTimestamp(),
    };

    const isOnlineForDatabase = {
      state: "online",
      last_changed: serverTimestamp(),
    };

    const isAwayForDatabase = {
      state: "away",
      last_changed: serverTimestamp(),
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        set(userStatusDatabaseRef, isAwayForDatabase);
      } else {
        set(userStatusDatabaseRef, isOnlineForDatabase);
      }
    };

    onValue(ref(database, ".info/connected"), (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }

      onDisconnect(userStatusDatabaseRef)
        .set(isOfflineForDatabase)
        .then(() => {
          set(userStatusDatabaseRef, isOnlineForDatabase);
        });
    });

    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentUser, database]);
};

export default useUserStatus;
