import React from "react";
import { InputField } from "../../Components";

const ClientBasics = (props) => {
  const { values, setValues, error, id, type } = props;
  return (
    <div class="form-group form-group-50">
      <InputField
        required
        title="First name"
        type="text"
        placeholder="First name"
        value={values?.firstName}
        onChangeText={(e) => {
          setValues({
            ...values,
            firstName: e?.target?.value,
          });
        }}
        isValid={error && !values?.firstName}
      />
      <InputField
        required
        title="Last name"
        type="text"
        placeholder="Last name"
        value={values?.lastName}
        onChangeText={(e) => {
          setValues({
            ...values,
            lastName: e?.target?.value,
          });
        }}
        isValid={error && !values?.lastName}
      />
      <InputField
        required
        title="Display name"
        type="text"
        placeholder="Display name"
        value={values?.displayName}
        onChangeText={(e) => {
          setValues({
            ...values,
            displayName: e?.target?.value,
          });
        }}
        isValid={error && !values?.displayName}
        toolTipText={"This will be your display name on the site"}
      />
      <InputField
        required
        title={"Phone number"}
        type="number"
        placeholder={"Phone number"}
        value={values?.phoneNo}
        onChangeText={(e) => {
          setValues({
            ...values,
            phoneNo: e?.target?.value,
          });
        }}
        isValid={error && !values?.phoneNo}
      />
      <InputField
        required
        title="Email"
        type="Email"
        placeholder="Email"
        value={values?.email}
        onChangeText={(e) => {
          setValues({
            ...values,
            email: e?.target?.value,
          });
        }}
        isValid={error && !values?.email}
      />
      {id ? undefined : (
        <InputField
          required
          title="Password"
          type="password"
          placeholder="Password"
          value={values?.password}
          onChangeText={(e) => {
            setValues({
              ...values,
              password: e?.target?.value,
            });
          }}
          isValid={error && !values?.password}
        />
      )}
       <InputField
        title="Tagline"
        type="text"
        placeholder="Tagline"
        value={values?.tagline}
        onChangeText={(e) => {
          setValues({
            ...values,
            tagline: e?.target?.value,
          });
        }}
      />
    </div>
  );
};

export default ClientBasics;
