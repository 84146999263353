import React from "react";
import { SitePagination } from "./filtersComponents";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { isValidInput } from "../Utility/validations";
import SearchBar from "./SearchBar";
import { Container, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { routeName, showToast } from "../Utility";
import { useNavigate } from "react-router-dom";
import { Images } from "../Constants";
import moment from "moment";

const ReportedPosts = (props) => {
  const {
    totalPages,
    page,
    setPage,
    onPageChange,
    noDataFound,
    tab,
    Heading,
    options,
    list,
    searchTerm,
    setSearchTerm,
    setDebouncedSearchTerm,
    setTab,
    handleGetFunctions,
    setSelectedId,
    setIsDeleteModal,
    setList,
    setIsReportModals,
    setReportedData,
    setHideShowModal,
  } = props;

  const navigate = useNavigate();

  return (
    <div>
      <Container>
        <div className="list_cnnt">
          <h3>{`${Heading}`}</h3>
        </div>
      </Container>
      <div className="dashboard_main">
        <div className="myJobs adminData">
          <Tabs
            defaultActiveKey="posted"
            id="list"
            activeKey={tab}
            onSelect={(type) => {
              setTab(type);
              setList([]);
              handleGetFunctions(type, page);
            }}
          >
            {options?.map((item, index) => (
              <Tab key={index} eventKey={item.type} title={item.name}>
                <div class="wt-managejobcontent">
                  <div className="cards">
                    <div className="cards_header">
                      <div />

                      <SearchBar
                        searchTerm={searchTerm}
                        setDebouncedSearchTerm={setDebouncedSearchTerm}
                        value={searchTerm}
                        onCross={() => setSearchTerm("")}
                        onChange={(val) => {
                          if (isValidInput(val.target.value)) {
                            setSearchTerm(val.target.value);
                          }
                        }}
                      />
                    </div>
                    <TableContainer className="table_container">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Author</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Content</TableCell>
                            <TableCell>Reported By</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {list?.length ? (
                          list?.map((item, i) => {
                            return (
                              <TableBody key={i}>
                                <TableRow>
                                  <TableCell>
                                    <div
                                      onClick={() => {
                                        if (Number(tab) === 4) {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: item?.profile?.user_id,
                                            });
                                          navigate(
                                            `${
                                              routeName.CLIENT_PROFILE
                                            }?${queryParams.toString()}`
                                          );
                                        } else {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: item?.user_data?.user_id,
                                            });
                                          navigate(
                                            `${
                                              routeName.TALENT_PROFILE
                                            }?${queryParams.toString()}`
                                          );
                                        }
                                      }}
                                      className="userImgName"
                                    >
                                      <img
                                        alt="profile"
                                        src={
                                          item?.user_data?.attachment?.url ||
                                          item?.profile_image ||
                                          Images.user
                                        }
                                      />
                                      {item?.user_data?.user_name ||
                                        item?.profile?.username ||
                                        ""}
                                    </div>
                                  </TableCell>

                                  <TableCell>
                                    {`${
                                      item?.post_details?.post_title ||
                                      item?.profile?.post_title ||
                                      "-"
                                    }`}
                                  </TableCell>
                                  <TableCell>
                                    {item?.post_details?.post_content ||
                                      item?.profile?.post_content ||
                                      ""}
                                  </TableCell>

                                  <TableCell
                                    onClick={() => {
                                      setReportedData(item?.reported_users);
                                      setIsReportModals(true);
                                    }}
                                  >
                                    <span>{item?.reported_users?.length}</span>
                                    <i
                                      class="fa fa-user"
                                      aria-hidden="true"
                                    ></i>
                                  </TableCell>

                                  <TableCell align="center">
                                    <div className="table_actions">
                                      <IconButton>
                                        <div
                                          onClick={() => {
                                            if (Number(tab) === 4) {
                                              const queryParams =
                                                new URLSearchParams({
                                                  id: item?.profile?.ID,
                                                });
                                              navigate(
                                                `${
                                                  routeName.JOB_DETAILS
                                                }?${queryParams.toString()}`
                                              );
                                            } else {
                                              const queryParams =
                                                new URLSearchParams({
                                                  id: item?.post_details?.ID,
                                                  type:
                                                    Number(tab) === 2
                                                      ? "portfolio"
                                                      : "post",
                                                });
                                              navigate(
                                                `${
                                                  routeName.SOCIAL_DETAIL
                                                }?${queryParams.toString()}`
                                              );
                                            }
                                          }}
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip-profile`}>
                                                View
                                              </Tooltip>
                                            }
                                          >
                                            <i
                                              class="fa fa-eye"
                                              aria-hidden="true"
                                            ></i>
                                          </OverlayTrigger>

                                          <p>View</p>
                                        </div>
                                      </IconButton>
                                      {Number(tab) === 4 ? (
                                        <IconButton>
                                          <div
                                            onClick={() => {
                                              if (
                                                item?.post_meta_details
                                                  ?.hired_status === "hired"
                                              ) {
                                                showToast(
                                                  "You can't hide this job as the job is already hired",
                                                  "error"
                                                );
                                              } else {
                                                const queryParams =
                                                  new URLSearchParams({
                                                    id: item?.profile?.ID,
                                                  });
                                                navigate(
                                                  `${
                                                    item?.post_meta_details
                                                      ?.model_type_req ===
                                                    "casting calls"
                                                      ? routeName.CASTING_JOB
                                                      : routeName.JOB_POST
                                                  }?${queryParams.toString()}`
                                                );
                                              }
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id={`tooltip-profile`}>
                                                  Edit
                                                </Tooltip>
                                              }
                                            >
                                              <i
                                                class="fa fa-pencil-square-o"
                                                aria-hidden="true"
                                              ></i>
                                            </OverlayTrigger>

                                            <p>Edit</p>
                                          </div>
                                        </IconButton>
                                      ) : undefined}
                                      {Number(tab) === 4 ? undefined : (
                                        <IconButton>
                                          <div
                                            onClick={() => {
                                              setSelectedId(item);
                                              setIsDeleteModal(true);
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id={`tooltip-profile`}>
                                                  Delete
                                                </Tooltip>
                                              }
                                            >
                                              <i
                                                class="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </OverlayTrigger>

                                            <p>Delete</p>
                                          </div>
                                        </IconButton>
                                      )}
                                      <IconButton>
                                        <div
                                          onClick={() => {
                                            if (
                                              item?.post_meta_details
                                                ?.hired_status === "hired"
                                            ) {
                                              showToast(
                                                "You can't hide this job as the job is already hired",
                                                "error"
                                              );
                                            } else {
                                              setSelectedId(item);
                                              setHideShowModal(true);
                                            }
                                          }}
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip-profile`}>
                                                {item?.profile?.hide_status ===
                                                  "show" ||
                                                item?.post_details
                                                  ?.hide_status === "show"
                                                  ? "Hide"
                                                  : "Show"}
                                              </Tooltip>
                                            }
                                          >
                                            <i
                                              class={
                                                item?.profile?.hide_status ===
                                                  "show" ||
                                                item?.post_details
                                                  ?.hide_status === "show"
                                                  ? "fa fa-eye-slash"
                                                  : "fa fa-eye"
                                              }
                                              aria-hidden="true"
                                            ></i>
                                          </OverlayTrigger>

                                          <p>
                                            {item?.profile?.hide_status ===
                                              "show" ||
                                            item?.post_details?.hide_status ===
                                              "show"
                                              ? "Hide"
                                              : "Show"}
                                          </p>
                                        </div>
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          })
                        ) : (
                          <TableBody>
                            <TableCell
                              align="center"
                              colSpan={10}
                              sx={{ color: "black" }}
                            >
                              {noDataFound}
                            </TableCell>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    <div className="pg_vew">
                      {totalPages > 1 ? (
                        <SitePagination
                          module={list}
                          page={page}
                          onPageChange={onPageChange}
                          totalPages={totalPages}
                          setPage={setPage}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ReportedPosts;
