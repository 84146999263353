import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  blockUser,
  getFollowDetails,
  userFollowing,
} from "../../Redux/Services/OtherServices";
import { Loader } from "../../Components";
import { routeName } from "../../Utility";
import { useNavigate } from "react-router-dom";

const FollowDetails = () => {
  const [tab, setTab] = useState("followers");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [followList, setFollowList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  useEffect(() => {
    getFollowingList("followers");
  }, []);

  const getFollowingList = async (type) => {
    // console.log("list type------", type);

    var body = {
      action: type,
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      post_id: partnerPermissions?.profile_id || loggedInUser?.user_data?.profile_id,
    };
    setLoading(true);
    let res = await dispatch(getFollowDetails(body));
    setLoading(false);
    // console.log("list res------", res);

    if (res?.status == 200) {
      setTab(type);
      setFollowList(res?.results);
    } else {
      setFollowList([]);
    }
  };

  const handleFollow = async (type, data) => {
    var body = {
      action: type,
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      post_id: data?.profile_id,
    };
    setLoading(true);
    let res = await dispatch(userFollowing(body));
    setLoading(false);
    if (res?.status == 200) {
      if (tab == "followers") {
        if (type == "follow" || type == "remove" || type == "unfollow") {
          getFollowingList("followers", 1);
        }
      } else if (tab == "following") {
        if (type == "unfollow") {
          getFollowingList("following", 2);
        }
      } else if (tab == "requests") {
        if (type == "accept" || type == "decline") {
          getFollowingList("requests", 3);
        }
      } else if (tab == "blocked") {
        if (type == "accept" || type == "decline") {
          getFollowingList("blocked", 4);
        }
      }
    }
  };
  // console.log("tab res------", tab);

  const options = [
    {
      name: "Followers",
      type: "followers",
    },
    {
      name: "Following",
      type: "following",
    },
    {
      name: "Requests",
      type: "requests",
    },
    {
      name: "Blocked",
      type: "blocked",
    },
  ];
  
  const handleBlockUser = async (item) => {
    if (loggedInUser?.user_data?.user_id) {
      setLoading(true);
      let body = {
        user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
        post_id: item?.id,
        action: "unblock",
      };
      let res = await dispatch(blockUser(body));
      setLoading(false);
      if (res?.status == 200) {
        getFollowingList("blocked", 4);
      }
    }
  };
  return (
    <>
      <SidebarLayout>
        <Loader loading={loading} />
        <div class="searchtalentList dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="followers"
              className="mb-3"
              id="follow"
              activeKey={tab}
              onSelect={getFollowingList}
            >
              {options?.map((item, index) => (
                <Tab key={index} eventKey={item.type} title={item.name}>
                  <section>
                    {followList?.length ? (
                      followList?.map((ele, ind) => {
                        return (
                          <>
                            <div>
                              <div class="paidUsers">
                                <i class="fas fa-crown"></i>
                              </div>
                              <div
                                class="rpc-post-image"
                                onClick={() => {
                                  const queryParams = new URLSearchParams({
                                    id: ele?.id,
                                  });
                                  navigate(
                                    `${
                                      routeName.TALENT_PROFILE
                                    }?${queryParams.toString()}`
                                  );
                                }}
                              >
                                <a href="#">
                                  <div class="fixed-height-image">
                                    <img src={ele?.profile_img} />
                                  </div>
                                </a>
                              </div>
                              <a
                                href="#"
                                class="craContent"
                                id=""
                                tabindex="-1"
                              >
                                <div class="bottomArea ">
                                  <h3 class="rpc-post-title">
                                    <div class="rpc-title" target="_self">
                                      <span
                                        class="wt-tipso tipso_style"
                                        data-tipso="ID-Verified"
                                        data-toggle="tooltip"
                                      >
                                        <i
                                          class="fa fa-check"
                                          color="#ef4d74"
                                        ></i>
                                      </span>
                                      {/* {ele?.display_name} */}
                                      {ele?.display_name
                                        ? ele?.display_name?.split(" ")?.[0] 
                                        // +
                                        //   " " +
                                        //   ele?.display_name
                                        //     ?.split(" ")?.[1]?.substring(0, 1)
                                        : ""}
                                    </div>
                                  </h3>
                                  {tab === "blocked" ? (
                                    <div className="actBrns">
                                      <button
                                        onClick={() => handleBlockUser(ele)}
                                        className="btn"
                                      >
                                        Unblock
                                      </button>
                                    </div>
                                  ) : tab === "followers" ? (
                                    <div className="actBrns">
                                      <button
                                        onClick={() =>
                                          handleFollow(
                                            ele?.status ? "unfollow" : "follow",
                                            ele
                                          )
                                        }
                                        className="btn"
                                      >
                                        {ele?.status
                                          ? "Unfollow"
                                          : "Follow Back"}
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleFollow("remove", ele)
                                        }
                                        className="btn"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  ) : tab === "following" ? (
                                    <div className="actBrns">
                                      <button
                                        onClick={() =>
                                          handleFollow("unfollow", ele)
                                        }
                                        className="btn"
                                      >
                                        UnFollow
                                      </button>
                                    </div>
                                  ) : tab === "requests" ? (
                                    <div className="actBrns">
                                      <button
                                        onClick={() =>
                                          handleFollow("accept", ele)
                                        }
                                        className="btn"
                                      >
                                        Accept
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleFollow("decline", ele)
                                        }
                                        className="btn"
                                      >
                                        Decline
                                      </button>
                                    </div>
                                  ) : (
                                    <div>No Data Found</div>
                                  )}
                                </div>
                              </a>
                            </div>
                          </>
                        );
                      })
                    ) : loading ? undefined : (
                      <div>No Data Found</div>
                    )}
                  </section>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </SidebarLayout>
    </>
  );
};

export default FollowDetails;
