export const routeName = {
  HOME: "/",
  ABOUT: "/about",
  SEARCH_JOBS: "/searchJobs",
  LOGIN: "/login",
  REGISTER: "/register",
  PROFILE: "/profile",
  SIGN_UP: "/signup",
  CONTACT_US: "/contactUs",
  SEARCH_TALENT: "/searchtalent",
  INSIGHTS: "/insights",
  DASHBOARD: "/dashboard",
  PRIVACY_POLICY: "/privacypolicy",
  TERMS_CONTIONS: "/termsconditions",
  TALENT_PROFILE: "/talentprofile",
  EDIT_PROFILE: "/editProfile",
  CLIENT_PROFILE: "/clientProfile",
  FAQ: "/faq",
  JOB_DETAILS: "/jobdetails",
  NOTIFICATION: "/notifications",
  JOB_PROPOSAL: "/jobproposal",
  IDENTITY_VERIFY: "/identityVerify",
  RE_DIRECT: "/redirect",
  PAYOUT: "/payout",
  STRIPE: "/stripe-responce",
  JOB_POST: "/jobPost",
  CASTING_JOB: "/postCastingJob",
  MODEL_JOBS: "/manage-jobs",
  CHAT: "/chat",
  CLIENT_PROJECTS: "/manage-projects",
  PROPOSALS: "/job-proposals",
  PACKAGES: "/packages",
  ADD_PORTFOLIO: "/add-portfolio",
  ADD_POST: "/add-post",
  MANAGE_PORTFOLIO: "/manage-portfolio",
  MANAGE_POST: "/manage-post",
  SOCIAL_DETAIL: "/social-detail",
  INSIGHT_DETAIL: "/insight-detail",
  FOLLOW_DETAILS: "/follow-details",
  SAVED_COLLECTION: "/saved-collection",
  ACCOUNT_SETTING: "/account-setting",
  CHECKOUT: "/checkout",
  PAYMENT_STATUS: "/payment-status",
  MANAGE_AVAILABILITY: "/manage-availability",
  EXTRA_PAY_LIST: "/pay-list",
  LIST: "/manage-list",
  EXCEL_UPLOAD: "/excel-upload",
  FAQ_LIST: "/faq-list",
  MANAGE_BLOGS: "/manage-blogs",
  ADD_BLOGS: "/add-blogs",
  MANGE_PACKAGE: "/manage-packages",
  ADD_PACKAGE: "/add-package",
  MODEL_LIST: "/models-list",
  ACTOR_LIST: "/actor-list",
  CLIENT_LIST: "/client-list",
  PHOTO_LIST: "/photo-list",
  PARTNER_LIST: "/partner-list",
  ADD_USER:'/add-user',
  ADD_CLIENT:'/add-client',
  SUBSCRIBERS_LIST:'/subscribers',
  REPORTED_DATA: '/reported-content',
  EDIT_PROFILE_ADMIN: '/update-profile',
  DELETED_ACCOUNTS: '/deleted-accounts',
  PARTNER_DETAIL_VIEW:'/partner-dashboard',
  REPORTED_PG_LINK:'/reported-redirect',
  ALL_ADMIN_JOBS:"/all_jobs",
  ADMIN_PORTFOLIO:'/admin_portfolio',
  ADMIN_POSTS:'/admin_posts',
  ALL_TRANSACTIONS: '/all_transactions'
};
