import { CToaster, CToast, CToastHeader, CToastBody } from "@coreui/react";
import React from "react";
import { Colors, Images } from "../Constants";
import { toast, ToastContainer } from "react-toastify";

export const showToast = (message, type) => {
  switch (type) {
    case "success":
      return (
        toast.success(
          <div style={{ display: "inline", flexDirection: "row" }}>
            <div
              style={{
                color: Colors?.black,
                fontSize: 17,
                textTransform: "capitalize",
              }}
            >
              {message}
            </div>
          </div>
        ),
        // { autoClose: 1000 },
        { limit: 1 }
        // custom-toast-style
      );

    case "error":
      return toast.error(
        <div style={{ display: "inline", flexDirection: "row" }}>
          <div
            style={{
              color: Colors?.black,
              fontSize: 17,
              textTransform: "capitalize",
            }}
          >
            {message}
          </div>
        </div>
      );
    case "warning":
      return toast.warning(
        <div style={{ display: "inline", flexDirection: "row" }}>
          <div
            style={{
              color: Colors?.black,
              fontSize: 17,
              textTransform: "capitalize",
            }}
          >
            {message}
          </div>
        </div>
      );
    case "info":
      return toast.info(
        <div style={{ display: "inline", flexDirection: "row" }}>
          <div
            style={{
              color: Colors?.black,
              fontSize: 17,
              textTransform: "capitalize",
            }}
          >
            {message}
          </div>
        </div>
      );
    default:
      return toast.warning(
        <div style={{ display: "inline", flexDirection: "row" }}>
          <div
            style={{
              color: Colors?.black,
              fontSize: 17,
              textTransform: "capitalize",
            }}
          >
            {message}
          </div>
        </div>
      );
  }
};
export default showToast;
