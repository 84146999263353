
import React, { useState } from "react";
import { Form, Button, ProgressBar } from "react-bootstrap";
import {
  Header,
  Footer,
  InputField,
  DropdownList,
  MultiSelectDropdown,
  MultipleImageCropper,
} from "../../Components";
import { useDispatch } from "react-redux";
import { submitContactForm } from "../../Redux/Services/OtherServices";
import { isValidEmail, showToast } from "../../Utility";
export const ContactUs = () => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);

  const emailValid = isValidEmail(inputs?.email);

  // const handleForm = async(event) => {
  //   setError(true);
  //   if(!inputs?.name && !inputs?.email && !inputs.subject && !inputs.message ){
  //     return
  //   }
  //   let body = {
  //     name:inputs?.name || '',
  //   email:inputs?.email,
  //   subject:inputs?.subject,
  //   message:inputs?.message
  //   }
  //   let res = await dispatch(submitContactForm(body));
  //   if (res?.status == 200) {
  //     setError(false);
  //     showToast('Form Submitted Successfully')

  //     window.scrollTo(0, 0);
  //   }
  // }

  const handleForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      if (
        !inputs?.name ||
        !inputs?.email ||
        !inputs?.message ||
        !inputs?.subject
      ) {
        setValidated(true);
        return;
      }
      handleSubmitForm();
    }
  };

  const handleSubmitForm = async () => {
    var body = {
      email: inputs?.email,
      name: inputs?.name,
      subject: inputs?.subject,
      message: inputs?.message,
    };
    let res = await dispatch(submitContactForm(body));
    if (res?.status == 200) {
      showToast("Form Submitted Successfully", "success");
      setValidated(false);
      setInputs({
        ...inputs,
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  };

  return (
    <div>
      <Header />
      <div className="pageTitle">
        <div className="wt-title">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div className="container">
        <div class="wt-userprofileholder contactForm">
          <div className="row">
            <div className="col-md-6">
              <Form noValidate validated={validated} onSubmit={handleForm}>
                <InputField
                  required
                  title="Your Name"
                  type="text"
                  value={inputs?.name}
                  onChangeText={(e) => {
                    setInputs({
                      ...inputs,
                      name: e?.target?.value,
                    });
                  }}
                />
                <InputField
                  required
                  title="Your Email"
                  type="text"
                  value={inputs?.email}
                  onChangeText={(e) => {
                    setInputs({
                      ...inputs,
                      email: e?.target?.value,
                    });
                  }}
                  isValid={emailValid}
                />
                <InputField
                  required
                  title="Subject"
                  type="text"
                  value={inputs?.subject}
                  onChangeText={(e) => {
                    setInputs({
                      ...inputs,
                      subject: e?.target?.value,
                    });
                  }}
                />
                <InputField
                  required
                  title="Your message"
                  type="textarea"
                  value={inputs?.message}
                  onChangeText={(e) => {
                    setInputs({
                      ...inputs,
                      message: e?.target?.value,
                    });
                  }}
                />
                <Button
                  variant=" wt-btn"
                  class="wt-btn hide_org"
                  type="button"
                  onClick={handleForm}
                >
                  Submit
                </Button>
              </Form>
            </div>
            <div className="col-md-6">
              <div class="contactRight">
                <h4>Have a Question?</h4>
                <p>
                  Check out our&nbsp;
                  <a href="https://booksculp.com/insight/">support</a>&nbsp;page,
                  where you will find answers to common questions about
                  using&nbsp;<a href="https://booksculp.com/">Book Sculp</a>
                </p>
                <h4>Clients, Need help booking a model?</h4>
                <p>
                  Too busy with other things or just need help? Contact&nbsp;
                  <a href="mailto:info@booksculp.com">info@booksculp.com</a>
                  &nbsp;and we’ll take care of the rest. Additional fees may
                  apply
                </p>
                <h4>I’m new, now what?</h4>
                <p>
                  Check out&nbsp;
                  <a href="http://www.sculpstyle.com/">www.sculpstyle.com</a>
                  &nbsp;where you will find answers to common questions about
                  what to do next
                </p>
                <h4>Press Center</h4>
                <p>
                  Check out our facebook and instagram page to see what others
                  are saying about Sculp.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
