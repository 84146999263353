import React from "react";
import Spinner from "react-bootstrap/Spinner";

export const Loader = (props) => {
  const { loading } = props;
  if (loading) {
  return (
    <div className="pageLoader">
      {/* <Spinner animation="grow" className="loader" /> */}
      <div className="loader"></div>
    </div>
  );
  }
};
