export const CountryNames = [
  { label: "AD", value: "Andorra" },
  { label: "AE", value: "United Arab Emirates" },
  { label: "AF", value: "Afghanistan" },
  { label: "AG", value: "Antigua and Barbuda" },
  { label: "AI", value: "Anguilla" },
  { label: "AL", value: "Albania" },
  { label: "AM", value: "Armenia" },
  { label: "AO", value: "Angola" },
  { label: "AQ", value: "Antarctica" },
  { label: "AR", value: "Argentina" },
  { label: "AS", value: "American Samoa" },
  { label: "AT", value: "Austria" },
  { label: "AU", value: "Australia" },
  { label: "AW", value: "Aruba" },
  { label: "AX", value: "Åland Islands" },
  { label: "AZ", value: "Azerbaijan" },
  { label: "BA", value: "Bosnia and Herzegovina" },
  { label: "BB", value: "Barbados" },
  { label: "BD", value: "Bangladesh" },
  { label: "BE", value: "Belgium" },
  { label: "BF", value: "Burkina Faso" },
  { label: "BG", value: "Bulgaria" },
  { label: "BH", value: "Bahrain" },
  { label: "BI", value: "Burundi" },
  { label: "BJ", value: "Benin" },
  { label: "BL", value: "Saint Barthélemy" },
  { label: "BM", value: "Bermuda" },
  { label: "BN", value: "Brunei Darussalam" },
  { label: "BO", value: "Bolivia, Plurinational State of" },
  { label: "BQ", value: "Bonaire, Sint Eustatius and Saba" },
  { label: "BR", value: "Brazil" },
  { label: "BS", value: "Bahamas" },
  { label: "BT", value: "Bhutan" },
  { label: "BV", value: "Bouvet Island" },
  { label: "BW", value: "Botswana" },
  { label: "BY", value: "Belarus" },
  { label: "BZ", value: "Belize" },
  { label: "CA", value: "Canada" },
  { label: "CC", value: "Cocos (Keeling) Islands" },
  { label: "CD", value: "Congo, Democratic Republic of the" },
  { label: "CF", value: "Central African Republic" },
  { label: "CG", value: "Congo" },
  { label: "CH", value: "Switzerland" },
  { label: "CI", value: "Côte d'Ivoire" },
  { label: "CK", value: "Cook Islands" },
  { label: "CL", value: "Chile" },
  { label: "CM", value: "Cameroon" },
  { label: "CN", value: "China" },
  { label: "CO", value: "Colombia" },
  { label: "CR", value: "Costa Rica" },
  { label: "CU", value: "Cuba" },
  { label: "CV", value: "Cabo Verde" },
  { label: "CW", value: "Curaçao" },
  { label: "CX", value: "Christmas Island" },
  { label: "CY", value: "Cyprus" },
  { label: "CZ", value: "Czechia" },
  { label: "DE", value: "Germany" },
  { label: "DJ", value: "Djibouti" },
  { label: "DK", value: "Denmark" },
  { label: "DM", value: "Dominica" },
  { label: "DO", value: "Dominican Republic" },
  { label: "DZ", value: "Algeria" },
  { label: "EC", value: "Ecuador" },
  { label: "EE", value: "Estonia" },
  { label: "EG", value: "Egypt" },
  { label: "EH", value: "Western Sahara" },
  { label: "ER", value: "Eritrea" },
  { label: "ES", value: "Spain" },
  { label: "ET", value: "Ethiopia" },
  { label: "FI", value: "Finland" },
  { label: "FJ", value: "Fiji" },
  { label: "FK", value: "Falkland Islands (Malvinas)" },
  { label: "FM", value: "Micronesia, Federated States of" },
  { label: "FO", value: "Faroe Islands" },
  { label: "FR", value: "France" },
  { label: "GA", value: "Gabon" },
  {
    label: "GB",
    value: "United Kingdom of Great Britain and Northern Ireland",
  },
  { label: "GD", value: "Grenada" },
  { label: "GE", value: "Georgia" },
  { label: "GF", value: "French Guiana" },
  { label: "GG", value: "Guernsey" },
  { label: "GH", value: "Ghana" },
  { label: "GI", value: "Gibraltar" },
  { label: "GL", value: "Greenland" },
  { label: "GM", value: "Gambia" },
  { label: "GN", value: "Guinea" },
  { label: "GP", value: "Guadeloupe" },
  { label: "GQ", value: "Equatorial Guinea" },
  { label: "GR", value: "Greece" },
  { label: "GS", value: "South Georgia and the South Sandwich Islands" },
  { label: "GT", value: "Guatemala" },
  { label: "GU", value: "Guam" },
  { label: "GW", value: "Guinea-Bissau" },
  { label: "GY", value: "Guyana" },
  { label: "HK", value: "Hong Kong" },
  { label: "HM", value: "Heard Island and McDonald Islands" },
  { label: "HN", value: "Honduras" },
  { label: "HR", value: "Croatia" },
  { label: "HT", value: "Haiti" },
  { label: "HU", value: "Hungary" },
  { label: "ID", value: "Indonesia" },
  { label: "IE", value: "Ireland" },
  { label: "IL", value: "Israel" },
  { label: "IM", value: "Isle of Man" },
  { label: "IN", value: "India" },
  { label: "IO", value: "British Indian Ocean Territory" },
  { label: "IQ", value: "Iraq" },
  { label: "IR", value: "Iran, Islamic Republic of" },
  { label: "IS", value: "Iceland" },
  { label: "IT", value: "Italy" },
  { label: "JE", value: "Jersey" },
  { label: "JM", value: "Jamaica" },
  { label: "JO", value: "Jordan" },
  { label: "JP", value: "Japan" },
  { label: "KE", value: "Kenya" },
  { label: "KG", value: "Kyrgyzstan" },
  { label: "KH", value: "Cambodia" },
  { label: "KI", value: "Kiribati" },
  { label: "KM", value: "Comoros" },
  { label: "KN", value: "Saint Kitts and Nevis" },
  { label: "KP", value: "Korea, Democratic People's Republic of" },
  { label: "KR", value: "Korea, Republic of" },
  { label: "KW", value: "Kuwait" },
  { label: "KY", value: "Cayman Islands" },
  { label: "KZ", value: "Kazakhstan" },
  { label: "LA", value: "Lao People's Democratic Republic" },
  { label: "LB", value: "Lebanon" },
  { label: "LC", value: "Saint Lucia" },
  { label: "LI", value: "Liechtenstein" },
  { label: "LK", value: "Sri Lanka" },
  { label: "LR", value: "Liberia" },
  { label: "LS", value: "Lesotho" },
  { label: "LT", value: "Lithuania" },
  { label: "LU", value: "Luxembourg" },
  { label: "LV", value: "Latvia" },
  { label: "LY", value: "Libya" },
  { label: "MA", value: "Morocco" },
  { label: "MC", value: "Monaco" },
  { label: "MD", value: "Moldova, Republic of" },
  { label: "ME", value: "Montenegro" },
  { label: "MF", value: "Saint Martin, (French part)" },
  { label: "MG", value: "Madagascar" },
  { label: "MH", value: "Marshall Islands" },
  { label: "MK", value: "North Macedonia" },
  { label: "ML", value: "Mali" },
  { label: "MM", value: "Myanmar" },
  { label: "MN", value: "Mongolia" },
  { label: "MO", value: "Macao" },
  { label: "MP", value: "Northern Mariana Islands" },
  { label: "MQ", value: "Martinique" },
  { label: "MR", value: "Mauritania" },
  { label: "MS", value: "Montserrat" },
  { label: "MT", value: "Malta" },
  { label: "MU", value: "Mauritius" },
  { label: "MV", value: "Maldives" },
  { label: "MW", value: "Malawi" },
  { label: "MX", value: "Mexico" },
  { label: "MY", value: "Malaysia" },
  { label: "MZ", value: "Mozambique" },
  { label: "NA", value: "Namibia" },
  { label: "NC", value: "New Caledonia" },
  { label: "NE", value: "Niger" },
  { label: "NF", value: "Norfolk Island" },
  { label: "NG", value: "Nigeria" },
  { label: "NI", value: "Nicaragua" },
  { label: "NL", value: "Netherlands" },
  { label: "NO", value: "Norway" },
  { label: "NP", value: "Nepal" },
  { label: "NR", value: "Nauru" },
  { label: "NU", value: "Niue" },
  { label: "NZ", value: "New Zealand" },
  { label: "OM", value: "Oman" },
  { label: "PA", value: "Panama" },
  { label: "PE", value: "Peru" },
  { label: "PF", value: "French Polynesia" },
  { label: "PG", value: "Papua New Guinea" },
  { label: "PH", value: "Philippines" },
  { label: "PK", value: "Pakistan" },
  { label: "PL", value: "Poland" },
  { label: "PM", value: "Saint Pierre and Miquelon" },
  { label: "PN", value: "Pitcairn" },
  { label: "PR", value: "Puerto Rico" },
  { label: "PS", value: "Palestine, State of" },
  { label: "PT", value: "Portugal" },
  { label: "PW", value: "Palau" },
  { label: "PY", value: "Paraguay" },
  { label: "QA", value: "Qatar" },
  { label: "RE", value: "Réunion" },
  { label: "RO", value: "Romania" },
  { label: "RS", value: "Serbia" },
  { label: "RU", value: "Russian Federation" },
  { label: "RW", value: "Rwanda" },
  { label: "SA", value: "Saudi Arabia" },
  { label: "SB", value: "Solomon Islands" },
  { label: "SC", value: "Seychelles" },
  { label: "SD", value: "Sudan" },
  { label: "SE", value: "Sweden" },
  { label: "SG", value: "Singapore" },
  { label: "SH", value: "Saint Helena, Ascension and Tristan da Cunha" },
  { label: "SI", value: "Slovenia" },
  { label: "SJ", value: "Svalbard and Jan Mayen" },
  { label: "SK", value: "Slovakia" },
  { label: "SL", value: "Sierra Leone" },
  { label: "SM", value: "San Marino" },
  { label: "SN", value: "Senegal" },
  { label: "SO", value: "Somalia" },
  { label: "SR", value: "Surivalue" },
  { label: "SS", value: "South Sudan" },
  { label: "ST", value: "Sao Tome and Principe" },
  { label: "SV", value: "El Salvador" },
  { label: "SX", value: "Sint Maarten, (Dutch part)" },
  { label: "SY", value: "Syrian Arab Republic" },
  { label: "SZ", value: "Eswatini" },
  { label: "TC", value: "Turks and Caicos Islands" },
  { label: "TD", value: "Chad" },
  { label: "TF", value: "French Southern Territories" },
  { label: "TG", value: "Togo" },
  { label: "TH", value: "Thailand" },
  { label: "TJ", value: "Tajikistan" },
  { label: "TK", value: "Tokelau" },
  { label: "TL", value: "Timor-Leste" },
  { label: "TM", value: "Turkmenistan" },
  { label: "TN", value: "Tunisia" },
  { label: "TO", value: "Tonga" },
  { label: "TR", value: "Turkey" },
  { label: "TT", value: "Trinidad and Tobago" },
  { label: "TV", value: "Tuvalu" },
  { label: "TW", value: "Taiwan, Province of China" },
  { label: "TZ", value: "Tanzania, United Republic of" },
  { label: "UA", value: "Ukraine" },
  { label: "UG", value: "Uganda" },
  { label: "UM", value: "United States Minor Outlying Islands" },
  { label: "US", value: "United States of America" },
  { label: "UY", value: "Uruguay" },
  { label: "UZ", value: "Uzbekistan" },
  { label: "VA", value: "Holy See" },
  { label: "VC", value: "Saint Vincent and the Grenadines" },
  { label: "VE", value: "Venezuela, Bolivarian Republic of" },
  { label: "VG", value: "Virgin Islands, British" },
  { label: "VI", value: "Virgin Islands, U.S." },
  { label: "VN", value: "Viet Nam" },
  { label: "VU", value: "Vanuatu" },
  { label: "WF", value: "Wallis and Futuna" },
  { label: "WS", value: "Samoa" },
  { label: "YE", value: "Yemen" },
  { label: "YT", value: "Mayotte" },
  { label: "ZA", value: "South Africa" },
  { label: "ZM", value: "Zambia" },
  { label: "ZW", value: "Zimbabwe" },
];

export const heightInches = [
  { label: `3"`, value: `3"` },
  { label: `3'1"`, value: `3'1"` },
  { label: `3'2"`, value: `3'2"` },
  { label: `3'3"`, value: `3'3"` },
  { label: `3'4"`, value: `3'4"` },
  { label: `3'5"`, value: `3'5"` },
  { label: `3'6"`, value: `3'6"` },
  { label: `3'7"`, value: `3'7"` },
  { label: `3'8"`, value: `3'8"` },
  { label: `3'9"`, value: `3'9"` },
  { label: `3'10"`, value: `3'10"` },
  { label: `3'11"`, value: `3'11"` },
  { label: `4"`, value: `4"` },
  { label: `4'1"`, value: `4'1"` },
  { label: `4'2"`, value: `4'2"` },
  { label: `4'3"`, value: `4'3"` },
  { label: `4'4"`, value: `4'4"` },
  { label: `4'5"`, value: `4'5"` },
  { label: `4'6"`, value: `4'6"` },
  { label: `4'7"`, value: `4'7"` },
  { label: `4'8"`, value: `4'8"` },
  { label: `4'9"`, value: `4'9"` },
  { label: `4'10"`, value: `4'10"` },
  { label: `4'11"`, value: `4'11"` },
  { label: `5"`, value: `5"` },
  { label: `5'1"`, value: `5'1"` },
  { label: `5'2"`, value: `5'2"` },
  { label: `5'3"`, value: `5'3"` },
  { label: `5'4"`, value: `5'4"` },
  { label: `5'5"`, value: `5'5"` },
  { label: `5'6"`, value: `5'6"` },
  { label: `5'7"`, value: `5'7"` },
  { label: `5'8"`, value: `5'8"` },
  { label: `5'9"`, value: `5'9"` },
  { label: `5'10"`, value: `5'10"` },
  { label: `5'11"`, value: `5'11"` },
  { label: `6"`, value: `6"` },
  { label: `6'1"`, value: `6'1"` },
  { label: `6'2"`, value: `6'2"` },
  { label: `6'3"`, value: `6'3"` },
  { label: `6'4"`, value: `6'4"` },
  { label: `6'5"`, value: `6'5"` },
  { label: `6'6"`, value: `6'6"` },
  { label: `6'7"`, value: `6'7"` },
  { label: `6'8"`, value: `6'8"` },
  { label: `6'9"`, value: `6'9"` },
  { label: `6'10"`, value: `6'10"` },
  { label: `6'11"`, value: `6'11"` },
  { label: `7"`, value: `7"` },
  { label: `7'1"`, value: `7'1"` },
  { label: `7'2"`, value: `7'2"` },
  { label: `7'3"`, value: `7'3"` },
  { label: `7'4"`, value: `7'4"` },
  { label: `7'5"`, value: `7'5"` },
  { label: `7'6"`, value: `7'6"` },
  { label: `7'7"`, value: `7'7"` },
  { label: `7'8"`, value: `7'8"` },
  { label: `7'9"`, value: `7'9"` },
  { label: `7'10"`, value: `7'10"` },
  { label: `7'11"`, value: `7'11"` },
];
export const heightFilterInches = [
  { label: "0-4", value: "0-4" },
  { label: "4-6", value: "4-6" },
  { label: "6-9", value: "6-9" },
];

export const languages = [
  
  { label: "Abkhazian", value: "Abkhazian" },
  { label: "Afar", value: "Afar" },
  { label: "Afrikaans", value: "Afrikaans" },
  { label: "Akan", value: "Akan" },
  { label: "Albanian", value: "Albanian" },
  { label: "Amharic", value: "Amharic" },
  { label: "Arabic", value: "Arabic" },
  { label: "Aragonese", value: "Aragonese" },
  { label: "Armenian", value: "Armenian" },
  { label: "Assamese", value: "Assamese" },
  { label: "Avaric", value: "Avaric" },
  { label: "Avestan", value: "Avestan" },
  { label: "Aymara", value: "Aymara" },
  { label: "Azerbaijani", value: "Azerbaijani" },
  { label: "Bambara", value: "Bambara" },
  { label: "Bashkir", value: "Bashkir" },
  { label: "Basque", value: "Basque" },
  { label: "Belarusian", value: "Belarusian" },
  { label: "Bengali", value: "Bengali" },
  { label: "Bihari languages", value: "Bihari languages" },
  { label: "Bislama", value: "Bislama" },
  { label: "Bosnian", value: "Bosnian" },
  { label: "Breton", value: "Breton" },
  { label: "Bulgarian", value: "Bulgarian" },
  { label: "Burmese", value: "Burmese" },
  { label: "Catalan, Catalunya", value: "Catalan, Catalunya" },
  { label: "Catalan, Valencian", value: "Catalan, Valencian" },
  { label: "Central Khmer", value: "Central Khmer" },
  { label: "Chamorro", value: "Chamorro" },
  { label: "Chechen", value: "Chechen" },
  { label: "Chichewa, Chewa, Nyanja", value: "Chichewa, Chewa, Nyanja" },
  { label: "Chinese", value: "Chinese" },
  {
    label: "Church Slavonic, Old Bulgarian, Old Church Slavonic",
    value: "Church Slavonic, Old Bulgarian, Old Church Slavonic",
  },
  { label: "Chuvash", value: "Chuvash" },
  { label: "Cornish", value: "Cornish" },
  { label: "Corsican", value: "Corsican" },
  { label: "Cree", value: "Cree" },
  { label: "Croatian", value: "Croatian" },
  { label: "Czech", value: "Czech" },
  { label: "Danish", value: "Danish" },
  { label: "Divehi, Dhivehi, Maldivian", value: "Divehi, Dhivehi, Maldivian" },
  { label: "Dutch, Flemish", value: "Dutch, Flemish" },
  { label: "Dzongkha", value: "Dzongkha" },
  { label: "English", value: "English" },
  { label: "Esperanto", value: "Esperanto" },
  { label: "Estonian", value: "Estonian" },
  { label: "Ewe", value: "Ewe" },
  { label: "Faroese", value: "Faroese" },
  { label: "Fijian", value: "Fijian" },
  { label: "Finnish", value: "Finnish" },
  { label: "French", value: "French" },
  { label: "Fulah", value: "Fulah" },
  { label: "Gaelic, Scottish Gaelic", value: "Gaelic, Scottish Gaelic" },
  { label: "Galician", value: "Galician" },
  { label: "Ganda", value: "Ganda" },
  { label: "Georgian", value: "Georgian" },
  { label: "German", value: "German" },
  { label: "Gikuyu, Kikuyu", value: "Gikuyu, Kikuyu" },
  { label: "Greek (Modern)", value: "Greek (Modern)" },
  { label: "Greenlandic, Kalaallisut", value: "Greenlandic, Kalaallisut" },
  { label: "Guarani", value: "Guarani" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Haitian, Haitian Creole", value: "Haitian, Haitian Creole" },
  { label: "Hausa", value: "Hausa" },
  { label: "Hebrew", value: "Hebrew" },
  { label: "Herero", value: "Herero" },
  { label: "Hindi", value: "Hindi" },
  { label: "Hiri Motu", value: "Hiri Motu" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Icelandic", value: "Icelandic" },
  { label: "Ido", value: "Ido" },
  { label: "Igbo", value: "Igbo" },
  { label: "Indonesian", value: "Indonesian" },
  {
    label: "Interlingua (International Auxiliary Language Association)",
    value: "Interlingua (International Auxiliary Language Association)",
  },
  { label: "Interlingue", value: "Interlingue" },
  { label: "Inuktitut", value: "Inuktitut" },
  { label: "Inupiaq", value: "Inupiaq" },
  { label: "Irish", value: "Irish" },
  { label: "Italian", value: "Italian" },
  { label: "Japanese", value: "Japanese" },
  { label: "Javanese", value: "Javanese" },
  { label: "Kannada", value: "Kannada" },
  { label: "Kanuri", value: "Kanuri" },
  { label: "Kashmiri", value: "Kashmiri" },
  { label: "Kazakh", value: "Kazakh" },
  { label: "Kinyarwanda", value: "Kinyarwanda" },
  { label: "Komi", value: "Komi" },
  { label: "Kongo", value: "Kongo" },
  { label: "Korean", value: "Korean" },
  { label: "Kurdish", value: "Kurdish" },
  { label: "Kwanyama, Kuanyama", value: "Kwanyama, Kuanyama" },
  { label: "Kyrgyz", value: "Kyrgyz" },
  { label: "Lao", value: "Lao" },
  { label: "Latin", value: "Latin" },
  { label: "Latvian", value: "Latvian" },
  {
    label: "Letzeburgesch, Luxembourgish",
    value: "Letzeburgesch, Luxembourgish",
  },
  {
    label: "Limburgish, Limburgan, Limburger",
    value: "Limburgish, Limburgan, Limburger",
  },
  { label: "Lingala", value: "Lingala" },
  { label: "Lithuanian", value: "Lithuanian" },
  { label: "Luba-Katanga", value: "Luba-Katanga" },
  { label: "Macedonian", value: "Macedonian" },
  { label: "Malagasy", value: "Malagasy" },
  { label: "Malay", value: "Malay" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Maltese", value: "Maltese" },
  { label: "Manx", value: "Manx" },
  { label: "Maori", value: "Maori" },
  { label: "Marathi", value: "Marathi" },
  { label: "Marshallese", value: "Marshallese" },
  {
    label: "Moldovan, Moldavian, Romanian",
    value: "Moldovan, Moldavian, Romanian",
  },
  { label: "Mongolian", value: "Mongolian" },
  { label: "Nauru", value: "Nauru" },
  { label: "Navajo, Navaho", value: "Navajo, Navaho" },
  { label: "Ndonga", value: "Ndonga" },
  { label: "Nepali", value: "Nepali" },
  { label: "Northern Ndebele", value: "Northern Ndebele" },
  { label: "Northern Sami", value: "Northern Sami" },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Norwegian Bokmål", value: "Norwegian Bokmål" },
  { label: "Norwegian Nynorsk", value: "Norwegian Nynorsk" },
  { label: "Nuosu, Sichuan Yi", value: "Nuosu, Sichuan Yi" },
  { label: "Occitan (post 1500)", value: "Occitan (post 1500)" },
  { label: "Ojibwa", value: "Ojibwa" },
  { label: "Oriya", value: "Oriya" },
  { label: "Oromo", value: "Oromo" },
  { label: "Ossetian, Ossetic", value: "Ossetian, Ossetic" },
  { label: "Pali", value: "Pali" },
  { label: "Panjabi, Punjabi", value: "Panjabi, Punjabi" },
  { label: "Pashto, Pushto", value: "Pashto, Pushto" },
  { label: "Persian", value: "Persian" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Quechua", value: "Quechua" },
  { label: "Romansh", value: "Romansh" },
  { label: "Rundi", value: "Rundi" },
  { label: "Russian", value: "Russian" },
  { label: "Samoan", value: "Samoan" },
  { label: "Sango", value: "Sango" },
  { label: "Sanskrit", value: "Sanskrit" },
  { label: "Sardinian", value: "Sardinian" },
  { label: "Serbian", value: "Serbian" },
  { label: "Shona", value: "Shona" },
  { label: "Sign Language", value: "Sign Language" },
  { label: "Sindhi", value: "Sindhi" },
  { label: "Sinhala, Sinhalese", value: "Sinhala, Sinhalese" },
  { label: "Slovak", value: "Slovak" },
  { label: "Slovenian", value: "Slovenian" },
  { label: "Somali", value: "Somali" },
  { label: "Sotho, Southern", value: "Sotho, Southern" },
  { label: "South Ndebele", value: "South Ndebele" },
  { label: "Spanish, Castilian", value: "Spanish, Castilian" },
  { label: "Sundanese", value: "Sundanese" },
  { label: "Swahili", value: "Swahili" },
  { label: "Swati", value: "Swati" },
  { label: "Swedish", value: "Swedish" },
  { label: "Tagalog", value: "Tagalog" },
  { label: "Tahitian", value: "Tahitian" },
  { label: "Tajik", value: "Tajik" },
  { label: "Tamil", value: "Tamil" },
  { label: "Tatar", value: "Tatar" },
  { label: "Telugu", value: "Telugu" },
  { label: "Thai", value: "Thai" },
  { label: "Tibetan", value: "Tibetan" },
  { label: "Tigrinya", value: "Tigrinya" },
  { label: "Tonga (Tonga Islands)", value: "Tonga (Tonga Islands)" },
  { label: "Tsonga", value: "Tsonga" },
  { label: "Tswana", value: "Tswana" },
  { label: "Turkish", value: "Turkish" },
  { label: "Turkmen", value: "Turkmen" },
  { label: "Twi", value: "Twi" },
  { label: "Uighur, Uyghur", value: "Uighur, Uyghur" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Urdu", value: "Urdu" },
  { label: "Uzbek", value: "Uzbek" },
  { label: "Venda", value: "Venda" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Volap_k", value: "Volap_k" },
  { label: "Walloon", value: "Walloon" },
  { label: "Welsh", value: "Welsh" },
  { label: "Western Frisian", value: "Western Frisian" },
  { label: "Wolof", value: "Wolof" },
  { label: "Xhosa", value: "Xhosa" },
  { label: "Yiddish", value: "Yiddish" },
  { label: "Yoruba", value: "Yoruba" },
  { label: "Zhuang, Chuang", value: "Zhuang, Chuang" },
  { label: "Zulu", value: "Zulu" },
];

export const weightInches = [
  { label: "Under 60 lbs", value: "Under 60 lbs" },
  { label: "60 to 70 lbs", value: "60 to 70 lbs" },
  { label: "70 to 80 lbs", value: "70 to 80 lbs" },
  { label: "80 to 90 lbs", value: "80 to 90 lbs" },
  { label: "90 to 100 lbs", value: "90 to 100 lbs" },
  { label: "100 to 110 lbs", value: "100 to 110 lbs" },
  { label: "110 to 120 lbs", value: "110 to 120 lbs" },
  { label: "120 to 130 lbs", value: "120 to 130 lbs" },
  { label: "130 to 140 lbs", value: "130 to 140 lbs" },
  { label: "140 to 150 lbs", value: "140 to 150 lbs" },
  { label: "150 to 160 lbs", value: "150 to 160 lbs" },
  { label: "160 to 170 lbs", value: "160 to 170 lbs" },
  { label: "170 to 180 lbs", value: "170 to 180 lbs" },
  { label: "Over 181 lbs", value: "Over 181 lbs" },
];
export const hairColors = [
  { label: "Black", value: "Black" },
  { label: "Brunette", value: "Brunette" },
  { label: "Blonde", value: "Blonde" },
  { label: "Blue", value: "Blue" },
  { label: "Gray", value: "Gray" },
  { label: "Purple", value: "Purple" },
  { label: "Red", value: "Red" },
  { label: "Other", value: "Other" },
];

export const experienceLevel = [
  { label: "Beginner", value: "Beginner" },
  { label: "Experienced", value: "Experienced" },
  { label: "Intermediate", value: "Intermediate" },
];
export const projectLevels = [
  { label: "All Talent levels", value: "All Talent levels" },
  { label: "Beginner", value: "Beginner" },
  { label: "Experienced", value: "Experienced" },
  { label: "Intermediate", value: "Intermediate" },
];

const clientProvides = [
  { label: "A car for pickup", value: "A car for pickup" },
  { label: "Flight Arrangements", value: "Flight Arrangements" },
  { label: "Hair stylist", value: "Hair stylist" },
  { label: "Lodging", value: "Lodging" },
  { label: "Makeup Artist", value: "Makeup Artist" },
  { label: "Meals", value: "Meals" },
  { label: "Milage reimbursement", value: "Milage reimbursement" },
  { label: "Wardrobe", value: "Wardrobe" },
];
const workTypes = [
  { label: "Broadcast", value: "Broadcast" },
  { label: "Commercial shoot", value: "Commercial shoot" },
  { label: "E-commerce", value: "E-commerce" },
  { label: "Internet", value: "Internet" },
  { label: "Non-Broadcast", value: "Non-Broadcast" },
  { label: "Personal Project", value: "Personal Project" },
  { label: "Print", value: "Print" },
  { label: "Social Media", value: "Social Media" },
  { label: "TV", value: "TV" },
  { label: "Web only", value: "Web only" },
  { label: "Other", value: "Other" },
];
const talentArrivalRequire = [
  { label: "Clothing", value: "Clothing" },
  { label: "Hair", value: "Hair" },
  { label: "Makeup", value: "Makeup" },
  { label: "Nails", value: "Nails" },
  { label: "Undergarments", value: "Undergarments" },
  { label: "Wardrode", value: "Wardrode" },
];
export const payTypes = [
  { label: "Hourly Rate", value: "Hourly Rate" },
  { label: "Half Day Rate", value: "Half Day Rate" },
  { label: "Full Day Rate", value: "Full Day Rate" },
  { label: "Flat Rate", value: "Flat Rate" },
];
const durations = [
  { label: "1 year", value: "1 year" },
  { label: "2 year", value: "2 year" },
  { label: "3 year", value: "3 year" },
  { label: "4 year", value: "4 year" },
  { label: "5 year", value: "5 year" },
  { label: "Unlimited", value: "Unlimited" },
];
export const jobDurations = [
  { label: "Hourly", value: "Hourly" },
  { label: "Half Day", value: "Half Day" },
  { label: "One Day", value: "One Day" },
  { label: "Two days", value: "Two days" },
  { label: "Three Days", value: "Three Days" },
  { label: "Four Days", value: "Four Days" },
  { label: "Five Days", value: "Five Days" },
  { label: "Six Days", value: "Six Days" },
  { label: "More than a Week", value: "More than a Week" },
];

export const ethnicities = [
  { label: "Asian", value: "Asian" },
  { label: "African American", value: "African American" },
  { label: "Caucasian", value: "Caucasian" },
  { label: "Latino", value: "Latino" },
  { label: "Multiacial", value: "Multiacial" },
  { label: "Pacific Islander", value: "Pacific Islander" },
  { label: "Other", value: "Other" },
];

export const mediaTypes = [
  { label: "Broadcast", value: "Broadcast" },
  { label: "Billboard", value: "Billboard" },
  { label: "Commercial", value: "Commercial" },
  { label: "Non-Broadcast", value: "Non-Broadcast" },
  { label: "Out of Home", value: "Out of Home" },
  { label: "Print", value: "Print" },
  { label: "Radio", value: "Radio" },
  { label: "Social", value: "Social" },
  { label: "Web", value: "Web" },
];

export const followers = [
  { label: "0-9k", value: "0-9k" },
  { label: "10-15k", value: "10-15k" },
  { label: "15-20k", value: "15-20k" },
  { label: "20-30k", value: "20-30k" },
  { label: "30-50k", value: "30-50k" },
  { label: "50-100k", value: "50-100k" },
  { label: "More than 100k", value: "More than 100k" },
];

export const shirtSizes = [
  { label: "XXS", value: "XXS" },
  { label: "XS", value: "XS" },
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "XXL", value: "XXl" },
  { label: "XXXL", value: "XXXl" },
];

export const pantSizes = [
  { label: "00", value: "00" },
  { label: "0", value: "0" },
  { label: "2", value: "2" },
  { label: "4", value: "4" },
  { label: "6", value: "6" },
  { label: "8", value: "8" },
  { label: "10", value: "10" },
  { label: "12", value: "12" },
  { label: "14", value: "14" },
  { label: "16", value: "16" },
  { label: "18", value: "18" },
  { label: "20", value: "20" },
  { label: "22", value: "22" },
  { label: "24", value: "24" },
  { label: "26", value: "26" },
  { label: "28", value: "28" },
  { label: "30", value: "30" },
];
export const braCups = [
  { label: "AA", value: "AA" },
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "DD", value: "DD" },
  { label: "DDD", value: "DDD" },
  { label: "E", value: "E" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
];

export const braSizes = [
  { label: "26", value: "26" },
  { label: "28", value: "28" },
  { label: "30", value: "30" },
  { label: "32", value: "32" },
  { label: "34", value: "34" },
  { label: "36", value: "36" },
  { label: "38", value: "38" },
  { label: "40", value: "40" },
];

export const dressSizes = [
  { label: "00", value: "00" },
  { label: "0", value: "0" },
  { label: "2", value: "2" },
  { label: "4", value: "4" },
  { label: "6", value: "6" },
  { label: "8", value: "8" },
  { label: "10", value: "10" },
  { label: "12", value: "12" },
  { label: "14", value: "14" },
  { label: "16", value: "16" },
  { label: "18", value: "18" },
  { label: "20", value: "20" },
  { label: "22", value: "22" },
];
export const shoeSizes = [
  { label: "4", value: "4" },
  { label: "4.5", value: "4.5" },
  { label: "5", value: "5" },
  { label: "5.5", value: "5.5" },
  { label: "6", value: "6" },
  { label: "6.5", value: "6.5" },
  { label: "7", value: "7" },
  { label: "7.5", value: "7.5" },
  { label: "8", value: "8" },
  { label: "8.5", value: "8.5" },
  { label: "9", value: "9" },
  { label: "9.5", value: "9.5" },
  { label: "10", value: "10" },
  { label: "10.5", value: "10.5" },
  { label: "11", value: "11" },
  { label: "11.5", value: "11.5" },
  { label: "12", value: "12" },
  { label: "12.5", value: "12.5" },
  { label: "13", value: "13" },
  { label: "13.5", value: "13.5" },
  { label: "14", value: "14" },
  { label: "14.5", value: "14.5" },
  { label: "15", value: "15" },
];

export const skills = [
  { label: "Baseball", value: "Baseball" },
  { label: "Basketball", value: "Basketball" },
  { label: "Biking", value: "Biking" },
  { label: "Body builder", value: "Body builder" },
  { label: "Boxing", value: "Boxing" },
  { label: "Bungee Jumping", value: "Bungee Jumping" },
  { label: "Canoeing", value: "Canoeing" },
  { label: "Dancing/choreography", value: "Dancing/choreography" },
  { label: "Extreme Sports", value: "Extreme Sports" },
  { label: "Fishing", value: "Fishing" },
  { label: "Fitness", value: "Fitness" },
  { label: "Fly Fishing", value: "Fly Fishing" },
  { label: "Football", value: "Football" },
  { label: "Foreign Language", value: "Foreign Language" },
  { label: "Golfing", value: "Golfing" },
  { label: "Gymnastics", value: "Gymnastics" },
  { label: "Hiking", value: "Hiking" },
  { label: "Horseback riding", value: "Horseback riding" },
  { label: "Hunting", value: "Hunting" },
  { label: "Ice Skating", value: "Ice Skating" },
  { label: "Juggler", value: "Juggler" },
  { label: "Kayaking", value: "Kayaking" },
  { label: "Martial arts", value: "Martial arts" },
  { label: "Painting", value: "Painting" },
  { label: "Play Musical Instrument", value: "Play Musical Instrument" },
  { label: "Racing", value: "Racing" },
  { label: "River rafting", value: "River rafting" },
  { label: "Rock climbing", value: "Rock climbing" },
  { label: "Roller Skating", value: "Roller Skating" },
  { label: "Rugby", value: "Rugby" },
  { label: "Runner", value: "Runner" },
  { label: "Scuba diving", value: "Scuba diving" },
  { label: "Signing", value: "Signing" },
  { label: "Skateboarding", value: "Skateboarding" },
  { label: "Skydiving", value: "Skydiving" },
  { label: "Snow skiing", value: "Snow skiing" },
  { label: "Snowboarding", value: "Snowboarding" },
  { label: "Soccer", value: "Soccer" },
  { label: "Softball", value: "Softball" },
  { label: "Surfing", value: "Surfing" },
  { label: "Swimming", value: "Swimming" },
  { label: "Tennis", value: "Tennis" },
  { label: "Visual artist", value: "Visual artist" },
  { label: "Volleyball", value: "Volleyball" },
  { label: "Water skiing", value: "Water skiing" },
  { label: "Yoga", value: "Yoga" },
];

export const documentTypes = [
  { label: "National identity card", value: "National identity card" },
  { label: "Passport", value: "Passport" },
  { label: "Passport Card", value: "Passport Card" },
  { label: "Driving License", value: "Driving License" },
  { label: "State issued ID card", value: "State issued ID card" },
  {
    label: "Resident permit ID / U.S. Green Card",
    value: "Resident permit ID / U.S. Green Card",
  },
  { label: "Border crossing card", value: "Border crossing card" },
  { label: "Child ID card", value: "Child ID card" },
  { label: "NYC card", value: "NYC card" },
  { label: "U.S. visa card", value: "U.S. visa card" },
  { label: "Other", value: "Other" },
];

export const locationsUsage = [
  { label: "International", value: "International" },
  { label: "Local", value: "Local" },
  { label: "National", value: "National" },
  { label: "Regional", value: "Regional" },
  { label: "Statewide", value: "Statewide" },
  { label: "Worldwide", value: "Worldwide" },
];
export const talentTypes = [
  { label: "Model", value: "Model" },
  { label: "Kid", value: "Kid" },
];
// { label: "Actor/Actress", value: "Actor/Actress" },
// { label: "Photographer", value: "Photographer" },

export const jobTalentTypes = [
  { label: "Model", value: "Model" },
  { label: "Kid", value: "Kid" },
];
export const englishLevels = [
  { label: "Basic", value: "Basic" },
  { label: "Fluent", value: "Fluent" },
  { label: "Professional", value: "Professional" },
  { label: "Native or Bilingual", value: "Native or Bilingual" },
];
export const genderTypes = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non Binary", value: "Non Binary" },
];

export const modelTypes = [
  { label: "adult", value: "actor" },
  { label: "child", value: "child" }
];


export const kidGenderTypes = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];
export const expertises = [
  { label: "Lifestyle", value: "Lifestyle" },
  { label: "Fashion", value: "Fashion" },
  { label: "Portrait", value: "Portrait" },
  { label: "Architecture", value: "Architecture" },
  { label: "Food", value: "Food" },
  { label: "Landscape", value: "Landscape" },
  { label: "Nature", value: "Nature" },
  { label: "Real estate", value: "Real estate" },
  { label: "Aerial", value: "Aerial" },
  { label: "Sports", value: "Sports" },
  { label: "Documentary", value: "Documentary" },
  { label: "Product", value: "Product" },
  { label: "Fine Art", value: "Fine Art" },
];
export const reasonList = [
  { label: "Sexual Content", value: "Sexual Content" },
  {
    label: "Voilent or repulsive content",
    value: "Voilent or repulsive content",
  },
  { label: "Hateful or abusive content", value: "Hateful or abusive content" },
  { label: "Harmful or dangerouse acts", value: "Harmful or dangerouse acts" },
  { label: "Spam or misleading", value: "Spam or misleading" },
  { label: "Child abuse", value: "Child abuse" },
  { label: "Other", value: "Other" },
];
export const projectLocations = [
  { label: "On Location", value: "On Location" },
  { label: "Studio", value: "Studio" },
  { label: "Remote", value: "Remote" },
  { label: "Combination", value: "Combination" },
];
export const hourlyRate = [
  { label: "0 - 5", value: "0 - 5" },
  { label: "5 - 10", value: "5 - 10" },
  { label: "10 - 20", value: "10 - 20" },
  { label: "20 - 30", value: "20 - 30" },
  { label: "30 - 40", value: "30 - 40" },
  { label: "40 - 50", value: "40 - 50" },
  { label: "50 - 60", value: "50 - 60" },
  { label: "60 - 70", value: "60 - 70" },
  { label: "70 - 80", value: "70 - 80" },
  { label: "80 - 90", value: "80 - 90" },
  { label: "100+", value: "100+" },
];
export const timeZones = [
  {
    label: "(GMT-08:00) Pacific Time (US & Canada); Tijuana",
    value: "(GMT-08:00) Pacific Time (US & Canada); Tijuana",
  },
  {
    label: "(GMT-07:00) Mountain Time (US & Canada)",
    value: "(GMT-07:00) Mountain Time (US & Canada)",
  },
  {
    label: "(GMT-06:00) Central Time (US & Canada)",
    value: "(GMT-06:00) Central Time (US & Canada)",
  },
  {
    label: "(GMT-05:00) Eastern Time (US & Canada)",
    value: "(GMT-05:00) Eastern Time (US & Canada)",
  },
  {
    label: "(GMT-12:00) International Date Line West",
    value: "(GMT-12:00) International Date Line West",
  },
  { label: "(GMT-11:00) Midway Island", value: "(GMT-11:00) Midway Island" },
  { label: "(GMT-11:00) Samoa", value: "(GMT-11:00) Samoa" },
  { label: "(GMT-10:00) Hawaii", value: "(GMT-10:00) Hawaii" },
  { label: "(GMT-09:00) Alaska", value: "(GMT-09:00) Alaska" },

  { label: "(GMT-07:00) Arizona", value: "(GMT-07:00) Arizona" },
  { label: "(GMT-07:00) Chihuahua", value: "(GMT-07:00) Chihuahua" },
  { label: "(GMT-07:00) La Paz", value: "(GMT-07:00) La Paz" },
  { label: "(GMT-07:00) Mazatlan", value: "(GMT-07:00) Mazatlan" },

  {
    label: "(GMT-06:00) Central America",
    value: "(GMT-06:00) Central America",
  },

  { label: "(GMT-06:00) Guadalajara", value: "(GMT-06:00) Guadalajara" },
  { label: "(GMT-06:00) Mexico City", value: "(GMT-06:00) Mexico City" },
  { label: "(GMT-06:00) Monterrey", value: "(GMT-06:00) Monterrey" },
  { label: "(GMT-06:00) Saskatchewan", value: "(GMT-06:00) Saskatchewan" },
  { label: "(GMT-05:00) Bogota", value: "(GMT-05:00) Bogota" },

  { label: "(GMT-05:00) Indiana (East)", value: "(GMT-05:00) Indiana (East)" },
  { label: "(GMT-05:00) Lima", value: "(GMT-05:00) Lima" },
  { label: "(GMT-05:00) Quito", value: "(GMT-05:00) Quito" },
  {
    label: "(GMT-04:00) Atlantic Time (Canada)",
    value: "(GMT-04:00) Atlantic Time (Canada)",
  },
  { label: "(GMT-04:00) Caracas", value: "(GMT-04:00) Caracas" },
  { label: "(GMT-04:00) La Paz", value: "(GMT-04:00) La Paz" },
  { label: "(GMT-04:00) Santiago", value: "(GMT-04:00) Santiago" },
  { label: "(GMT-03:30) Newfoundland", value: "(GMT-03:30) Newfoundland" },
  { label: "(GMT-03:00) Brasilia", value: "(GMT-03:00) Brasilia" },
  { label: "(GMT-03:00) Buenos Aires", value: "(GMT-03:00) Buenos Aires" },
  { label: "(GMT-03:00) Georgetown", value: "(GMT-03:00) Georgetown" },
  { label: "(GMT-03:00) Greenland", value: "(GMT-03:00) Greenland" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "(GMT-02:00) Mid-Atlantic" },
  { label: "(GMT-01:00) Azores", value: "(GMT-01:00) Azores" },
  { label: "(GMT-01:00) Cape Verde Is.", value: "(GMT-01:00) Cape Verde Is." },
  { label: "(GMT) Casablanca", value: "(GMT) Casablanca" },
  { label: "(GMT) Edinburgh", value: "(GMT) Edinburgh" },
  {
    label: "(GMT) Greenwich Mean Time : Dublin",
    value: "(GMT) Greenwich Mean Time : Dublin",
  },
  { label: "(GMT) Lisbon", value: "(GMT) Lisbon" },
  { label: "(GMT) London", value: "(GMT) London" },
  { label: "(GMT) Monrovia", value: "(GMT) Monrovia" },
  { label: "(GMT+01:00) Amsterdam", value: "(GMT+01:00) Amsterdam" },
  { label: "(GMT+01:00) Belgrade", value: "(GMT+01:00) Belgrade" },
  { label: "(GMT+01:00) Berlin", value: "(GMT+01:00) Berlin" },
  { label: "(GMT+01:00) Bern", value: "(GMT+01:00) Bern" },
  { label: "(GMT+01:00) Bratislava", value: "(GMT+01:00) Bratislava" },
  { label: "(GMT+01:00) Brussels", value: "(GMT+01:00) Brussels" },
  { label: "(GMT+01:00) Budapest", value: "(GMT+01:00) Budapest" },
  { label: "(GMT+01:00) Copenhagen", value: "(GMT+01:00) Copenhagen" },
  { label: "(GMT+01:00) Ljubljana", value: "(GMT+01:00) Ljubljana" },
  { label: "(GMT+01:00) Madrid", value: "(GMT+01:00) Madrid" },
  { label: "(GMT+01:00) Paris", value: "(GMT+01:00) Paris" },
  { label: "(GMT+01:00) Prague", value: "(GMT+01:00) Prague" },
  { label: "(GMT+01:00) Rome", value: "(GMT+01:00) Rome" },
  { label: "(GMT+01:00) Sarajevo", value: "(GMT+01:00) Sarajevo" },
  { label: "(GMT+01:00) Skopje", value: "(GMT+01:00) Skopje" },
  { label: "(GMT+01:00) Stockholm", value: "(GMT+01:00) Stockholm" },
  { label: "(GMT+01:00) Vienna", value: "(GMT+01:00) Vienna" },
  { label: "(GMT+01:00) Warsaw", value: "(GMT+01:00) Warsaw" },
  {
    label: "(GMT+01:00) West Central Africa",
    value: "(GMT+01:00) West Central Africa",
  },
  { label: "(GMT+01:00) Zagreb", value: "(GMT+01:00) Zagreb" },
  { label: "(GMT+02:00) Athens", value: "(GMT+02:00) Athens" },
  { label: "(GMT+02:00) Bucharest", value: "(GMT+02:00) Bucharest" },
  { label: "(GMT+02:00) Cairo", value: "(GMT+02:00) Cairo" },
  { label: "(GMT+02:00) Harare", value: "(GMT+02:00) Harare" },
  { label: "(GMT+02:00) Helsinki", value: "(GMT+02:00) Helsinki" },
  { label: "(GMT+02:00) Istanbul", value: "(GMT+02:00) Istanbul" },
  { label: "(GMT+02:00) Jerusalem", value: "(GMT+02:00) Jerusalem" },
  { label: "(GMT+02:00) Kyiv", value: "(GMT+02:00) Kyiv" },
  { label: "(GMT+02:00) Minsk", value: "(GMT+02:00) Minsk" },
  { label: "(GMT+02:00) Pretoria", value: "(GMT+02:00) Pretoria" },
  { label: "(GMT+02:00) Riga", value: "(GMT+02:00) Riga" },
  { label: "(GMT+02:00) Sofia", value: "(GMT+02:00) Sofia" },
  { label: "(GMT+02:00) Tallinn", value: "(GMT+02:00) Tallinn" },
  { label: "(GMT+02:00) Vilnius", value: "(GMT+02:00) Vilnius" },
  { label: "(GMT+03:00) Baghdad", value: "(GMT+03:00) Baghdad" },
  { label: "(GMT+03:00) Kuwait", value: "(GMT+03:00) Kuwait" },
  { label: "(GMT+03:00) Moscow", value: "(GMT+03:00) Moscow" },
  { label: "(GMT+03:00) Nairobi", value: "(GMT+03:00) Nairobi" },
  { label: "(GMT+03:00) Riyadh", value: "(GMT+03:00) Riyadh" },
  { label: "(GMT+03:00) St. Petersburg", value: "(GMT+03:00) St. Petersburg" },
  { label: "(GMT+03:00) Volgograd", value: "(GMT+03:00) Volgograd" },
  { label: "(GMT+03:30) Tehran", value: "(GMT+03:30) Tehran" },
  { label: "(GMT+04:00) Abu Dhabi", value: "(GMT+04:00) Abu Dhabi" },
  { label: "(GMT+04:00) Baku", value: "(GMT+04:00) Baku" },
  { label: "(GMT+04:00) Muscat", value: "(GMT+04:00) Muscat" },
  { label: "(GMT+04:00) Tbilisi", value: "(GMT+04:00) Tbilisi" },
  { label: "(GMT+04:00) Yerevan", value: "(GMT+04:00) Yerevan" },
  { label: "(GMT+04:30) Kabul", value: "(GMT+04:30) Kabul" },
  { label: "(GMT+05:00) Ekaterinburg", value: "(GMT+05:00) Ekaterinburg" },
  { label: "(GMT+05:00) Islamabad", value: "(GMT+05:00) Islamabad" },
  { label: "(GMT+05:00) Karachi", value: "(GMT+05:00) Karachi" },
  { label: "(GMT+05:00) Tashkent", value: "(GMT+05:00) Tashkent" },
  { label: "(GMT+05:30) Chennai", value: "(GMT+05:30) Chennai" },
  { label: "(GMT+05:30) Kolkata", value: "(GMT+05:30) Kolkata" },
  { label: "(GMT+05:30) Mumbai", value: "(GMT+05:30) Mumbai" },
  { label: "(GMT+05:30) New Delhi", value: "(GMT+05:30) New Delhi" },
  { label: "(GMT+05:45) Kathmandu", value: "(GMT+05:45) Kathmandu" },
  { label: "(GMT+06:00) Almaty", value: "(GMT+06:00) Almaty" },
  { label: "(GMT+06:00) Astana", value: "(GMT+06:00) Astana" },
  { label: "(GMT+06:00) Dhaka", value: "(GMT+06:00) Dhaka" },
  { label: "(GMT+06:00) Novosibirsk", value: "(GMT+06:00) Novosibirsk" },
  {
    label: "(GMT+06:00) Sri Jayawardenepura",
    value: "(GMT+06:00) Sri Jayawardenepura",
  },
  { label: "(GMT+06:30) Rangoon", value: "(GMT+06:30) Rangoon" },
  { label: "(GMT+07:00) Bangkok", value: "(GMT+07:00) Bangkok" },
  { label: "(GMT+07:00) Hanoi", value: "(GMT+07:00) Hanoi" },
  { label: "(GMT+07:00) Jakarta", value: "(GMT+07:00) Jakarta" },
  { label: "(GMT+07:00) Krasnoyarsk", value: "(GMT+07:00) Krasnoyarsk" },
  { label: "(GMT+08:00) Beijing", value: "(GMT+08:00) Beijing" },
  { label: "(GMT+08:00) Chongqing", value: "(GMT+08:00) Chongqing" },
  { label: "(GMT+08:00) Hong Kong", value: "(GMT+08:00) Hong Kong" },
  { label: "(GMT+08:00) Irkutsk", value: "(GMT+08:00) Irkutsk" },
  { label: "(GMT+08:00) Kuala Lumpur", value: "(GMT+08:00) Kuala Lumpur" },
  { label: "(GMT+08:00) Perth", value: "(GMT+08:00) Perth" },
  { label: "(GMT+08:00) Singapore", value: "(GMT+08:00) Singapore" },
  { label: "(GMT+08:00) Taipei", value: "(GMT+08:00) Taipei" },
  { label: "(GMT+08:00) Ulaan Bataar", value: "(GMT+08:00) Ulaan Bataar" },
  { label: "(GMT+08:00) Urumqi", value: "(GMT+08:00) Urumqi" },
  { label: "(GMT+09:00) Osaka", value: "(GMT+09:00) Osaka" },
  { label: "(GMT+09:00) Sapporo", value: "(GMT+09:00) Sapporo" },
  { label: "(GMT+09:00) Seoul", value: "(GMT+09:00) Seoul" },
  { label: "(GMT+09:00) Tokyo", value: "(GMT+09:00) Tokyo" },
  { label: "(GMT+09:00) Yakutsk", value: "(GMT+09:00) Yakutsk" },
  { label: "(GMT+09:30) Adelaide", value: "(GMT+09:30) Adelaide" },
  { label: "(GMT+09:30) Darwin", value: "(GMT+09:30) Darwin" },
  { label: "(GMT+10:00) Brisbane", value: "(GMT+10:00) Brisbane" },
  { label: "(GMT+10:00) Canberra", value: "(GMT+10:00) Canberra" },
  { label: "(GMT+10:00) Guam", value: "(GMT+10:00) Guam" },
  { label: "(GMT+10:00) Hobart", value: "(GMT+10:00) Hobart" },
  { label: "(GMT+10:00) Melbourne", value: "(GMT+10:00) Melbourne" },
  { label: "(GMT+10:00) Port Moresby", value: "(GMT+10:00) Port Moresby" },
  { label: "(GMT+10:00) Sydney", value: "(GMT+10:00) Sydney" },
  { label: "(GMT+10:00) Vladivostok", value: "(GMT+10:00) Vladivostok" },
  { label: "(GMT+11:00) Magadan", value: "(GMT+11:00) Magadan" },
  { label: "(GMT+11:00) New Caledonia", value: "(GMT+11:00) New Caledonia" },
  { label: "(GMT+11:00) Solomon Is.", value: "(GMT+11:00) Solomon Is." },
  { label: "(GMT+12:00) Auckland", value: "(GMT+12:00) Auckland" },
  { label: "(GMT+12:00) Fiji", value: "(GMT+12:00) Fiji" },
  { label: "(GMT+12:00) Kamchatka", value: "(GMT+12:00) Kamchatka" },
  { label: "(GMT+12:00) Marshall Is.", value: "(GMT+12:00) Marshall Is." },
  { label: "(GMT+12:00) Wellington", value: "(GMT+12:00) Wellington" },
  { label: `(GMT+13:00) Nuku'alofa`, value: `(GMT+13:00) Nuku'alofa` },
];
export const ages = [
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20-25", value: "20-25" },
  { label: "26-30", value: "26-30" },
  { label: "31-35", value: "31-35" },
  { label: "36-40", value: "36-40" },
  { label: "41-45", value: "41-45" },
  { label: "46-50", value: "46-50" },
  { label: "51-55", value: "51-55" },
  { label: "56-60", value: "56-60" },
  { label: "61-65", value: "61-65" },
  { label: "66-70", value: "66-70" },
  { label: "71-75", value: "71-75" },
  { label: "76-80", value: "76-80" },
  { label: "81-85", value: "81-85" },
  { label: "86-90", value: "86-90" },
];

export const kidAges = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
];
