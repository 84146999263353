import React from "react";

const VerificationComplete = () => {
  return (
    <div>
 <div className=" idVerification">
      <div class="col-md-6">
        <form class="post-identity-form wt-haslayout wt-attachmentsholder">
          <div class="wt-dashboardbox">
            <div class="wt-dashboardboxtitle wt-titlewithsearch">
              <h2>Upload Identity Information</h2>
            </div>
            <div class="wt-dashboardboxcontent">
              <div class="wt-helpsupportcontents">
                <div class="wt-identity-verified wt-haslayout">
                  <img src="https://booksculp.com/LIVE/uploads/identity_verified_color.png" />
                  <p>
                    Congratulations! Your identity has been verified. You are
                    ready to apply for jobs or post a service for projects
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      </div>

    </div>
  );
};

export default VerificationComplete;
