import React, { useState } from "react";

export const AppliedFilters = (props) => {
  const {
    selectedFilters,
    setSelectedFilters,
    selectedSizes,
    setSelectedSizes,
    setCity,
    city,
    country,
    setCountry,
    mileRange,
    setMileRange,
    state,
    setState,
    getAllModelsList,
    setIsSpace,
    setIsSubmit,
    setMinPrice,
    minPrice,
    maxPrice,
    setMaxPrice,
    isSubmit,
    selectedAge,
    setSelectedAge,
    selectedGender,
    setSelectedGender,
    selectedkeyword,
    setSelectedkeyword,
    setSelectedTalent,
    selectedTalent,
    myUsers,
    setMyUsers,
  } = props;

  const handleClearFilter = (filterValue) => {
    if (filterValue === country) {
      setCountry("");
      return;
    }

    if (setMyUsers && filterValue === myUsers ) {
      setMyUsers("");
      return;
    }

    if (filterValue === selectedAge) {
      setSelectedAge("");
      return;
    }

    if (filterValue === selectedGender) {
      setSelectedGender("");
      return;
    }

    if (filterValue === selectedkeyword) {
      setSelectedkeyword("");
      return;
    }

    if (filterValue === selectedTalent) {
      setSelectedTalent("");
      return;
    }

    if (filterValue === state) {
      setState("");
      return;
    }

    if (filterValue === city) {
      setCity("");
      return;
    }
    if (filterValue === mileRange) {
      setMileRange(0);
      return;
    }

    if (filterValue === minPrice) {
      setMinPrice("");
      return;
    }

    if (filterValue === maxPrice) {
      setMaxPrice("");
      return;
    }

    const updatedFilters = { ...selectedFilters };
    for (const key in updatedFilters) {
      const value = updatedFilters[key];
      if (Array.isArray(value)) {
        updatedFilters[key] = value.filter((v) => v.label !== filterValue);
      } else if (typeof value === "string") {
        updatedFilters[key] = "";
      }
    }
    setSelectedFilters(updatedFilters);

    const updatedSizes = { ...selectedSizes };

    for (const key in updatedSizes) {
      const value = updatedSizes[key];

      if (Array.isArray(value)) {
        updatedSizes[key] = value.filter((item) => item.label !== filterValue);
      } else if (typeof value === "string") {
        updatedSizes[key] = "";
      }
    }
    setSelectedSizes(updatedSizes);
  };

  const handleClearAllFilters = () => {
    setIsSubmit(false);
    setSelectedAge("");
    setSelectedGender("");
    setSelectedkeyword("");
    setSelectedTalent("");
    if(setMyUsers){
      setMyUsers("");
    }
    setSelectedFilters({
      ...selectedFilters,
      hair_colour: [],
      weight: [],
      ethnicities: [],
      height: [],
      language: [],
      english_level: [],
      influencers: [],
      // gender: "",
      // age: "",
      // talentType: "model",
      hourlyRate: [],
      skills: [],
      // keyword: "",
      projectType: "",
      projectLength: [],
      projectLocation: [],
      // talentSubType: "adult",
    });
    setSelectedSizes({
      ...selectedSizes,
      shirtSize: [],
      pantSize: [],
      shoeSize: [],
      dressShirtSize: [],
      braCup: [],
      braSize: [],
      dressSize: [],
      pantSizeL: [],
      pantSizeW: [],
      dressShirtSleeve: [],
      neckSize: [],
      chestSize: [],
      jacket: [],
      dressSizeFemale: [],
      shirtSizeFemail: [],
      pantSizeFemale: [],
      shoeSizeFemale: [],
    });
    setCountry("");
    setState("");
    if (setMinPrice || setMaxPrice) {
      setMaxPrice("");
      setMinPrice("");
    }
    setCity("");
    setMileRange(0);
    setIsSpace(false);
    getAllModelsList();
  };

  const convertToFilterFormat = (
    filters,
    sizes,
    country,
    state,
    city,
    mileRange,
    age,
    gender,
    keyword,
    talent,
    myUsers
  ) => {
    const filterArray = [
      ...filters,
      ...Object.values(sizes).flat(),
      country,
      state,
      city,
    ].filter((filter) => filter !== "");
    if (mileRange !== 0) {
      filterArray.push(mileRange);
    }
    if (age !== "") {
      filterArray.push(age);
    }
    if (gender !== "") {
      filterArray.push(gender);
    }
    if (talent !== "") {
      filterArray.push(talent);
    }
    if (keyword !== "") {
      filterArray.push(keyword);
    }
    if (myUsers && myUsers !== "") {
      filterArray.push(myUsers);
    }
    if (minPrice && (minPrice !== 0 || minPrice !== "")) {
      filterArray.push(minPrice);
    }
    if (maxPrice && (maxPrice !== 0 || maxPrice !== "")) {
      filterArray.push(maxPrice);
    }
    return filterArray.map((filter) =>
      filter?.label ? filter?.label : filter
    );
  };

  const SelectedFilters = ({ filters, sizes }) => {
    const allFilters = convertToFilterFormat(
      filters,
      sizes,
      country,
      state,
      city,
      mileRange,
      selectedAge,
      selectedGender,
      selectedkeyword,
      selectedTalent,
      myUsers
    );

    if (allFilters.length === 0) {
      setIsSpace(false);
      return null;
    }

    if (allFilters?.length) {
      setIsSpace(true);
    }

    return (
      <ul className="wt-filtertag">
        <li className="wt-filtertagclear">
          <a href="#" onClick={handleClearAllFilters}>
            <i className="fa fa-times"></i> <span>Clear All Filters</span>
          </a>
        </li>
        {allFilters?.map((filter, index) => (
          <>
            {filter != 0 ? (
              <li key={index} className="alert alert-dismissable fade in">
                <a>
                  <i
                    className="fa fa-times close"
                    data-dismiss="alert"
                    aria-label="close"
                    onClick={() => handleClearFilter(filter)}
                  ></i>{" "}
                  <span>{filter}</span>
                </a>
              </li>
            ) : null}
          </>
        ))}
      </ul>
    );
  };

  return (
    <>
      <SelectedFilters
        filters={Object.values(selectedFilters).flat()}
        sizes={Object.values(selectedSizes).flat()}
      />
    </>
  );
};
