import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { db } from "../../Utility/firebase";
import Message from "./Message";
import { ChatContext } from "../../Context/ChatContext";
import { AuthContext } from "../../Context/AuthContext";

const Messages = ({ setLoading }) => {
  const [messages, setMessages] = useState([]);
  const [userDeletedMessages, setUserDeletedMessages] = useState([]);
  const { data } = useContext(ChatContext);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);

    // Fetch the messages from the "chats" collection
    const unSub = onSnapshot(doc(db, "chats", data?.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    // Fetch the deleted messages for the current user
    const userRef = doc(db, "users", currentUser.uid);
    const unSubUser = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setUserDeletedMessages(doc.data().deletedMessages || []);
      }
    });

    setLoading(false);

    return () => {
      unSub();
      unSubUser();
    };
  }, [data?.chatId, currentUser.uid, setLoading]);

  // Function to filter out messages that have been deleted for the current user
  const renderMessages = (messages) => {
    return messages
      .filter((message) => !userDeletedMessages.includes(message.id)) // Filter out deleted messages
      .map((m) => <Message message={m} key={m.id} />);
  };

  return (
    <div className="messages">
      {messages?.length ? renderMessages(messages) : undefined}
    </div>
  );
};

export default Messages;

